import { creatorTradeDiaryTierUrl, creatorTradeDiaryUrl } from "../../const";
import axios from "../../lib/axios";

// get creator tradediary
export const getCreatorTradeDiary = async (
  creator_id,
  page = 1,
  limit = 5,
  search = "",
  pin = "",
  post_id = ""
) => {
  return await axios.get(creatorTradeDiaryUrl, {
    params: {
      creator_id,
      page,
      limit,
      search,
      pin,
      post_id,
    },
  });
};

// get trade diary tiers
export const getTradeDiaryTiers = async (post_id) => {
  return await axios.get(creatorTradeDiaryTierUrl, { params: { post_id } });
};
