import React, { useState } from 'react';
import Spacing from '../../components/Spacing';
import Canceled from './Components/Canceled';
import History from './Components/History';
import UpcomingBilling from './Components/UpcomingBilling';
import { useTranslation } from 'react-i18next';

export default function BillingInformation() {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(0);
  const handelTab = index => {
    setSelected(index);
  };

  return (
    <>
      <div className="cs-container_md">
        <Spacing lg="40" md="40" />
        <div className="cs-section_heading_2">
          <div className="cs-section_left">
            <h2 className="cs-section_title">
              {t('billing_information_title')}
            </h2>
          </div>
          <div className="cs-section_right">{/* <DateRangeComp/> */}</div>
        </div>
        <Spacing lg="30" md="30" />
        <ul className="cs-secondary_nav cs-style1">
          <li>
            <button
              className={selected === 0 ? 'active' : ''}
              onClick={() => handelTab(0)}
            >
              {t('billing_information_tab_nav_1')}
            </button>
          </li>
          <li>
            <button
              className={selected === 1 ? 'active' : ''}
              onClick={() => handelTab(1)}
            >
              {t('billing_information_tab_nav_2')}
            </button>
          </li>
          <li>
            <button
              className={selected === 2 ? 'active' : ''}
              onClick={() => handelTab(2)}
            >
              {t('billing_information_tab_nav_3')}
            </button>
          </li>
        </ul>
        {selected === 0 && <History />}
        {selected === 1 && <UpcomingBilling />}
        {selected === 2 && <Canceled />}
        <Spacing lg="60" md="60" />
      </div>
    </>
  );
}
