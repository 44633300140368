import { Icon } from "@iconify/react";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { getCreatorTradeDiary } from "../../../api/services/creator/tradediary";
import EmptyData from "../../../components/Common/EmptyData";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import PaginateButton from "../../../components/Common/PaginateButton";
import DotOption from "../../../components/DotOption";
import Spacing from "../../../components/Spacing";
import { defaultHasMoreCount } from "../../../config/const/hasMore";
import { useTranslation } from "react-i18next";

export default function TradeDiary() {
  const { t } = useTranslation();
  const params = useParams();
  const [creator] = useOutletContext();
  const [searchParams] = useSearchParams();
  const [tradeDiary, setTradeDiary] = useState([]);
  const [tradeDiaryLoading, setTradeDiaryLoading] = useState(false);
  const [tradeDiaryCount, setTradeDiaryCount] = useState(0);
  const [tradeDiaryPage, setTradeDiaryPage] = useState(2);
  const [tradeDiaryHasMore, setTradeDiaryHasMore] = useState(true);
  const [tradeDiaryPaginateLoading, setTradeDiaryPaginateLoading] =
    useState(false);

  const [isDisclaimer, setIsDisclaimer] = useState(
    localStorage.getItem("closeTradeDiaryDisclaimer") ? false : true
  );

  const [pinnedTradeDiary, setPinnedTradeDiary] = useState([]);
  const [pinnedTradeDiaryLoading, setPinnedTradeDiaryLoading] = useState(false);
  const [pinnedTradeDiaryCount, setPinnedTradeDiaryCount] = useState(0);
  const [pinnedTradeDiaryPage, setPinnedTradeDiaryPage] = useState(2);
  const [pinnedTradeDiaryHasMore, setPinnedTradeDiaryHasMore] = useState(true);
  const [pinnedTradeDiaryPaginateLoading, setPinnedTradeDiaryPaginateLoading] =
    useState(false);

  const searchQuery = searchParams.get("search") ?? "";

  // get all trade diary
  const getTradeDiary = async () => {
    setTradeDiaryLoading(true);
    const res = await getCreatorTradeDiary(
      params.creator_id,
      1,
      5,
      searchQuery
    );
    setTradeDiary(res.data.data);
    setTradeDiaryCount(res.data.count);
    setTradeDiaryLoading(false);
  };

  // paginate trade diary
  const tradeDiaryPaginate = async () => {
    setTradeDiaryPaginateLoading(true);
    const res = await getCreatorTradeDiary(
      params.creator_id,
      tradeDiaryPage,
      5,
      searchQuery
    );
    setTradeDiaryPaginateLoading(false);
    setTradeDiary([...tradeDiary, ...res.data.data]);
    setTradeDiaryPage(tradeDiaryPage + 1);
    if (!res.data.data.length) {
      setTradeDiaryHasMore(false);
    }
  };

  // pinned tradeDiary
  const pinnedTradeDiarys = async () => {
    setPinnedTradeDiaryLoading(true);
    const res = await getCreatorTradeDiary(
      params.creator_id,
      1,
      5,
      searchQuery,
      1
    );
    setPinnedTradeDiaryLoading(false);
    setPinnedTradeDiary(res.data.data);
    setPinnedTradeDiaryCount(res.data.count);
  };
  // paginate pinned post
  const pinnedTradeDiaryPaginate = async () => {
    setPinnedTradeDiaryPaginateLoading(true);
    const res = await getCreatorTradeDiary(
      params.creator_id,
      pinnedTradeDiaryPage,
      5,
      searchQuery,
      1
    );
    setPinnedTradeDiaryPaginateLoading(false);
    setPinnedTradeDiary([...pinnedTradeDiary, ...res.data.data]);
    setPinnedTradeDiaryPage(pinnedTradeDiaryPage + 1);
    if (!res.data.data.length) {
      setPinnedTradeDiaryHasMore(false);
    }
  };

  const closeTradeDiaryDisclaimer = () => {
    setIsDisclaimer(false);
    localStorage.setItem("closeTradeDiaryDisclaimer", true);
  };

  useEffect(() => {
    getTradeDiary();
    pinnedTradeDiarys();
  }, [searchParams]);
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <div className="cs-layout_1">
        <div className="cs-layout_left">
          <h2 style={{ fontSize: "24px", marginBottom: "8px" }}>
            {creator.name_show}
            {t("trade_diary_title")}
          </h2>
          <p className="cs-m0 cs-light" style={{ color: "#8E8EA3" }}>
            {t("trade_diary_subtitle")}
          </p>
          <Spacing lg="30" md="30" />
          <div
            className={`cs-card_9_wrap ${
              tradeDiaryLoading ? "cs-loading_wrap" : ""
            }`}
          >
            {tradeDiaryLoading && (
              <>
                <Spacing lg="50" md="50" />
                <LoadingSpinner />
              </>
            )}
            {tradeDiary.map((item) => (
              <div className="cs-card cs-style9">
                <div
                  className="cs-card_bar"
                  style={{ backgroundColor: item.tradeDairyColorBackground }}
                ></div>
                <div className="cs-card_in">
                  <div className="cs-left">
                    <h2 className="ca-card_title">{item.stock_name}</h2>
                    <div className="cs-card_meta">
                      <div>
                        <div className="cs-card_meta_title">
                          {t("price_text")}
                        </div>
                        <div className="cs-card_meta_price">
                          {item.stock_price !== "Now" && "$"}
                          {item.stock_price}
                        </div>
                      </div>
                      <div>
                        <div className="cs-card_meta_title">
                          {t("stop_loss_text")}
                        </div>
                        <div className="cs-card_meta_price">
                          ${item.stop_loss}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cs-right">
                    <div className="cs-card_label">
                      <span
                        style={{
                          backgroundColor: item.tradeDairyColorBackground,
                        }}
                      ></span>
                      {item.tradeDairyType}
                    </div>
                    <div className="cs-card_date">
                      {moment(item.created_at).format("DD-MMM-Y")}{" "}
                      {moment(item.created_at).format("LT")}
                    </div>
                  </div>
                </div>
                {item.note && (
                  <>
                    <div
                      className="cs-card_note_title"
                      style={{ textTransform: "uppercase" }}
                    >
                      <Icon icon="mdi:tick-circle-outline" />
                      {t("notes_text")}:
                    </div>
                    <div className="cs-card_text cs-break_all">{item.note}</div>
                  </>
                )}

                <DotOption
                  url={`/dashboard/${item.userId}/trade-diary/${item.id}`}
                />
              </div>
            ))}
            {!tradeDiary.length && !tradeDiaryLoading ? <EmptyData /> : null}
          </div>
          {tradeDiaryCount > defaultHasMoreCount && (
            <>
              <Spacing lg="20" md="20" />
              <PaginateButton
                onClick={tradeDiaryPaginate}
                loading={tradeDiaryPaginateLoading}
                hasMore={tradeDiaryHasMore}
              />
            </>
          )}
        </div>
        <div className="cs-layout_right">
          <div className="cs-section_heading_4">
            <h2 className="cs-section_title">{t("pinned_by_creator_text")}</h2>
            <div className="cs-section_heading_right">
              {/* <button className="cs-icon_btn">
                <svg
                  width={9}
                  height={13}
                  viewBox="0 0 9 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.121661 12.7023L4.50004 9.08404L8.87842 12.7023V1.33066C8.87842 0.759493 8.38859 0.296875 7.78382 0.296875H1.21626C0.611492 0.296875 0.121661 0.759493 0.121661 1.33066V12.7023Z"
                    fill="currentColor"
                  />
                </svg>
              </button> */}
            </div>
          </div>
          <Spacing lg="30" md="30" />
          <div
            className={`cs-card_1_list ${
              pinnedTradeDiaryLoading ? "cs-loading_wrap" : ""
            }`}
          >
            {pinnedTradeDiaryLoading && (
              <>
                <Spacing lg="50" md="50" />
                <LoadingSpinner />
              </>
            )}
            {pinnedTradeDiary.map((item) => (
              <div className="cs-card cs-style1">
                <DotOption
                  url={`/dashboard/${item.userId}/trade-diary/${item.id}`}
                />
                <h2>{item.stock_name}</h2>
                <span
                  className="cs-card_label"
                  style={{
                    color: item.tradeDairyColorText,
                    backgroundColor: item.tradeDairyColorBackground,
                  }}
                >
                  {item.tradeDairyType}
                </span>
                <Spacing lg="10" md="10" />

                <p className="cs-semibold">
                  <span
                    className="cs-info_color"
                    style={{ marginRight: "5px" }}
                  >
                    {t("price_text")}:
                  </span>
                  <span className="cs-accent_color">
                    {item.stock_price}{" "}
                    {item.stock_price !== "Now" && t("currency")}
                  </span>
                </p>
                <Spacing lg="5" md="5" />
                <p className="cs-semibold">
                  <span
                    className="cs-info_color"
                    style={{ marginRight: "5px" }}
                  >
                    {t("stop_loss_text")}:
                  </span>
                  <span className="cs-primary_color">
                    {item.stop_loss} {t("currency")}
                  </span>
                </p>
                <Spacing lg="10" md="5" />
                <div className="cs-card_top_date cs-info_color">
                  <span>{moment(item.created_at).format("DD-MMM-Y")} </span>{" "}
                  <span>{moment(item.created_at).format("LT")}</span>
                </div>
                <Spacing lg="10" md="10" />
                {item.note && (
                  <p className="cs-primary_color cs-break_all">
                    {t("note_text")}: {item.note}
                  </p>
                )}
              </div>
            ))}

            {!pinnedTradeDiary.length && !pinnedTradeDiaryLoading ? (
              <EmptyData />
            ) : null}
          </div>
          {/* Tsting */}
          {pinnedTradeDiaryCount > defaultHasMoreCount && (
            <>
              <Spacing lg="20" md="20" />
              <PaginateButton
                onClick={pinnedTradeDiaryPaginate}
                loading={pinnedTradeDiaryPaginateLoading}
                hasMore={pinnedTradeDiaryHasMore}
              />
            </>
          )}
          <Spacing lg="20" md="20" />
          {isDisclaimer && (
            <div className="cs-disclaimer_card">
              <span
                className="cs-disclaimer_close"
                onClick={closeTradeDiaryDisclaimer}
              >
                <svg
                  width={21}
                  height={22}
                  viewBox="0 0 21 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    y="0.457031"
                    width={21}
                    height="21.5309"
                    rx="10.5"
                    fill="#E5E5E5"
                  />
                  <path
                    d="M10.5 10.1117L14.3891 6.22266L15.5 7.33359L11.6109 11.2227L15.5 15.1117L14.3891 16.2227L10.5 12.3336L6.61094 16.2227L5.5 15.1117L9.38906 11.2227L5.5 7.33359L6.61094 6.22266L10.5 10.1117Z"
                    fill="#4F4F4F"
                  />
                </svg>
              </span>
              <h2>
                <svg
                  width={12}
                  height={15}
                  viewBox="0 0 12 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.1786 11.9431C9.90336 11.2973 9.85486 8.52519 9.85391 8.45525V6.59553C9.85391 4.80564 8.87858 3.26653 7.4926 2.61119C7.48962 1.69096 6.82126 0.943359 6.00004 0.943359C5.17881 0.943359 4.51047 1.691 4.50747 2.61115C3.12155 3.26648 2.14619 4.8056 2.14619 6.59548V8.45521C2.14529 8.52515 2.09674 11.2972 0.821467 11.943C0.676192 12.0166 0.596349 12.194 0.629937 12.3687C0.663487 12.5435 0.80131 12.6683 0.960742 12.6683H4.19369C4.25996 13.0772 4.43236 13.4575 4.69602 13.7656C5.04622 14.1748 5.50934 14.4001 5.99999 14.4001C6.49067 14.4001 6.95379 14.1748 7.30395 13.7656C7.56766 13.4575 7.74005 13.0771 7.80628 12.6683H11.0392C11.1987 12.6683 11.3365 12.5435 11.3701 12.3687C11.4037 12.194 11.3239 12.0166 11.1786 11.9431ZM9.49874 10.6151C9.6328 11.0709 9.82262 11.5286 10.0918 11.9095H1.90825C2.17739 11.5286 2.36721 11.0709 2.50127 10.6151H9.49874ZM6.00004 1.70228C6.37152 1.70228 6.6856 1.98219 6.78387 2.36373C6.53069 2.30485 6.26854 2.2738 6.00004 2.2738C5.73155 2.2738 5.4694 2.30483 5.21622 2.36373C5.31451 1.98222 5.62859 1.70228 6.00004 1.70228ZM2.82296 8.45754V6.59546C2.82296 4.63097 4.24818 3.03273 6.00004 3.03273C7.75191 3.03273 9.17713 4.63097 9.17713 6.59546V8.46064C9.17758 8.51772 9.1855 9.11763 9.32032 9.85612H2.67969C2.81459 9.11692 2.82255 8.51596 2.82296 8.45754ZM6.00004 13.6412C5.48355 13.6412 5.03279 13.2236 4.8881 12.6684H7.11195C6.96726 13.2236 6.51658 13.6412 6.00004 13.6412Z"
                    fill="#54595E"
                  />
                  <path
                    d="M5.99996 3.56445C4.54407 3.56445 3.35962 4.89265 3.35962 6.52526C3.35962 6.73483 3.51113 6.90473 3.69801 6.90473C3.88489 6.90473 4.0364 6.73483 4.0364 6.52526C4.0364 5.31113 4.91725 4.32338 5.99998 4.32338C6.18686 4.32338 6.33837 4.15347 6.33837 3.9439C6.33835 3.73431 6.18684 3.56445 5.99996 3.56445Z"
                    fill="#54595E"
                  />
                </svg>
                <span>{t("disclaimer")}:</span>
              </h2>
              <p>
                {t("disclaimer_text_1")} <br /> <br />
                {t("disclaimer_text_2")}
              </p>
            </div>
          )}
        </div>
      </div>
      <Spacing lg="50" md="50" />
    </div>
  );
}
