import React from 'react'
import { Link } from 'react-router-dom'

export default function ErrorPage() {
  return (
    <div className='error'>
      <div className='cs-contaienr_sm cs-text_center'>
        <div className='error_img'>
          <svg width={665} viewBox="0 0 665 330" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.0682 242.364V198.5L118.409 48.2727H157.386V107.818H134.773L78.9773 196.227V198.045H217.045V242.364H23.0682ZM135.455 281V228.955L136.591 209.75V48.2727H189.205V281H135.455ZM449.943 242.364V198.5L545.284 48.2727H584.261V107.818H561.648L505.852 196.227V198.045H643.92V242.364H449.943ZM562.33 281V228.955L563.466 209.75V48.2727H616.08V281H562.33Z" fill="#4680C2" />
            <circle cx="332.5" cy="152.5" r="113.5" fill="#181059" />
            <circle cx="419.5" cy="94.5" r="55.5" fill="#D0D2D8" />
          </svg>
        </div>
        <h2>Oops, This Page Could Not Be Found.</h2>
        <p>The page you are looking for might have been removed had its <br/>name changed or is temporarily unavailable.</p>
        <Link to='/' className='error_btn'>
          <>
            <svg width={17} height={12} viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.131836 5.52832L5.53711 0.131836C5.625 0.0439449 5.73047 0 5.85352 0C5.97656 0 6.08203 0.0439449 6.16992 0.131836C6.25781 0.219727 6.30176 0.325195 6.30176 0.448242C6.30176 0.57129 6.25781 0.676757 6.16992 0.764648L1.53809 5.39648H16.1982C16.3271 5.39648 16.4355 5.44043 16.5234 5.52832C16.6113 5.61621 16.6553 5.72461 16.6553 5.85352C16.6553 5.97656 16.6113 6.08203 16.5234 6.16992C16.4355 6.25781 16.3271 6.30176 16.1982 6.30176H1.53809L6.16992 10.9336C6.25781 11.0215 6.30176 11.127 6.30176 11.25C6.30176 11.373 6.25781 11.4785 6.16992 11.5664C6.12891 11.6133 6.08057 11.647 6.0249 11.6675C5.96924 11.688 5.91211 11.6982 5.85352 11.6982C5.79492 11.6982 5.73779 11.688 5.68213 11.6675C5.62646 11.647 5.57812 11.6133 5.53711 11.5664L0.131836 6.16992C0.0439449 6.08203 0 5.9751 0 5.84912C0 5.72314 0.0439449 5.61621 0.131836 5.52832Z" fill="currentColor" />
            </svg>
            Back to home
          </>
        </Link>
      </div>
    </div>
  )
}
