import { useEffect, useState } from 'react';
import { allBillingInfo } from '../../../api/services/billingInfo';
import LoadingSpinner from '../../../components/Common/LoadingSpinner';
import Spacing from '../../../components/Spacing';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function History() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [billingInfo, setBillingInfo] = useState([]);
  // get all billing info
  const allBilling = async () => {
    setLoading(true);
    const res = await allBillingInfo();
    setBillingInfo(res.data.data.data);
    setLoading(false);
  };

  useEffect(() => {
    allBilling();
  }, []);
  return (
    <div className="cs-table_responsive">
      <table
        className={`cs-table cs-medium ${loading ? 'cs-loading_wrap' : ''}`}
        style={{ minWidth: '700px' }}
      >
        {loading && (
          <>
            <Spacing lg="50" md="50" />
            <LoadingSpinner />
          </>
        )}
        <thead>
          <tr>
            <th>{t('billing_information_history_table_heading_1')}</th>
            <th>{t('billing_information_history_table_heading_2')}</th>
            <th>{t('billing_information_history_table_heading_3')}</th>
            <th>{t('billing_information_history_table_heading_4')}</th>
            <th>{t('billing_information_history_table_heading_5')}</th>
            <th>{t('billing_information_history_table_heading_6')}</th>
            <th>{t('billing_information_history_table_heading_7')}</th>
          </tr>
        </thead>
        <tbody>
          {billingInfo.map(item => (
            <tr key={uuidv4()}>
              <td>
                {moment(new Date(item.created * 1000)).format('MMM D, Y')}
              </td>
              <td>{item.lines.data[0].plan.metadata?.title}</td>
              <td className="cs-accent_color">
                {item.lines.data[0].plan.metadata?.fullname}
              </td>
              <td style={{ textTransform: 'uppercase' }}>
                {item.lines.data[0].plan.metadata?.price}{' '}
                {item.lines.data[0].plan.metadata?.currency}
              </td>
              <td>
                {moment(item.lines.data[0].period.end * 1000).format(
                  'MMM D, Y',
                )}
              </td>
              <td>{item.id}</td>
              <td>
                <a
                  className="cs-accent_color"
                  target="_blank"
                  href={item.hosted_invoice_url}
                >
                  {t('view_text')}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
