import React from 'react';
import Header from '../../components/Header';
import Spacing from '../../components/Spacing';
import LoadingSpinner from '../../components/Common/LoadingSpinner';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useFetch from '../../hooks/useFetch';
import { getCountryListUrl } from '../../api/const';
import { useContext } from 'react';
import UserContext from '../../context/user/user-context';
import { useEffect } from 'react';
import { updateUserInfo } from '../../api/services/auth/user';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function PersonalInfo() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { data: countries, loading: fetchLoading } =
    useFetch(getCountryListUrl);
  const { user, getUserInformation } = useContext(UserContext);
  const navigate = useNavigate();

  const formSchema = Yup.object().shape({
    name_show: Yup.string(),
    whatsapp: Yup.string(),
    telegram: Yup.string(),
    discord: Yup.string(),
    fullname: Yup.string(),
    about_yourself: Yup.string(),
    dob: Yup.string().required('The date of birth field required'),
    country: Yup.string().required('The country field is required'),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm(formOptions);

  // handle update
  const handleUpdate = async data => {
    setIsLoading(true);
    try {
      await updateUserInfo(data);
      await getUserInformation();
      setIsLoading(false);
      navigate('/');
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setValue('name_show', user.name_show);
    setValue('fullname', ' ');
    setValue('whatsapp', ' ');
    setValue('telegram', ' ');
    setValue('discord', ' ');
    setValue('about_yourself', ' ');
  }, [user]);
  return (
    <>
      <Header />
      <Spacing lg="90" md="90" />
      <div className="cs-contaienr_sm">
        <div className="cs-display_justify">
          <div className={`${fetchLoading ? 'cs-loading_wrap' : ''}`}>
            <Spacing lg="70" md="50" />
            {fetchLoading && <LoadingSpinner />}
            <form
              onSubmit={handleSubmit(handleUpdate)}
              style={{ maxWidth: '450px', margin: 'auto' }}
            >
              <h2 className="cs-m0" style={{ fontSize: '21px' }}>
                {t('personal_info_title')}
              </h2>
              <Spacing lg="30" md="30" />
              <label>{t('personal_info_input_label_1')}</label>
              <input
                type="text"
                className="cs-form_field"
                {...register('name_show')}
                disabled
              />
              <Spacing lg="25" md="25" />
              <label>{t('personal_info_input_label_2')}</label>
              <input
                type="date"
                className="cs-form_field"
                {...register('dob')}
              />
              <p className="invalid-feedback">{errors.dob?.message}</p>
              <Spacing lg="25" md="25" />
              <label>{t('personal_info_input_label_3')}</label>
              <select className="cs-form_field" {...register('country')}>
                <option value="">Select Country</option>
                {countries.data?.map(country => {
                  return (
                    <option key={country.id} value={country.nicename}>
                      {country.nicename}
                    </option>
                  );
                })}
              </select>
              <p className="invalid-feedback">{errors.country?.message}</p>
              <Spacing lg="30" md="30" />
              <button
                className={`cs-logout_btn cs-type1 ${
                  isLoading ? 'cs-loading_wrap' : ''
                }`}
                disabled={isLoading}
              >
                {isLoading && <LoadingSpinner />}
                SUBMIT NOW
              </button>
            </form>
            <Spacing lg="60" md="60" />
          </div>
          <div>
            <div className="cs-copyright">{t('copyright_text')}</div>
          </div>
        </div>
      </div>
    </>
  );
}
