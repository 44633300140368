import React, { useEffect, useRef, useState } from 'react';
import Spacing from '../../components/Spacing';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getEmailSettings,
  getPushSettings,
  updateEmailNotificationSettings,
  updatePushNotificationSettings,
} from '../../api/services/settings/notification';
import LoadingSpinner from '../../components/Common/LoadingSpinner';
import { useTranslation } from 'react-i18next';

export default function NotificationSettings() {
  const { t } = useTranslation();
  const submitEmailRef = useRef();
  const submitPushRef = useRef();
  const [loading, setLoading] = useState(false);

  // email notification schema
  const formSchema = Yup.object().shape({
    email: Yup.bool(),
    broadcast: Yup.bool(),
    trade_diary: Yup.bool(),
    watchlist: Yup.bool(),
    open_position: Yup.bool(),
    resources: Yup.bool(),
    newsletter: Yup.bool(),
    webinar: Yup.bool(),
    subs_new: Yup.bool(),
    subs_canc: Yup.bool(),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register: registerEmailSettings,
    handleSubmit: handleEmailSubmit,
    watch: emailSettingsWatch,
    setValue: setEmailValue,
    getValues: getEmailValue,
    formState: { errors },
  } = useForm(formOptions);

  // push notification schema
  const formSchemaPush = Yup.object().shape({
    push: Yup.bool(),
    broadcast: Yup.bool(),
    trade_diary: Yup.bool(),
    watchlist: Yup.bool(),
    open_position: Yup.bool(),
    resources: Yup.bool(),
    newsletter: Yup.bool(),
    webinar: Yup.bool(),
    subs_new: Yup.bool(),
    subs_canc: Yup.bool(),
  });
  const formOptionsPush = { resolver: yupResolver(formSchemaPush) };
  const {
    register: registerPushSettings,
    handleSubmit: handlePushSubmit,
    watch: pushSettingsWatch,
    setValue: setPushValue,
    getValues: getPushValue,
    formState: {},
  } = useForm(formOptionsPush);

  // update email settings
  const updateEmailSettings = async data => {
    await updateEmailNotificationSettings(data);
  };

  // update push settings
  const updatePushSettings = async data => {
    await updatePushNotificationSettings(data);
  };

  // get email settings
  const getEmailSetting = async () => {
    setLoading(true);
    const res = await getEmailSettings();
    const {
      email,
      email_broadcast,
      email_trade_diary,
      email_watchlist,
      email_open_position,
      email_resources,
      email_newsletter,
      email_webinar,
      email_subs_new,
      email_subs_canc,
    } = res.data.data;
    setEmailValue('email', email === 1 ? true : false);
    setEmailValue('broadcast', email_broadcast === 1 ? true : false);
    setEmailValue('trade_diary', email_trade_diary === 1 ? true : false);
    setEmailValue('watchlist', email_watchlist === 1 ? true : false);
    setEmailValue('open_position', email_open_position === 1 ? true : false);
    setEmailValue('resources', email_resources === 1 ? true : false);
    setEmailValue('newsletter', email_newsletter === 1 ? true : false);
    setEmailValue('webinar', email_webinar === 1 ? true : false);
    setEmailValue('subs_new', email_subs_new === 1 ? true : false);
    setEmailValue('subs_canc', email_subs_canc === 1 ? true : false);
    setLoading(false);
  };

  // get push settings
  const getPushSetting = async () => {
    const res = await getPushSettings();
    const {
      push,
      broadcast,
      trade_diary,
      watchlist,
      open_position,
      resources,
      newsletter,
      webinar,
      subs_new,
      subs_canc,
    } = res.data.data;
    setPushValue('push', push === 1 ? true : false);
    setPushValue('broadcast', broadcast === 1 ? true : false);
    setPushValue('trade_diary', trade_diary === 1 ? true : false);
    setPushValue('watchlist', watchlist === 1 ? true : false);
    setPushValue('open_position', open_position === 1 ? true : false);
    setPushValue('resources', resources === 1 ? true : false);
    setPushValue('newsletter', newsletter === 1 ? true : false);
    setPushValue('webinar', webinar === 1 ? true : false);
    setPushValue('subs_new', subs_new === 1 ? true : false);
    setPushValue('subs_canc', subs_canc === 1 ? true : false);
  };

  // swith email
  const switchEmail = () => {
    const email = getEmailValue('email');
    setEmailValue('broadcast', email ? true : false);
    setEmailValue('trade_diary', email ? true : false);
    setEmailValue('watchlist', email ? true : false);
    setEmailValue('open_position', email ? true : false);
    setEmailValue('resources', email ? true : false);
    setEmailValue('newsletter', email ? true : false);
    setEmailValue('webinar', email ? true : false);
    setEmailValue('subs_new', email ? true : false);
    setEmailValue('subs_canc', email ? true : false);
  };

  // swith push
  const switchPush = () => {
    const push = getPushValue('push');
    setPushValue('broadcast', push ? true : false);
    setPushValue('trade_diary', push ? true : false);
    setPushValue('watchlist', push ? true : false);
    setPushValue('open_position', push ? true : false);
    setPushValue('resources', push ? true : false);
    setPushValue('newsletter', push ? true : false);
    setPushValue('webinar', push ? true : false);
    setPushValue('subs_new', push ? true : false);
    setPushValue('subs_canc', push ? true : false);
  };

  useEffect(() => {
    getEmailSetting();
    getPushSetting();
  }, []);

  useEffect(() => {
    const subscription = emailSettingsWatch(async value => {
      submitEmailRef.current.click();
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const subscription = pushSettingsWatch(async value => {
      submitPushRef.current.click();
    });
    return () => subscription.unsubscribe();
  }, []);

  return (
    <div className={`cs-container_md ${loading ? 'cs-loading_wrap' : ''}`}>
      {loading && <LoadingSpinner />}
      <Spacing lg="40" md="40" />
      <h2 style={{ fontSize: '22px', marginBottom: '0', fontWeight: '600' }}>
        {t('notification_settings_title')}
      </h2>
      <Spacing lg="30" md="30" />
      <div className="cs-notice_section_heading">
        <h3>{t('notification_settings_secondary_title_1')}</h3>
        <div>
          <label className="cs-switch" onChange={switchEmail}>
            <input type="checkbox" {...registerEmailSettings('email')} />
            <span></span>
          </label>
        </div>
      </div>
      <form
        onSubmit={handleEmailSubmit(updateEmailSettings)}
        className="cs-row"
      >
        <div className="cs-col_4">
          <div className="cs-check">
            <input type="checkbox" {...registerEmailSettings('broadcast')} />
            <label>{t('broadcast_text')}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <div className="cs-col_4">
          <div className="cs-check">
            <input type="checkbox" {...registerEmailSettings('trade_diary')} />
            <label>{t('trade_dairy_text')}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <div className="cs-col_4">
          <div className="cs-check">
            <input type="checkbox" {...registerEmailSettings('watchlist')} />
            <label>{t('watchlist_text')}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <div className="cs-col_4">
          <div className="cs-check">
            <input
              type="checkbox"
              {...registerEmailSettings('open_position')}
            />
            <label>{t('open_position_text')}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <div className="cs-col_4">
          <div className="cs-check">
            <input type="checkbox" {...registerEmailSettings('newsletter')} />
            <label>{t('newsletter_text')}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <div className="cs-col_4">
          <div className="cs-check">
            <input type="checkbox" {...registerEmailSettings('webinar')} />
            <label>{t('webinar_video_text')}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <div className="cs-col_4">
          <div className="cs-check">
            <input type="checkbox" {...registerEmailSettings('resources')} />
            <label>{t('resources_faq_text')}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <div className="cs-col_4">
          <div className="cs-check">
            <input type="checkbox" {...registerEmailSettings('subs_canc')} />
            <label>{t('cancel_subscribtion_text')}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <div className="cs-col_4">
          <div className="cs-check">
            <input type="checkbox" {...registerEmailSettings('subs_new')} />
            <label>{t('subscribe_new_creator_text')}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <button ref={submitEmailRef} style={{ display: 'none' }} type="submit">
          {t('submit_text')}
        </button>
      </form>
      <Spacing lg="30" md="30" />
      <div className="cs-notice_section_heading">
        <h3>{t('notification_settings_secondary_title_2')}</h3>
        <div>
          <label className="cs-switch" onChange={switchPush}>
            <input type="checkbox" {...registerPushSettings('push')} />
            <span></span>
          </label>
        </div>
      </div>
      <form onSubmit={handlePushSubmit(updatePushSettings)} className="cs-row">
        <div className="cs-col_4">
          <div className="cs-check">
            <input type="checkbox" {...registerPushSettings('broadcast')} />
            <label>{t('broadcast_text')}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <div className="cs-col_4">
          <div className="cs-check">
            <input type="checkbox" {...registerPushSettings('trade_diary')} />
            <label>{t('trade_dairy_text')}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <div className="cs-col_4">
          <div className="cs-check">
            <input type="checkbox" {...registerPushSettings('watchlist')} />
            <label>{t('watchlist_text')}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <div className="cs-col_4">
          <div className="cs-check">
            <input type="checkbox" {...registerPushSettings('open_position')} />
            <label>{t('open_position_text')}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <div className="cs-col_4">
          <div className="cs-check">
            <input type="checkbox" {...registerPushSettings('newsletter')} />
            <label>{t('newsletter_text')}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <div className="cs-col_4">
          <div className="cs-check">
            <input type="checkbox" {...registerPushSettings('webinar')} />
            <label>{t('webinar_video_text')}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <div className="cs-col_4">
          <div className="cs-check">
            <input type="checkbox" {...registerPushSettings('resources')} />
            <label>{t('resources_faq_text')}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <div className="cs-col_4">
          <div className="cs-check">
            <input type="checkbox" {...registerPushSettings('subs_canc')} />
            <label>{t('cancel_subscribtion_text')}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <div className="cs-col_4">
          <div className="cs-check">
            <input type="checkbox" {...registerPushSettings('subs_new')} />
            <label>{t('subscribe_new_creator_text')}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <button ref={submitPushRef} style={{ display: 'none' }} type="submit">
          {t('submit_text')}
        </button>
      </form>
      <Spacing lg="45" md="45" />
    </div>
  );
}
