import React from 'react';
import { useState } from 'react';
import { getCountryListUrl } from '../../api/const';
import LoadingSpinner from '../../components/Common/LoadingSpinner';
import Spacing from '../../components/Spacing';
import useFetch from '../../hooks/useFetch';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext } from 'react';
import UserContext from '../../context/user/user-context';
import { useEffect } from 'react';
import { dateConvart } from '../../utils/dateConvart';
import { updateUserInfo } from '../../api/services/auth/user';
import { useTranslation } from 'react-i18next';

export default function BasicInfo() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { data: countries, loading: fetchLoading } =
    useFetch(getCountryListUrl);

  const { user, getUserInformation } = useContext(UserContext);

  const formSchema = Yup.object().shape({
    name_show: Yup.string(),
    whatsapp: Yup.string(),
    telegram: Yup.string(),
    discord: Yup.string(),
    fullname: Yup.string(),
    email: Yup.string(),
    about_yourself: Yup.string(),
    dob: Yup.string().required('The date of birth field required'),
    country: Yup.string().required('The country field is required'),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm(formOptions);

  // handle update
  const handleUpdate = async data => {
    setLoading(true);
    delete data.email;
    await updateUserInfo(data);
    getUserInformation();
    setLoading(false);
  };

  useEffect(() => {
    setValue('name_show', user.name_show);
    setValue('dob', dateConvart(user.dob));
    setValue('email', user.email);
    setValue('fullname', ' ');
    setValue('whatsapp', ' ');
    setValue('telegram', ' ');
    setValue('discord', ' ');
    setValue('about_yourself', ' ');
  }, []);

  useEffect(() => {
    setLoading(fetchLoading);
    if (!fetchLoading) {
      setValue('country', user.country);
    }
  }, [fetchLoading, countries]);

  return (
    <form
      onSubmit={handleSubmit(handleUpdate)}
      className={`cs-container_md ${loading ? 'cs-loading_wrap' : ''}`}
    >
      {loading && (
        <>
          <Spacing lg="50" md="50" />
          <LoadingSpinner />
        </>
      )}
      <Spacing lg="50" md="50" />
      <div className="cs-section_heading_3">
        <div className="cs-section_heading_left">
          <h2 className="cs-section_title">{t('my_basic_details')}</h2>
        </div>
        <div className="cs-section_heading_right">
          <button className="cs-section_btn">{t('save_text')}</button>
        </div>
      </div>
      <Spacing lg="60" md="40" />
      <div className="cs-row">
        <div className="cs-col_6">
          <label>{t('register_input_display_name')}</label>
          <input
            type="text"
            {...register('name_show')}
            className="cs-form_field"
            disabled
          />
          <Spacing lg="35" md="35" />
        </div>
        <div className="cs-col_6">
          <label>{t('personal_info_input_label_3')}</label>
          <select className="cs-form_field" {...register('country')}>
            <option value="">Select Country</option>
            {countries.data?.map(country => {
              return (
                <option key={country.id} value={country.nicename}>
                  {country.nicename}
                </option>
              );
            })}
          </select>
          <Spacing lg="35" md="35" />
        </div>
        <div className="cs-col_6">
          <label>{t('personal_info_input_label_2')}</label>
          <input type="date" className="cs-form_field" {...register('dob')} />
          <Spacing lg="35" md="35" />
        </div>
      </div>
      <h2 style={{ fontSize: '22px', marginBottom: '0', fontWeight: '600' }}>
        {t('other_information')}
      </h2>
      <Spacing lg="25" md="25" />
      <div className="cs-col_6">
        <label>{t('your_email_address')}</label>
        <input
          type="text"
          className="cs-form_field"
          {...register('email')}
          disabled
        />
      </div>
      <Spacing lg="60" md="60" />
    </form>
  );
}
