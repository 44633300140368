import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { paymentCancelVerify } from "../api/services/public/creator";

export default function PaymentCancel() {
  let [searchParams] = useSearchParams();
  const creatorId = searchParams.get("creator_id") ?? "";
  const sessionId = searchParams.get("session_id") ?? "";
  const navigate = useNavigate();
  useEffect(() => {
    const handle = async () => {
      try {
        const res = await toast.promise(paymentCancelVerify(sessionId), {
          pending: "Please wait...",
          error: "Something went wrong 🤯",
        });
        if (res.data.success === 1) {
          toast.error("Payment canceled 🤯");
          navigate(`/creator-public-view/${creatorId}`);
        } else {
          toast.error("Something went wrong 🤯");
          navigate(`/creator-public-view/${creatorId}`);
        }
      } catch {
        toast.error("Something went wrong 🤯");
        navigate(`/creator-public-view/${creatorId}`);
      }
    };
    handle();
  }, []);
}
