import { Icon } from '@iconify/react';
import React from 'react';
import { Link } from 'react-router-dom';
import Spacing from '../Spacing';
import { useTranslation } from 'react-i18next';

export default function RelatedPages() {
  const { t } = useTranslation();
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <h3>Related Pages</h3>
      <ol>
        <li>
          <Link to="/member/login">Login</Link>
        </li>
        <li>
          <Link to="/member/register">Register</Link>
        </li>
        <li>
          <Link to="/member/email-verification">Email Verification</Link>
        </li>
        <li>
          <Link to="/member/fogot-password">Fogot Password</Link>
        </li>
        <li>
          <Link to="/member/email-confirmation">Email Confirmation</Link>
        </li>
        <li>
          <Link to="/member/update-password">Update Password</Link>
        </li>
        <li>
          <Link to="/member/personal-info">Personal Info</Link>
        </li>
      </ol>
      <Spacing lg="50" md="50" />
      <Spacing lg="50" md="50" />
      <Spacing lg="50" md="50" />
      <button className="cs-logout_btn cs-primary_font cs-loading_wrap">
        <span className="cs-btn_loading">
          <Icon icon="la:spinner" />
          {t('loading_text')}
        </span>
        Submit Now
      </button>
      <Spacing lg="50" md="50" />
      <div className="cs-loading_wrap">
        <span className="cs-section_loading">
          <Icon icon="la:spinner" />
          {t('loading_text')}
        </span>

        <h1>Content</h1>
        <h1>Content</h1>
      </div>
      <Spacing lg="50" md="50" />
      {/* Empty Data */}
      <div className="empty_data">
        <img src="/images/available.svg" alt="SVG" />
        <p>{t('no_data_text')}</p>
      </div>
      <Spacing lg="50" md="50" />
      {/* Lock Seciton */}
      <div className="cs-lock_wrap">
        <div className="cs-lock_overlay">
          <svg
            width={30}
            height={45}
            viewBox="0 0 30 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.375 18.3019V9.375C24.375 4.19813 20.1788 0 15 0C9.81938 0 5.625 4.19813 5.625 9.375V18.3019C2.19937 21.0506 0 25.2656 0 30C0 38.2838 6.71625 45 15 45C23.2838 45 30 38.2838 30 30C30 25.2656 27.8006 21.0506 24.375 18.3019ZM9.375 9.375C9.375 6.27375 11.8987 3.75 15 3.75C18.1013 3.75 20.625 6.27375 20.625 9.375V16.1006C18.8869 15.3956 16.9913 15 15 15C13.0087 15 11.1131 15.3956 9.375 16.1006V9.375ZM15 41.25C8.79562 41.25 3.75 36.2044 3.75 30C3.75 23.7956 8.79562 18.75 15 18.75C21.2044 18.75 26.25 23.7956 26.25 30C26.25 36.2044 21.2044 41.25 15 41.25ZM18.75 30C18.75 32.07 17.07 33.75 15 33.75C12.93 33.75 11.25 32.07 11.25 30C11.25 27.93 12.93 26.25 15 26.25C17.07 26.25 18.75 27.93 18.75 30Z"
              fill="white"
            />
          </svg>
          <h3>Subscribe to unlock post</h3>
          <a className="cs-lock_btn" href="/">
            Subscribe now
          </a>
        </div>
        <h1>Content</h1>
        <h1>Content</h1>
        <h1>Content</h1>
        <h1>Content</h1>
      </div>
    </div>
  );
}
