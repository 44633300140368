import { Icon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { logoutUser } from "../../api/services/auth/user";
import Card3 from "../Card/Card3";
import Spacing from "../Spacing";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function DashboardSideNav({ creator }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [logoutLoading, setLogoutLoading] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const location = useLocation();

  const isFeatures = (item) => {
    return creator.features?.find(({ featureName }) => featureName === item);
  };

  // handle logout
  const handleLogout = async () => {
    setLogoutLoading(true);
    await logoutUser({ type: "2", device: 3 });
    setLogoutLoading(false);
    localStorage.removeItem("token");
    localStorage.removeItem("isAuth");
    sessionStorage.setItem("currentPath", "/");
    localStorage.removeItem("closeBroadcastDisclaimer");
    localStorage.removeItem("closeTradeDiaryDisclaimer");
    navigate("/auth/login");
  };

  useEffect(() => {
    // Check if the first link exists
    const firstLink = menuRef.current?.querySelector("a:first-child");
    if (firstLink) {
      // Programmatically click the first link
      if (location.pathname.includes("broadcasts")) {
        const regex = /^\/dashboard\/\d+\/broadcasts\/\d+$/;
        if (!regex.test(location.pathname)) {
          firstLink.click();
        }
      }
    }
  }, [creator]);

  return (
    <>
      <button className="cs-side_nav_toggle" onClick={() => setOpen(!open)}>
        <Icon icon="ic:round-menu" />
      </button>
      <div
        className={open ? "cs-side_nav_overlay active" : "cs-side_nav_overlay"}
        onClick={() => setOpen(false)}
      ></div>
      <div className={open ? "cs-side_nav active" : "cs-side_nav"}>
        <div className="cs-side_nav_top">
          <Link to="/" className="cs-bak_list_btn cs-semibold">
            <>
              <Icon icon="ph:arrow-left-duotone" />
              <span>{t("back_to_home")}</span>
            </>
          </Link>
          <Spacing lg="25" md="25" />
          {creator.id && (
            <>
              <Card3 creator={creator} />
              <Spacing lg="30" md="30" />

              <ul className="cs-side_nav_list" ref={menuRef}>
                {isFeatures("Broadcast") && (
                  <li>
                    <NavLink to={`/dashboard/${creator.id}/broadcasts`}>
                      <>
                        <span className="cs-side_nav_icon">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.9996 10.1538C13.7857 11.3677 12.1394 12.0497 10.4227 12.0497C8.70602 12.0497 7.05965 11.3677 5.84577 10.1538C4.6319 8.93997 3.94995 7.2936 3.94995 5.57692C3.94995 3.86024 4.6319 2.21388 5.84577 1L14.9996 10.1538Z"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10.4336 5.566L13.3844 2.61523"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M4.72615 8.61328L1 14.9994H8L6.83692 10.9394"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <span className="cs-side_nav_text">
                          {t("broadcasts")}
                        </span>
                      </>
                    </NavLink>
                  </li>
                )}
                {isFeatures("Trade Diary") && (
                  <li>
                    <NavLink to={`/dashboard/${creator.id}/trade-diary`}>
                      <>
                        <span className="cs-side_nav_icon">
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.97003 7.39764C7.8217 7.46205 7.66173 7.49528 7.50003 7.49528C7.33832 7.49528 7.17835 7.46205 7.03003 7.39764L1.33003 4.75764C1.23747 4.71096 1.15968 4.6395 1.10534 4.55122C1.05099 4.46294 1.02222 4.36131 1.02222 4.25764C1.02222 4.15398 1.05099 4.05234 1.10534 3.96407C1.15968 3.87579 1.23747 3.80433 1.33003 3.75764L7.03003 1.09764C7.17835 1.03323 7.33832 1 7.50003 1C7.66173 1 7.8217 1.03323 7.97003 1.09764L13.67 3.73764C13.7626 3.78433 13.8404 3.85579 13.8947 3.94407C13.9491 4.03234 13.9778 4.13398 13.9778 4.23764C13.9778 4.34131 13.9491 4.44294 13.8947 4.53122C13.8404 4.6195 13.7626 4.69096 13.67 4.73764L7.97003 7.39764Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M14 7.84766L7.9 10.6577C7.76972 10.7171 7.62819 10.7478 7.485 10.7478C7.34181 10.7478 7.20028 10.7171 7.07 10.6577L1 7.84766"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M14 11.0977L7.9 13.9077C7.76972 13.9671 7.62819 13.9978 7.485 13.9978C7.34181 13.9978 7.20028 13.9671 7.07 13.9077L1 11.0977"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <span className="cs-side_nav_text">
                          {t("trade_diary")}
                        </span>
                      </>
                    </NavLink>
                  </li>
                )}
                {isFeatures("Watchlist") && (
                  <li>
                    <NavLink to={`/dashboard/${creator.id}/watchlists`}>
                      <>
                        <span className="cs-side_nav_icon">
                          <svg
                            width="15"
                            height="20"
                            viewBox="0 0 15 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.1 4.09961H2.3C1.58203 4.09961 1 4.68164 1 5.39961V17.0996C1 17.8176 1.58203 18.3996 2.3 18.3996H10.1C10.818 18.3996 11.4 17.8176 11.4 17.0996V5.39961C11.4 4.68164 10.818 4.09961 10.1 4.09961Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M3.6001 7.34961H8.8001"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M3.6001 10.5996H8.8001"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M3.6001 13.8496H6.2001"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M4.25 1.5H12.7C13.0448 1.5 13.3754 1.63696 13.6192 1.88076C13.863 2.12456 14 2.45522 14 2.8V15.15"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <span className="cs-side_nav_text">
                          {t("watchlists")}
                        </span>
                      </>
                    </NavLink>
                  </li>
                )}
                {isFeatures("Open Position") && (
                  <li>
                    <NavLink to={`/dashboard/${creator.id}/open-position`}>
                      <>
                        <span className="cs-side_nav_icon">
                          <svg
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16 16H1"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10.2307 6.76908V11.9614C10.2307 12.5986 10.7473 13.1152 11.3846 13.1152H13.6923C14.3295 13.1152 14.8461 12.5986 14.8461 11.9614V6.76908C14.8461 6.13183 14.3295 5.61523 13.6923 5.61523H11.3846C10.7473 5.61523 10.2307 6.13183 10.2307 6.76908Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M2.15381 2.1537L2.15381 11.9614C2.15381 12.5986 2.6704 13.1152 3.30765 13.1152H5.61535C6.2526 13.1152 6.76919 12.5986 6.76919 11.9614L6.76919 2.1537C6.76919 1.51644 6.2526 0.99985 5.61535 0.99985H3.30765C2.6704 0.99985 2.15381 1.51644 2.15381 2.1537Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <span className="cs-side_nav_text">
                          {t("open_position_text")}
                        </span>
                      </>
                    </NavLink>
                  </li>
                )}
                {isFeatures("Newsletter") && (
                  <li>
                    <NavLink to={`/dashboard/${creator.id}/newsletter`}>
                      <>
                        <span className="cs-side_nav_icon">
                          <svg
                            width="17"
                            height="15"
                            viewBox="0 0 17 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.5 13.9999C6.8099 12.3872 4.51785 11.3427 2.02694 11.0499C1.74342 11.0227 1.48157 10.9054 1.29193 10.7208C1.10228 10.5361 0.998291 10.2971 1.00002 10.0499V1.99988C1.00001 1.85544 1.0361 1.71271 1.10582 1.58151C1.17553 1.45032 1.27721 1.33377 1.40387 1.23988C1.52829 1.1477 1.67391 1.07952 1.83108 1.03986C1.98824 1.00021 2.15333 0.989988 2.3154 1.00988C4.69727 1.35249 6.87622 2.38126 8.5 3.92988V13.9999Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8.5 13.9999C10.1901 12.3872 12.4821 11.3427 14.9731 11.0499C15.2566 11.0227 15.5184 10.9054 15.7081 10.7208C15.8977 10.5361 16.0017 10.2971 16 10.0499V1.99988C16 1.85544 15.9639 1.71271 15.8942 1.58151C15.8245 1.45032 15.7228 1.33377 15.5961 1.23988C15.4717 1.1477 15.3261 1.07952 15.1689 1.03986C15.0118 1.00021 14.8467 0.989988 14.6846 1.00988C12.3027 1.35249 10.1238 2.38126 8.5 3.92988V13.9999Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <span className="cs-side_nav_text">
                          {t("newsletter_text")}
                        </span>
                      </>
                    </NavLink>
                  </li>
                )}
                {isFeatures("Webinar / Video") && (
                  <li>
                    <NavLink to={`/dashboard/${creator.id}/webinar-video`}>
                      <>
                        <span className="cs-side_nav_icon">
                          <svg
                            width="17"
                            height="13"
                            viewBox="0 0 17 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.75"
                              y="0.75"
                              width="15.5"
                              height="11.5"
                              rx="3.25"
                              stroke="currentColor"
                              strokeWidth="1.5"
                            />
                            <path
                              d="M12 6.5L6.75 9.53109L6.75 3.46891L12 6.5Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        <span className="cs-side_nav_text">
                          {t("webinar_video_text")}
                        </span>
                      </>
                    </NavLink>
                  </li>
                )}
                {isFeatures("Resources + FAQ") && (
                  <li>
                    <NavLink to={`/dashboard/${creator.id}/resources-faq`}>
                      <>
                        <span className="cs-side_nav_icon">
                          <svg
                            width={15}
                            height={15}
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6 7.5C6 8.12132 5.49632 8.625 4.875 8.625C4.25368 8.625 3.75 8.12132 3.75 7.5C3.75 6.87868 4.25368 6.375 4.875 6.375C5.49632 6.375 6 6.87868 6 7.5Z"
                              fill="currentColor"
                            />
                            <path
                              d="M10.125 8.625C10.7463 8.625 11.25 8.12132 11.25 7.5C11.25 6.87868 10.7463 6.375 10.125 6.375C9.50368 6.375 9 6.87868 9 7.5C9 8.12132 9.50368 8.625 10.125 8.625Z"
                              fill="currentColor"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C6.08279 15 4.75544 14.6062 3.62378 13.9218L1.34982 14.5906C0.774632 14.7598 0.240171 14.2254 0.409356 13.6502L1.07817 11.3762C0.393787 10.2446 0 8.91721 0 7.5ZM7.5 1.5C4.18629 1.5 1.5 4.18629 1.5 7.5C1.5 8.68265 1.8414 9.78327 2.43074 10.7114C2.60077 10.9791 2.65914 11.3169 2.56374 11.6413L2.2325 12.7675L3.3587 12.4363C3.68306 12.3409 4.02085 12.3992 4.28863 12.5693C5.21673 13.1586 6.31735 13.5 7.5 13.5C10.8137 13.5 13.5 10.8137 13.5 7.5C13.5 4.18629 10.8137 1.5 7.5 1.5Z"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                        <span className="cs-side_nav_text">
                          {t("resources_faq_text")}
                        </span>
                      </>
                    </NavLink>
                  </li>
                )}
              </ul>
            </>
          )}
        </div>
        <div className="cs-side_nav_bottom">
          <button
            onClick={handleLogout}
            className={`cs-logout_btn cs-primary_font ${
              logoutLoading ? "cs-loading_wrap" : ""
            }`}
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.655 2.75781C17.6828 2.75781 20.1522 5.18492 20.1522 8.17306V14.0152H12.7067C12.1731 14.0152 11.7512 14.4299 11.7512 14.9543C11.7512 15.4666 12.1731 15.8934 12.7067 15.8934H20.1522V21.7234C20.1522 24.7115 17.6828 27.1508 14.6301 27.1508H8.58685C5.54658 27.1508 3.07715 24.7237 3.07715 21.7356V8.18525C3.07715 5.18492 5.55899 2.75781 8.59926 2.75781H14.655ZM23.2504 10.7468C23.6163 10.3687 24.2139 10.3687 24.5798 10.7346L28.1412 14.2837C28.3241 14.4667 28.4217 14.6984 28.4217 14.9546C28.4217 15.1985 28.3241 15.4424 28.1412 15.6132L24.5798 19.1623C24.3969 19.3453 24.1529 19.4429 23.9212 19.4429C23.6773 19.4429 23.4333 19.3453 23.2504 19.1623C22.8845 18.7965 22.8845 18.1988 23.2504 17.8329L25.2018 15.8937H20.1525V14.0154H25.2018L23.2504 12.0762C22.8845 11.7103 22.8845 11.1127 23.2504 10.7468Z"
                fill="white"
              />
            </svg>
            {logoutLoading && (
              <span className="cs-btn_loading">
                <Icon icon="la:spinner" />
                {t("loading_text")}
              </span>
            )}
            <span>{t("logout")}</span>
          </button>
        </div>
      </div>
    </>
  );
}
