import moment from "moment/moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useOutletContext, useParams } from "react-router-dom";
import { creatorNewsletter } from "../../api/services/creator/newsletter";
import { checkFileExt } from "../../utils/checkFileExt";
import { getFileName } from "../../utils/getFileName";
import { tagStringToArray } from "../../utils/tagStringToArray";
import LoadingSpinner from "../Common/LoadingSpinner";
import SvgIcon from "../Common/SvgIcon";
import Spacing from "../Spacing";
import { v4 as uuidv4 } from "uuid";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

export default function NewsletterDetailsView() {
  const { t } = useTranslation();
  const params = useParams();
  const [creator] = useOutletContext();
  const [newsletter, setNewsletter] = useState([]);
  const [loading, setLoading] = useState(false);

  // get single newsletter
  const singleNewsletter = async () => {
    setLoading(true);
    const res = await creatorNewsletter(
      params.creator_id,
      1,
      5,
      "",
      "",
      "",
      params.id
    );
    let data = res.data.data;
    setLoading(false);
    setNewsletter(tagStringToArray(data));
  };

  useEffect(() => {
    singleNewsletter();
  }, []);
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <Link
        to={`/dashboard/${params.creator_id}/newsletter`}
        className="cs-bak_list_btn cs-semibold"
      >
        <>
          <Icon icon="ph:arrow-left-duotone" />
          <span>{t("back_to_list_text")}</span>
        </>
      </Link>
      <Spacing lg="20" md="20" />
      <div className={`cs-single_view ${loading ? "cs-loading_wrap" : ""}`}>
        {loading && (
          <>
            <Spacing lg="50" md="50" />
            <LoadingSpinner />
          </>
        )}
        <div className="cs-single_view_head">
          <h2>{newsletter[0]?.title}</h2>
          <div className="cs-single_view_date">
            <span>
              <svg
                width={15}
                height={18}
                viewBox="0 0 15 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.7353 0.0920902C14.814 0.141534 14.8785 0.208416 14.9232 0.28683C14.9678 0.365244 14.9912 0.452779 14.9914 0.541707V8.66731C14.9913 8.7755 14.9568 8.88119 14.8923 8.97079C14.8278 9.06038 14.7361 9.12977 14.6293 9.17002L14.4148 8.66731L14.6293 9.17002L14.6258 9.1711L14.6189 9.17435L14.5924 9.1841C14.4407 9.24072 14.2881 9.29489 14.1345 9.34661C13.8301 9.44954 13.4069 9.58821 12.9272 9.72581C11.9862 9.99883 10.7603 10.2924 9.80204 10.2924C8.82529 10.2924 8.01691 9.98908 7.31347 9.72364L7.28118 9.71281C6.55007 9.43654 5.92735 9.20902 5.18931 9.20902C4.38209 9.20902 3.3004 9.45821 2.37901 9.72581C1.96647 9.8466 1.55769 9.97847 1.15318 10.1213V16.7929C1.15318 16.9366 1.09243 17.0744 0.984301 17.176C0.87617 17.2776 0.729512 17.3346 0.57659 17.3346C0.423669 17.3346 0.277011 17.2776 0.16888 17.176C0.0607479 17.0744 0 16.9366 0 16.7929V0.541707C0 0.398037 0.0607479 0.260252 0.16888 0.158662C0.277011 0.0570726 0.423669 0 0.57659 0C0.729512 0 0.87617 0.0570726 0.984301 0.158662C1.09243 0.260252 1.15318 0.398037 1.15318 0.541707V0.84723C1.4138 0.76164 1.72516 0.663049 2.06419 0.565542C3.00519 0.294689 4.23217 0 5.18931 0C6.15799 0 6.94676 0.300106 7.63521 0.562292L7.6848 0.581793C8.40208 0.85373 9.0271 1.08341 9.80204 1.08341C10.6093 1.08341 11.6909 0.834229 12.6123 0.566626C13.1374 0.412577 13.6563 0.240561 14.168 0.0509205L14.1899 0.0433366L14.1945 0.0411697H14.1957"
                  fill="#C4CAD3"
                />
              </svg>
              {t("due_to_text")}{" "}
              {moment(newsletter[0]?.created_at).format("DD-MMM-Y")}
            </span>
            <span>
              <svg
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_6435_18005)">
                  <path
                    d="M15.5273 8.18214C15.5273 10.2553 14.7372 12.2436 13.3306 13.7095C11.9241 15.1755 10.0165 15.999 8.02734 15.999C6.03822 15.999 4.13057 15.1755 2.72404 13.7095C1.31752 12.2436 0.527344 10.2553 0.527344 8.18214C0.527344 6.10896 1.31752 4.12071 2.72404 2.65475C4.13057 1.1888 6.03822 0.365234 8.02734 0.365234C10.0165 0.365234 11.9241 1.1888 13.3306 2.65475C14.7372 4.12071 15.5273 6.10896 15.5273 8.18214ZM8.02734 3.78513C8.02734 3.65556 7.97796 3.53129 7.89005 3.43967C7.80214 3.34805 7.68291 3.29657 7.55859 3.29657C7.43427 3.29657 7.31505 3.34805 7.22714 3.43967C7.13923 3.53129 7.08984 3.65556 7.08984 3.78513V9.15925C7.08987 9.24536 7.11174 9.32994 7.15322 9.40441C7.19471 9.47888 7.25435 9.5406 7.32609 9.58331L10.6073 11.5375C10.715 11.5982 10.8412 11.6125 10.9589 11.5775C11.0766 11.5425 11.1765 11.4609 11.2372 11.3501C11.2979 11.2394 11.3147 11.1082 11.2839 10.9848C11.2531 10.8613 11.1771 10.7553 11.0723 10.6894L8.02734 8.87589V3.78513Z"
                    fill="#C4CAD3"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6435_18005">
                    <rect
                      width={15}
                      height="15.6338"
                      fill="white"
                      transform="translate(0.527344 0.365234)"
                    />
                  </clipPath>
                </defs>
              </svg>
              {moment(newsletter[0]?.created_at).format("LT")}
            </span>
          </div>
        </div>
        {newsletter[0]?.image && <img src={newsletter[0]?.image} alt="Thumb" />}
        <div
          dangerouslySetInnerHTML={{ __html: newsletter[0]?.detail }}
          className="cs-break_all"
        ></div>
        {newsletter[0]?.files.length ? (
          <div className="cs-attachment">
            <h3
              className="cs-attachment_title"
              style={{ textTransform: "uppercase" }}
            >
              <svg
                width={15}
                height={25}
                viewBox="0 0 15 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.9595 5.54066V18.2842C12.9595 20.7331 10.6847 22.7167 7.87623 22.7167C5.06772 22.7167 2.79295 20.7331 2.79295 18.2842V4.43253C2.79295 2.90331 4.21627 1.6622 5.97 1.6622C7.72373 1.6622 9.14705 2.90331 9.14705 4.43253V16.0679C9.14705 16.6774 8.57518 17.176 7.87623 17.176C7.17728 17.176 6.60541 16.6774 6.60541 16.0679V5.54066H4.69918V16.0679C4.69918 17.5971 6.1225 18.8382 7.87623 18.8382C9.62996 18.8382 11.0533 17.5971 11.0533 16.0679V4.43253C11.0533 1.98356 8.77851 0 5.97 0C3.16149 0 0.886719 1.98356 0.886719 4.43253V18.2842C0.886719 21.6529 4.01294 24.3789 7.87623 24.3789C11.7395 24.3789 14.8657 21.6529 14.8657 18.2842V5.54066H12.9595Z"
                  fill="currentColor"
                />
              </svg>
              {t("attachments_text")}
            </h3>
            <ul className="cs-attachment_list cs-mp0">
              {newsletter[0]?.files.map((file) => (
                <li key={uuidv4()}>
                  <a href={file} download>
                    <SvgIcon name={checkFileExt(file)} />
                    {getFileName(file)}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        {newsletter[0]?.tag.length ? (
          <div className="cs-hashtag">
            <h3
              className="cs-hashtag_title"
              style={{ textTransform: "uppercase" }}
            >
              <span>#</span>
              {t("hashtag_text")}
            </h3>
            <div className="cs-tags">
              {newsletter[0]?.tag.map((tag) => (
                <Link
                  key={uuidv4()}
                  to={`/dashboard/${creator.id}/newsletter?tag=${tag}`}
                  className="cs-tag"
                >
                  {tag}
                </Link>
              ))}
            </div>
          </div>
        ) : null}
      </div>
      <Spacing lg="60" md="60" />
    </div>
  );
}
