import { creatorDetailsUrl } from "../../const";
import axios from "../../lib/axios";

// get creator details
export const getCreatorDetails = async (creator_id) => {
  return await axios.get(creatorDetailsUrl, {
    params: {
      id: creator_id,
    },
  });
};
