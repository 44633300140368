import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { getLatestBoardcast, getTradeDiary } from "../api/services/feed";
import EmptyData from "../components/Common/EmptyData";

import LoadingSpinner from "../components/Common/LoadingSpinner";
import PaginateButton from "../components/Common/PaginateButton";
import DotOption from "../components/DotOption";
import Spacing from "../components/Spacing";
import { defaultHasMoreCount } from "../config/const/hasMore";
import { tagStringToArray } from "../utils/tagStringToArray";
import { useTranslation } from "react-i18next";

export default function Feed() {
  let [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const [boardcast, setBoardcast] = useState([]);
  const [boardcastLoading, setBoardcastLoading] = useState(false);
  const [boardcastCount, setBoardcastCount] = useState(0);
  const [boardcastPage, setBoardcastPage] = useState(2);
  const [boardcastHasMore, setBoardcastHasMore] = useState(true);
  const [boardcastPaginateLoading, setBoardcastPaginateLoading] =
    useState(false);

  const [tradeDiaryLoading, setTradeDiaryLoading] = useState(false);
  const [tradeDiary, setTradeDiary] = useState([]);
  const [tradeDiaryCount, setTradeDiaryCount] = useState(0);
  const [tradeDiaryPage, setTradeDiaryPage] = useState(2);
  const [tradeDiaryHasMore, setTradeDiaryHasMore] = useState(true);
  const [tradeDiaryPaginateLoading, setTradeDiaryPaginateLoading] =
    useState(false);

  const searchQuery = searchParams.get("search") ?? "";
  const tagQuery = searchParams.get("tag") ?? "";

  // get latest boardcast
  const getBoardCast = async () => {
    setBoardcastLoading(true);
    const res = await getLatestBoardcast(1, 5, searchQuery, tagQuery);
    setBoardcast(tagStringToArray(res.data.data));
    setBoardcastCount(res.data.count);
    setBoardcastLoading(false);
  };

  // boardcast paginate
  const boardcastPaginate = async () => {
    setBoardcastPaginateLoading(true);
    const res = await getLatestBoardcast(
      boardcastPage,
      5,
      searchQuery,
      tagQuery
    );
    setBoardcast([...boardcast, ...tagStringToArray(res.data.data)]);
    setBoardcastPaginateLoading(false);
    setBoardcastPage(boardcastPage + 1);
    if (!res.data.data.length) {
      setBoardcastHasMore(false);
    }
  };

  // get trade diary
  const getTradeDiery = async () => {
    setTradeDiaryLoading(true);
    const res = await getTradeDiary(1, 5, searchQuery);
    setTradeDiary(res.data.data);
    setTradeDiaryCount(res.data.count);
    setTradeDiaryLoading(false);
  };

  // trade diary paginate
  const tradeDiaryPaginate = async () => {
    setTradeDiaryPaginateLoading(true);
    const res = await getTradeDiary(tradeDiaryPage, 5, searchQuery);
    setTradeDiary([...tradeDiary, ...res.data.data]);
    setTradeDiaryPaginateLoading(false);
    setTradeDiaryPage(tradeDiaryPage + 1);
    if (!res.data.data.length) {
      setTradeDiaryHasMore(false);
    }
  };

  useEffect(() => {
    getBoardCast();
    getTradeDiery();
  }, [searchParams]);

  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <div className="cs-layout_1">
        <div className="cs-layout_left">
          <h2 className="cs-section_heading cs-type1">
            <span>{t("feed_title")}</span>
          </h2>
          <p className="cs-light cs-m0" style={{ color: "#8E8EA3" }}>
            {t("feed_subtitle")}
          </p>
          <Spacing lg="10" md="10" />
          <div
            className={`cs-feeds ${boardcastLoading ? "cs-loading_wrap" : ""}`}
          >
            {boardcastLoading && (
              <>
                <Spacing lg="50" md="50" />
                <LoadingSpinner />
              </>
            )}
            {boardcast.map((item) => (
              <div className="cs-feed">
                <Link
                  to={`/dashboard/${item.userId}/broadcasts`}
                  className="cs-feed_img"
                >
                  <img src={item.creator_image} alt="User" />
                </Link>
                <div className="cs-feed_right">
                  <h3 className="cs-feed_title cs-secondary_font">
                    <Link to={`/dashboard/${item.userId}/broadcasts`}>
                      {item.name_show}
                    </Link>
                    <span className="cs-info_color cs-regular">
                      {moment(item.created_at).format("DD-MMM-Y")}
                    </span>
                    <span className="cs-info_color cs-regular">
                      {moment(item.created_at).format("LT")}
                    </span>
                  </h3>
                  <Spacing lg="5" md="5" />
                  <div className="cs-break_all">{item.description}</div>
                  {item.image && <img src={item.image} alt="Feed" />}
                  <Spacing lg="15" md="15" />
                  <div className="cs-tags">
                    {item.tag.map((tag) => {
                      return (
                        <Link
                          to={`/?tag=${tag}`}
                          className={`cs-tag ${
                            tagQuery === tag ? "active" : ""
                          }`}
                        >
                          {tag}
                        </Link>
                      );
                    })}
                  </div>
                </div>
                <DotOption
                  url={`/dashboard/${item.userId}/broadcasts/${item.id}`}
                />
              </div>
            ))}
            {!boardcast.length && !boardcastLoading ? <EmptyData /> : null}
          </div>

          <Spacing lg="20" md="20" />
          {boardcastCount > defaultHasMoreCount && (
            <PaginateButton
              hasMore={boardcastHasMore}
              loading={boardcastPaginateLoading}
              onClick={boardcastPaginate}
            />
          )}
        </div>
        <div className="cs-layout_right">
          <h2 className="cs-section_heading cs-type1">
            <span>{t("feed_sidebar_title")}</span>
          </h2>
          <Spacing lg="30" md="30" />
          <div
            className={`cs-card_1_list ${
              tradeDiaryLoading ? "cs-loading_wrap" : ""
            }`}
          >
            {tradeDiaryLoading && (
              <>
                <Spacing lg="50" md="50" />
                <LoadingSpinner />
              </>
            )}
            {tradeDiary.map((item) => {
              return (
                <div className="cs-card cs-style1">
                  <DotOption
                    url={`/dashboard/${item.userId}/trade-diary/${item.id}`}
                  />
                  <div className="cs-card_top">
                    <Link
                      to={`/dashboard/${item.userId}/broadcasts`}
                      className="cs-card_top_img"
                    >
                      <img src={item.creator_image} alt="User" />
                    </Link>
                    <div className="cs-card_top_right">
                      <h3 className="cs-card_top_title">
                        <Link to={`/dashboard/${item.userId}/broadcasts`}>
                          {item.name_show}
                        </Link>{" "}
                      </h3>
                      <div className="cs-card_top_date cs-info_color">
                        <span>
                          {moment(item.created_at).format("DD-MMM-Y")}
                        </span>{" "}
                        <span>{moment(item.created_at).format("LT")}</span>
                      </div>
                    </div>
                  </div>
                  <h2>{item.stock_name}</h2>
                  <span
                    className="cs-card_label"
                    style={{
                      backgroundColor: item.tradeDairyColorBackground,
                      color: item.tradeDairyColorText,
                      borderColor: item.tradeDairyColorBorder,
                    }}
                  >
                    {item.tradeDairyType}
                  </span>
                  <Spacing lg="10" md="10" />

                  <p className="cs-semibold">
                    <span
                      className="cs-info_color"
                      style={{ marginRight: "5px" }}
                    >
                      {t("price_text")}:
                    </span>
                    <span className="cs-accent_color">
                      {item.stock_price}{" "}
                      {item.stock_price !== "Now" && t("currency")}
                    </span>
                  </p>
                  <Spacing lg="10" md="10" />
                  <p className="cs-semibold">
                    <span
                      className="cs-info_color"
                      style={{ marginRight: "5px" }}
                    >
                      {t("stop_loss_text")}:
                    </span>
                    <span className="cs-primary_color">
                      {item.stop_loss} {t("currency")}
                    </span>
                  </p>
                  <Spacing lg="15" md="15" />
                  {item.note && (
                    <p className="cs-primary_color">
                      {t("note_text")}: {item.note}.
                    </p>
                  )}
                </div>
              );
            })}
            {!tradeDiary.length && !tradeDiaryLoading ? <EmptyData /> : null}
          </div>
          <Spacing lg="20" md="20" />
          {tradeDiaryCount > defaultHasMoreCount && (
            <PaginateButton
              onClick={tradeDiaryPaginate}
              hasMore={tradeDiaryHasMore}
              loading={tradeDiaryPaginateLoading}
            />
          )}
        </div>
      </div>
      <Spacing lg="50" md="50" />
    </div>
  );
}
