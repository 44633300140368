// creator all newsletter
import { creatorNewslettersUrl } from "../../const";
import axios from "../../lib/axios";
export const creatorNewsletter = async (
  creator_id,
  page = 1,
  limit = 5,
  search = "",
  tag = "",
  pin = "",
  post_id = "",
  is_schedule = ""
) => {
  return await axios.get(creatorNewslettersUrl, {
    params: {
      creator_id,
      page,
      limit,
      search,
      pin,
      post_id,
      is_schedule,
      tag,
    },
  });
};
