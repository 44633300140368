import {
  allNotificationUrl,
  notificationDeleteUrl,
  notificationSeenUpdateUrl,
  unreadNotificationUrl,
} from "../../const";
import axios from "../../lib/axios";

// get all notification
export const allNotification = async () => {
  return await axios.get(allNotificationUrl, { params: { type: 2 } });
};

// get all unread notification
export const unreadNotification = async () => {
  return await axios.get(unreadNotificationUrl, { params: { type: 2 } });
};

// notification seen update
export const notificationSeenUpdate = async (post_id) => {
  return await axios.get(notificationSeenUpdateUrl, {
    params: { type: 2, post_id },
  });
};

// notification delete
export const deleteNotification = async (post_id) => {
  return await axios.get(notificationDeleteUrl, {
    params: { post_id, type: 2 },
  });
};
