import {
  allTransactionUrl,
  canceledTransactionUrl,
  cancelSubscribtionUrl,
  subscriptionCheckUrl,
  upcomingTransactionUrl,
} from "../../const";
import axios from "../../lib/axios";

// all billing details
export const allBillingInfo = async () => {
  return await axios.get(allTransactionUrl);
};

// upcomming billing
export const upcommingBillingInfo = async () => {
  return await axios.get(upcomingTransactionUrl);
};

// cancled billing
export const canceledBillingInfo = async () => {
  return await axios.get(canceledTransactionUrl);
};

// check subscribtion
export const checkSubscribtion = async (post_id) => {
  return await axios.get(subscriptionCheckUrl, { params: { post_id } });
};
