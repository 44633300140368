import { Icon } from '@iconify/react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { Link } from "react-router-dom";

export default function DotOption({ url }) {
  const [open, setOpen] = useState(false);
  const memberDoamin = window.location.origin;
  const refOne = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    // event listeners
    document.addEventListener('keydown', hideOnEscape, true);
    document.addEventListener('click', hideOnClickOutside, true);
  }, []);
  // hide dropdown on ESC press
  const hideOnEscape = e => {
    if (e.key === 'Escape') {
      setOpen(false);
    }
  };

  // Hide on outside click
  const hideOnClickOutside = e => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };
  return (
    <div className="cs-dot_wrap" ref={refOne}>
      <button className="cs-dot_btn" onClick={() => setOpen(open => !open)}>
        <svg
          width={23}
          height={5}
          viewBox="0 0 23 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.4302 4C12.1538 4 12.7404 3.32843 12.7404 2.5C12.7404 1.67157 12.1538 1 11.4302 1C10.7067 1 10.1201 1.67157 10.1201 2.5C10.1201 3.32843 10.7067 4 11.4302 4Z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="1.15385"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.6011 4C21.3247 4 21.9113 3.32843 21.9113 2.5C21.9113 1.67157 21.3247 1 20.6011 1C19.8776 1 19.291 1.67157 19.291 2.5C19.291 3.32843 19.8776 4 20.6011 4Z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="1.15385"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.25934 4C2.98291 4 3.56947 3.32843 3.56947 2.5C3.56947 1.67157 2.98291 1 2.25934 1C1.53578 1 0.949219 1.67157 0.949219 2.5C0.949219 3.32843 1.53578 4 2.25934 4Z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="1.15385"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {open && (
        <div className="cs-dot_option">
          <button
            onClick={() => {
              navigator.clipboard.writeText(memberDoamin + url);
              setOpen(false);
            }}
          >
            <Icon icon="material-symbols:file-copy" />
            <span>{t('copy_post_link')}</span>
          </button>
          <a
            href={`https://www.facebook.com/sharer.php?u=${memberDoamin + url}`}
            target="_blank"
          >
            <>
              <Icon icon="fa6-brands:facebook" />
              <span>{t('facebook')}</span>
            </>
          </a>
          <a
            href={`https://twitter.com/intent/tweet?url=${memberDoamin + url}`}
            target="_blank"
          >
            <>
              <Icon icon="fa6-brands:twitter" />
              <span>{t('twitter')}</span>
            </>
          </a>
          <a
            href={`https://www.pinterest.com/pin-builder/?media=${
              memberDoamin + url
            }`}
            target="_blank"
          >
            <>
              <Icon icon="fa6-brands:pinterest" />
              <span>{t('pinterest')}</span>
            </>
          </a>
        </div>
      )}
    </div>
  );
}
