import { Icon } from '@iconify/react';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  createCard,
  getCards,
  getSingleSubscribtion,
  handleCancelSubscribtion,
  makeCardDefault,
} from '../../api/services/mySubscribtion';
import { getPublicCreatorTier } from '../../api/services/public/creator';
import LoadingSpinner from '../../components/Common/LoadingSpinner';
import MembershipTire from '../../components/MembershipTire';
import Spacing from '../../components/Spacing';
import UserContext from '../../context/user/user-context';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

export default function EditSubscription() {
  const { t } = useTranslation();
  const params = useParams();
  const cardAddRef = useRef();
  const { user } = useContext(UserContext);
  const [subscribtion, setSubscribtion] = useState({});
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [cardLoading, setCardLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [tiers, setTiers] = useState([]);
  const [tierLoading, setTierLoading] = useState(false);
  const [cardCreateLoading, setcardCreateLoading] = useState(false);
  const [cardCreateError, setCardCreateError] = useState('');
  const [manageSubs, setManageSubs] = useState('choose_plans');

  const formSchema = Yup.object().shape({
    number: Yup.mixed().nullable(),
    name: Yup.string().nullable(),
    exp_month: Yup.mixed().nullable(),
    exp_year: Yup.mixed().nullable(),
    cvc: Yup.mixed().nullable(),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(formOptions);

  const singleSubscribtion = async () => {
    setLoading(true);
    const res = await getSingleSubscribtion(params.id);
    setSubscribtion(res.data.data);
    setLoading(false);
  };

  // create card
  const handleCardCreate = async data => {
    setcardCreateLoading(true);
    const res = await createCard(data);
    if (res.data.success === 1) {
      getCard();
      setCardCreateError('');
      reset();
    } else {
      setCardCreateError('Oops! Something went wrong');
    }
    setcardCreateLoading(false);
  };

  // get tier
  const getTier = async () => {
    setTiers([]);
    setTierLoading(true);
    const res = await getPublicCreatorTier(subscribtion.creatorId);
    setTiers(res.data.data);
    setTierLoading(false);
  };
  // cancel subscribtion
  const cancelSubscribtion = async () => {
    setCancelLoading(true);
    await handleCancelSubscribtion(subscribtion.id);
    setCancelLoading(false);
    setModal(false);
    setModal2(false);
    setModal3(false);
    setModal4(true);
  };

  // get all card
  const getCard = async () => {
    setCardLoading(true);
    const res = await getCards();
    setCards(res.data.data);
    setCardLoading(false);
  };

  function padWithLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  // handle card primary
  const handleMakePrimary = cardId => {
    makeCardDefault(cardId);
  };

  useEffect(() => {
    singleSubscribtion();
    getCard();
  }, []);
  return (
    <>
      <div className="cs-container_md">
        <Spacing lg="40" md="40" />
        <h2 style={{ fontSize: '20px', marginBottom: '8px' }}>
          {t('edit_subscription_title')}
        </h2>
        <p className="cs-m0 cs-light" style={{ color: '#8E8EA3' }}>
          {t('edit_subscription_subtitle')}
        </p>
        <Spacing lg="30" md="30" />
        <h3 className="cs-semibold cs-m0" style={{ fontSize: '24px' }}>
          {t('edit_subscription_text_1')}
        </h3>
        <Spacing lg="20" md="20" />
        <div
          className={`cs-card cs-style6 ${loading ? 'cs-loading_wrap' : ''}`}
        >
          {loading && <LoadingSpinner />}
          <div className="cs-card_in">
            <div className="cs-card_media">
              <img src={subscribtion.creator_image} alt="User" />
              <div className="cs-card_media_right">
                <h3>{subscribtion.name_show}</h3>
                <p>{subscribtion.tier_name}</p>
              </div>
            </div>
            <div className="cs-card_right">
              <div>
                <span className="cs-card_label">
                  {t('edit_subscription_text_2')}
                </span>
                <Spacing lg="15" md="15" />
                <button className="cs-card_btn" onClick={() => setModal(true)}>
                  {t('edit_subscription_text_3')}
                </button>
              </div>
            </div>
          </div>
          <ul className="cs-card_info_list cs-mp0">
            <li>
              <span>{t('edit_subscription_text_4')}</span>
              <span>{subscribtion.tier_name}</span>
            </li>
            {subscribtion.amount !== '0' && (
              <li>
                <span>{t('edit_subscription_text_5')}</span>
                <span>1 {subscribtion.amount === '0' ? 'Year' : 'Month'} </span>
              </li>
            )}
            <li>
              <span>{t('edit_subscription_text_6')}</span>
              <span>
                ${subscribtion.amount} / {t('edit_subscription_text_7')}
              </span>
            </li>
            <li className="cs-total_paid">
              <span>{t('edit_subscription_text_8')}</span>
              <span>${subscribtion.amount}</span>
            </li>
          </ul>
        </div>
        {subscribtion.amount !== '0' && (
          <>
            <Spacing lg="45" md="40" />
            <div className="cs-card cs-style7">
              <h2 className="cs-card_head">
                <Icon icon="ph:credit-card" />
                {t('edit_subscription_text_9')}
              </h2>
              <div
                className={`cs-card_body ${
                  cardLoading ? 'cs-loading_wrap' : ''
                }`}
              >
                {loading && (
                  <>
                    <Spacing lg="50" md="50" />
                    <LoadingSpinner />
                  </>
                )}
                {cards.map(item => (
                  <ul className="cs-card_info_list cs-mp0" key={uuidv4()}>
                    <li>
                      <div className="cs-active_card">
                        <input
                          type="radio"
                          id="card_1"
                          name="active_card"
                          value="card_1"
                          defaultChecked={item.is_default}
                          onChange={() => handleMakePrimary(item.id)}
                        />
                        <span className="cs-active_card_icon">
                          <Icon icon="material-symbols:check-circle-rounded" />
                        </span>
                        <span>
                          <Icon icon="ph:credit-card" />
                        </span>
                      </div>
                    </li>
                    <li>
                      <span>{t('edit_subscription_text_10')}</span>
                      <span className="cs-medium cs-primary_color">
                        {item?.billing_details?.name}
                      </span>
                    </li>
                    <li>
                      <span>{t('edit_subscription_text_11')}</span>
                      <span className="cs-medium cs-primary_color">
                        {item?.card?.last4}
                      </span>
                    </li>
                    <li>
                      <span>{t('edit_subscription_text_12')}</span>
                      <span className="cs-medium cs-primary_color">
                        {item?.card?.brand}
                      </span>
                    </li>
                    <li>
                      <span>{t('edit_subscription_text_13')}</span>
                      <span className="cs-medium cs-primary_color">
                        {padWithLeadingZeros(item?.card?.exp_month, 2)}/
                        {item?.card?.exp_year}
                      </span>
                    </li>
                  </ul>
                ))}
              </div>
            </div>{' '}
            <Spacing lg="40" md="30" />
            <form
              onSubmit={handleSubmit(handleCardCreate)}
              className="cs_card cs_style_10"
            >
              <div className="cs_card_left">
                <div className="cs_card_head">
                  <div className="cs_card_head_left">
                    <svg
                      width={24}
                      height={25}
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx={12} cy="12.5" r={12} fill="#1BB66E" />
                      <path
                        d="M16.8 9.5L10.2 16.1L7.19995 13.1"
                        stroke="white"
                        strokeWidth={3}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {t('edit_subscription_text_14')}
                  </div>
                  <div className="cs_card_head_right">
                    <svg
                      width={96}
                      height={29}
                      viewBox="0 0 96 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx={14} cy="14.5" r={14} fill="#CF1928" />
                      <circle
                        cx={30}
                        cy="14.5"
                        r={14}
                        fill="#FFD400"
                        fillOpacity="0.7"
                      />
                      <g clipPath="url(#clip0_8269_24834)">
                        <path
                          d="M71.177 10.0011L65.9424 19.0468H62.5185L59.9506 11.815C59.786 11.3615 59.6543 11.2183 59.1934 11.0274C58.1791 10.6734 57.1057 10.4167 56 10.2636L56.0658 10.0011H61.5638C62.3069 10.0035 62.9363 10.3989 63.0453 10.9319L64.3951 16.1589L67.7531 10.0011H71.177ZM84.5761 16.0873C84.5761 13.7005 80.0329 13.5573 80.0658 12.5071C80.0658 12.173 80.4938 11.8389 81.4486 11.7434C82.5439 11.6692 83.6467 11.8093 84.642 12.1491L85.2016 10.2397C84.235 9.97065 83.2083 9.8331 82.1728 9.83399C78.9794 9.83399 76.7407 11.0751 76.7078 12.8174C76.6749 14.1301 78.321 14.8461 79.5391 15.2758C80.7572 15.7054 81.2181 16.0156 81.2181 16.3975C81.2181 16.9942 80.1975 17.2806 79.2757 17.2806C78.1173 17.3038 76.9708 17.1063 75.9506 16.7078L75.358 18.6888C76.498 19.0205 77.7183 19.1828 78.9465 19.1661C82.3374 19.19 84.5432 17.9728 84.5761 16.0873ZM93.0041 19.0468H96L93.3992 10.0011H90.6337C90.0268 9.99579 89.4777 10.2612 89.251 10.6693L84.3786 19.0468H87.7695L88.428 17.6864H92.5761L93.0041 19.0468ZM89.3827 15.8486L91.0946 12.4594L92.0823 15.8486H89.3827ZM75.786 10.0011L73.1193 19.0468H69.893L72.5597 10.0011H75.786Z"
                          fill="#1A1F71"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_8269_24834">
                          <rect
                            width={40}
                            height={28}
                            fill="white"
                            transform="translate(56 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                {cardCreateError && (
                  <p className="cs-danger">{cardCreateError}</p>
                )}
                <div className="cs_card_item">
                  <div className="cs_card_item_left">
                    <h3>{t('edit_subscription_text_15')}</h3>
                    <p>{t('edit_subscription_text_16')}</p>
                  </div>
                  <div className="cs_card_item_right">
                    <div className="cs_card_input_with_icons">
                      <input
                        type="text"
                        className="cs_card_input"
                        {...register('number')}
                        maxLength={16}
                      />
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="cs_card_icon"
                      >
                        <path
                          d="M6 14.25C5.58579 14.25 5.25 14.5858 5.25 15C5.25 15.4142 5.58579 15.75 6 15.75V14.25ZM9.5 15.75C9.91421 15.75 10.25 15.4142 10.25 15C10.25 14.5858 9.91421 14.25 9.5 14.25V15.75ZM5 6.75H19V5.25H5V6.75ZM20.25 8V16H21.75V8H20.25ZM19 17.25H5V18.75H19V17.25ZM3.75 16V8H2.25V16H3.75ZM5 17.25C4.30964 17.25 3.75 16.6904 3.75 16H2.25C2.25 17.5188 3.48122 18.75 5 18.75V17.25ZM20.25 16C20.25 16.6904 19.6904 17.25 19 17.25V18.75C20.5188 18.75 21.75 17.5188 21.75 16H20.25ZM19 6.75C19.6904 6.75 20.25 7.30964 20.25 8H21.75C21.75 6.48122 20.5188 5.25 19 5.25V6.75ZM5 5.25C3.48122 5.25 2.25 6.48122 2.25 8H3.75C3.75 7.30964 4.30964 6.75 5 6.75V5.25ZM3 10.75H21V9.25H3V10.75ZM6 15.75H9.5V14.25H6V15.75Z"
                          fill="black"
                        />
                      </svg>
                      {/* <svg
                        width={32}
                        height={32}
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="cs_check"
                      >
                        <circle
                          cx={16}
                          cy={16}
                          r="15.5"
                          fill="white"
                          stroke="#C9C9C9"
                        />
                        <path
                          d="M23.1111 11.5566L13.3334 21.3344L8.88885 16.89"
                          stroke="#C9C9C9"
                          strokeWidth={3}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg> */}
                    </div>
                  </div>
                </div>
                <Spacing lg="25" md="25" />
                <div className="cs_card_item">
                  <div className="cs_card_item_left">
                    <h3>{t('edit_subscription_text_17')}</h3>
                    <p>{t('edit_subscription_text_18')}</p>
                  </div>
                  <div className="cs_card_item_right">
                    <input
                      type="text"
                      className="cs_card_input"
                      {...register('name')}
                      required
                    />
                  </div>
                </div>
                <Spacing lg="25" md="25" />
                <div className="cs_card_item">
                  <div className="cs_card_item_left">
                    <h3>{t('edit_subscription_text_19')}</h3>
                    <p>{t('edit_subscription_text_20')}</p>
                  </div>
                  <div className="cs_card_item_right">
                    <div className="cs_card_item cs_type_1">
                      <div className="cs_card_input_group">
                        <input
                          {...register('exp_month')}
                          type="text"
                          className="cs_card_input"
                          maxLength={2}
                        />
                        <span>/</span>
                        <input
                          type="text"
                          {...register('exp_year')}
                          className="cs_card_input"
                          maxLength={2}
                        />
                      </div>
                      <div className="cs_card_item_left">
                        <h3>CVV</h3>
                        <p>{t('edit_subscription_text_21')}</p>
                      </div>
                      <div className="cs_card_item_right">
                        <input
                          {...register('cvc')}
                          type="text"
                          className="cs_card_input"
                          maxLength={3}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Spacing lg="25" md="25" />
                {/* <div className="cs-check">
              <input type="checkbox" />
              <label>Set us default</label>
            </div> */}
              </div>
              <div className="cs_card_right">
                <img src="/images/card.png" alt="" />
              </div>
              <button
                type="submit"
                ref={cardAddRef}
                style={{ display: 'none' }}
              ></button>
            </form>
          </>
        )}

        <Spacing lg="50" md="30" />
        <div className="cs-general_btn_group">
          <div>
            <Link
              to="/settings/my-subscription"
              className="cs-general_btn cs-color1"
            >
              {t('edit_subscription_text_22')}
            </Link>
          </div>
          <div>
            {subscribtion.amount !== '0' && (
              <button
                onClick={() => cardAddRef.current.click()}
                className={`cs-general_btn ${
                  cardCreateLoading ? 'cs-loading_wrap' : ''
                }`}
                disabled={cardCreateLoading}
              >
                {cardCreateLoading && (
                  <span className="cs-btn_loading">
                    <Icon icon="la:spinner" />
                    {t('loading_text')}
                  </span>
                )}
                {t('edit_subscription_text_23')}
              </button>
            )}
          </div>
        </div>
        <Spacing lg="60" md="60" />
      </div>
      {modal && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div className="cs-modal_box cs-style1">
                <div
                  className="cs-modal_box_head cs-bg"
                  style={{ backgroundImage: 'url(/images/modal_img.jpeg)' }}
                >
                  <img
                    src={subscribtion.creator_image}
                    style={{ width: '100px' }}
                    alt="User"
                  />
                </div>
                <Spacing lg="25" md="25" />
                <div className="cs-modal_box_in">
                  <h2
                    className="cs-text_center cs-m0"
                    style={{ fontSize: '18px' }}
                  >
                    {t('edit_subscription_text_24')}
                  </h2>
                  <Spacing lg="25" md="25" />
                  <div className="cs-radio">
                    <input
                      type="radio"
                      id="choose_plans"
                      name="fav_language"
                      onChange={() => setManageSubs('choose_plans')}
                      checked={manageSubs === 'choose_plans'}
                    />
                    <label htmlFor="choose_plans">
                      {t('edit_subscription_text_25')} {subscribtion.name_show}
                    </label>
                  </div>
                  <Spacing lg="20" md="20" />
                  <div className="cs-radio">
                    <input
                      type="radio"
                      id="cancel_subscription"
                      name="fav_language"
                      onChange={() => setManageSubs('cancel_subscription')}
                      checked={manageSubs === 'cancel_subscription'}
                    />
                    <label htmlFor="cancel_subscription">
                      {t('edit_subscription_text_26')} <br />
                      {subscribtion.amount !== '0' && (
                        <span style={{ color: '#767676' }}>
                          {t('edit_subscription_text_28')}{' '}
                          {moment(subscribtion.expire_at).format('D/M/Y')}
                        </span>
                      )}
                    </label>
                  </div>
                  <Spacing lg="70" md="50" />
                  <div className="cs-modal_btns">
                    <button
                      className="cs-modal_btn"
                      onClick={() => setModal(false)}
                    >
                      {t('edit_subscription_text_29')}
                    </button>
                    {manageSubs === 'choose_plans' ? (
                      <button
                        onClick={() => {
                          setModal2(true);
                          getTier();
                        }}
                        className="cs-modal_btn cs-color1"
                      >
                        {t('view_membership_tiers_text')}
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setModal3(true);
                        }}
                        className="cs-modal_btn cs-color1"
                      >
                        {t('cancel_subscribtion_text')}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {modal2 && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal2(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div className="cs-modal_box cs-style2">
                <div className="cs-modal_box_in">
                  <button
                    className="cs-close_btn"
                    onClick={() => setModal2(false)}
                  >
                    <Icon icon="ri:close-circle-fill" />
                  </button>
                  <MembershipTire
                    tiers={tiers}
                    loading={tierLoading}
                    title={t('select_membership_tier')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {modal3 && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal3(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div className="cs-modal_box cs-style1">
                <Spacing lg="45" md="45" />
                <div className="cs-modal_box_in">
                  <div className="cs-text_center">
                    <h2
                      className="cs-semibold cs-m0"
                      style={{ fontSize: '20px' }}
                    >
                      {t('cancel_confirmation_text')}
                    </h2>
                    <Spacing lg="5" md="5" />
                    <div style={{ fontSize: '14px', lineHeight: '1.6em' }}>
                      {t('confirm_cancel_your_subscription_text')}
                    </div>
                  </div>
                  <Spacing lg="30" md="30" />
                  <div className="cs-modal_btns">
                    <button
                      className="cs-modal_btn"
                      onClick={() => setModal3(false)}
                    >
                      {t('cancel_text')}
                    </button>
                    <button
                      className={`cs-modal_btn cs-color1 ${
                        cancelLoading ? 'cs-loading_wrap' : ''
                      }`}
                      disabled={cancelLoading}
                      onClick={() => cancelSubscribtion()}
                    >
                      {cancelLoading && (
                        <span className="cs-btn_loading">
                          <Icon icon="la:spinner" />
                          {t('loading_text')}
                        </span>
                      )}
                      {t('confirm_text')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {modal4 && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal4(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div className="cs-modal_box cs-style1">
                <div
                  className="cs-modal_box_head cs-bg"
                  style={{ backgroundImage: 'url(/images/modal_img.jpeg)' }}
                >
                  <img
                    src={subscribtion.creator_image}
                    style={{ width: '100px' }}
                    alt="User"
                  />
                </div>
                <Spacing lg="25" md="25" />
                <div className="cs-modal_box_in">
                  <h2
                    className="cs-text_center cs-m0"
                    style={{ fontSize: '18px' }}
                  >
                    {t('edit_subscription_text_30')}
                  </h2>
                  <Spacing lg="20" md="20" />
                  <div style={{ textAlign: 'center' }}>
                    {subscribtion.amount !== '0' && (
                      <span>
                        {t('edit_subscription_text_31')}{' '}
                        {moment(subscribtion.expire_at).format('D/M/Y')}
                      </span>
                    )}
                  </div>
                  <Spacing lg="70" md="50" />
                  <div style={{ textAlign: 'center' }}>
                    <Link
                      to="/settings/my-subscription"
                      className="cs-modal_btn cs-color1"
                    >
                      {t('done_text')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
