import React, { useState } from "react";
import { Link } from "react-router-dom";
import NoticeDotOption from "../DotOption/NoticeDotOption";
import { v4 as uuidv4 } from "uuid";

export default function Notification({
  thumb,
  title,
  detail,
  note,
  date,
  type,
  userId,
  seen,
  postId,
  id,
  updateSeen,
  deleteNotification,
  makeNotificationSeen,
}) {
  let link;
  switch (type) {
    case 1:
      link = `/dashboard/${userId}/broadcasts/${postId}`;
      break;
    case 2:
      link = `/dashboard/${userId}/trade-diary/${postId}`;
      break;
    case 3:
      link = `/dashboard/${userId}/watchlists/${postId}`;
      break;
    case 4:
      link = `/dashboard/${userId}/open-positions?post_ids=${postId}`;
      break;
    case 5:
      link = `/dashboard/${userId}/resources-faq/${postId}`;
      break;
    case 6:
      link = `/dashboard/${userId}/newsletter/${postId}`;
      break;
    case 7:
      link = `/dashboard/${userId}/webinar-video/${postId}`;
      break;
    case 99:
      link = "/my-creators";
      break;
    case 101:
      link = "/my-creators?tab=unsubscribed";
      break;
  }

  return (
    <div style={{ position: "relative" }}>
      <Link
        onClick={() => makeNotificationSeen(id)}
        to={link}
        className={`cs-notification ${seen !== 1 ? "active" : ""}`}
      >
        {thumb && (
          <div className="cs-notification_thumb">
            <img src={thumb} alt="" />
          </div>
        )}
        <div className="cs-notification_right">
          {title && (
            <h2 className="cs-notification_title cs-break_all">{title}</h2>
          )}
          {detail.map((item) => (
            <div
              key={uuidv4()}
              className="cs-notification_subtitle cs-break_all"
            >
              {type === 3 && <b>Ticker: </b>}
              {item.substring(0, 100)}
            </div>
          ))}
          {note && (
            <div className="cs-notification_note cs-break_all">
              <b>{type === 3 ? "Creator's Note:" : "Note:"}</b>{" "}
              {note.substring(0, 100)}
            </div>
          )}
          {date && (
            <div className="cs-notification_date cs-break_all">{date}</div>
          )}
        </div>
      </Link>
      <NoticeDotOption
        postId={id}
        seen={seen}
        updateSeen={updateSeen}
        deleteNotifications={deleteNotification}
      />
    </div>
  );
}
