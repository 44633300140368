import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  processToStripePayment,
  processResubscribePayment,
} from "../api/services/public/creator";
import LoadingSpinner from "../components/Common/LoadingSpinner";

export default function SubscribeCreator() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const creatorId = searchParams.get("creator_id");
  const tierId = searchParams.get("tier_id");
  const navigate = useNavigate();
  const invitationCode = searchParams.get("invitation_code");
  const location = useLocation();
  if (token) {
    localStorage.setItem("token", token);
    localStorage.setItem("isAuth", true);
    window.location = `${location.pathname}?creator_id=${creatorId}&tier_id=${tierId}&invitation_code=${invitationCode}`;
  }

  //   window.location = location.pathname;
  // process to pay
  const processToPayment = async () => {
    const baseUrl = window.location.origin;
    // process to subscribe
    if (invitationCode) {
      const data = {
        membership_tire_id: tierId,
        invitation_code: invitationCode,
        success_url: `${baseUrl}/payment/success?creator_id=${creatorId}&`,
        cancel_url: `${baseUrl}/payment/cancel?creator_id=${creatorId}&`,
      };
      const res = await processToStripePayment(data);
      if (res.data.success === 1) {
        if (res.data.data.url) {
          window.location.href = res.data.data.url;
        } else {
          navigate("/my-creators");
        }
      } else {
        toast.error(res.data.message);
        navigate(`/creator-public-view/${creatorId}`);
      }
    }
    // process to re-subscribe
    else {
      const data = {
        membership_tire_id: tierId,
        success_url: `${baseUrl}/payment/success?creator_id=${creatorId}&`,
        cancel_url: `${baseUrl}/payment/cancel?creator_id=${creatorId}&`,
      };
      const res = await processResubscribePayment(data);
      if (res.data.success === 1) {
        if (res.data.data.url) {
          window.location.href = res.data.data.url;
        } else {
          navigate("/my-creators");
        }
      } else {
        toast.error(res.data.message);
        navigate(`/creator-public-view/${creatorId}`);
      }
    }
  };

  useEffect(() => {
    processToPayment();
  }, [creatorId, tierId, invitationCode]);

  return (
    <div
      className="cs-loading_wrap"
      style={{ widows: "100%", height: "100vh" }}
    >
      <LoadingSpinner />
    </div>
  );
}
