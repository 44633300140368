import { Icon } from "@iconify/react";
import moment from "moment/moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  creatorOpenPosition,
  getMultipleOpenPossition,
} from "../../../api/services/creator/openPosition";
import {
  getPublicCreatorDetails,
  getPublicCreatorTier,
} from "../../../api/services/public/creator";
import Card3 from "../../../components/Card/Card3";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import DotOption from "../../../components/DotOption";
import MembershipTire from "../../../components/MembershipTire";
import Spacing from "../../../components/Spacing";
import OpenPositionGraph from "./components/OpenPositionGraph";
import { useTranslation } from "react-i18next";

export default function MultipleOpenPositionDetails() {
  const { t } = useTranslation();
  const { creator_id } = useParams();
  let [searchParams] = useSearchParams();
  const [openPosition, setOpenPosition] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tierLoading, setTierLoading] = useState(false);
  const [tiers, setTiers] = useState([]);
  const [modal, setModal] = useState(false);
  const [creator, setCreator] = useState({});
  const post_ids = searchParams.get("post_ids").replace(/,*$/, "");

  // get open position
  const getAllOpenPostion = async () => {
    setLoading(true);
    const res = await getMultipleOpenPossition(creator_id, post_ids);
    setOpenPosition(res.data.count);
    setLoading(false);
  };

  // get creator
  const getCreator = async () => {
    const res = await getPublicCreatorDetails(creator_id);
    setCreator(res.data.data);
  };

  // get tiers
  const getTiers = async () => {
    setTierLoading(true);
    const res = await getPublicCreatorTier(creator_id);
    setTiers(res.data.data);
    setTierLoading(false);
  };
  useEffect(() => {
    getAllOpenPostion();
    getCreator();
  }, [creator_id]);
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <h2 style={{ fontSize: "24px", marginBottom: "8px" }}>
        {t("open_position_text")}
      </h2>
      <Spacing lg="30" md="30" />
      <div
        className={`cs-table_responsive ${loading ? "cs-loading_wrap" : ""}`}
        style={{
          overflow: "auto",
          marginTop: "-1px",
        }}
      >
        {loading && (
          <>
            <Spacing lg="50" md="50" />
            <LoadingSpinner />
          </>
        )}
        {openPosition.length > 0 ? (
          <table className="cs-table cs-type1 cs-medium">
            <thead>
              <tr>
                <th className="cs-stycky_table_head">Date</th>
                <th className="cs-stycky_table_head">Ticker</th>
                <th className="cs-stycky_table_head">Cost</th>
                <th className="cs-stycky_table_head">Stop Loss (Optional)</th>
                <th className="cs-stycky_table_head">Current Return / Loss</th>
                <th className="cs-stycky_table_head">Position Size %</th>
                <th className="cs-stycky_table_head">Action</th>
              </tr>
            </thead>
            <tbody>
              {openPosition.map((item, index) => (
                <tr key={index}>
                  <td>{moment(item.date).format("D MMM Y")}</td>
                  <td className="cs-primary_color cs-break-all">
                    {item.ticker}
                  </td>
                  <td className="cs-primary_color cs-medium">$ {item.cost}</td>
                  <td className="cs-danger_text cs-medium">
                    $ {item.stop_loss}
                  </td>
                  <td style={{ color: "#858585" }}>{item.current_return} %</td>
                  <td className="cs-primary_color cs-medium">
                    {item.position_size}% positioned
                  </td>
                  <td>
                    <DotOption
                      url={`/dashboard/${item.userId}/open-position/${item.id}`}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <table className="cs-table cs-type1 cs-medium cs-lock_wrap">
            <div className="cs-lock_overlay">
              <svg
                width={30}
                height={45}
                viewBox="0 0 30 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.375 18.3019V9.375C24.375 4.19813 20.1788 0 15 0C9.81938 0 5.625 4.19813 5.625 9.375V18.3019C2.19937 21.0506 0 25.2656 0 30C0 38.2838 6.71625 45 15 45C23.2838 45 30 38.2838 30 30C30 25.2656 27.8006 21.0506 24.375 18.3019ZM9.375 9.375C9.375 6.27375 11.8987 3.75 15 3.75C18.1013 3.75 20.625 6.27375 20.625 9.375V16.1006C18.8869 15.3956 16.9913 15 15 15C13.0087 15 11.1131 15.3956 9.375 16.1006V9.375ZM15 41.25C8.79562 41.25 3.75 36.2044 3.75 30C3.75 23.7956 8.79562 18.75 15 18.75C21.2044 18.75 26.25 23.7956 26.25 30C26.25 36.2044 21.2044 41.25 15 41.25ZM18.75 30C18.75 32.07 17.07 33.75 15 33.75C12.93 33.75 11.25 32.07 11.25 30C11.25 27.93 12.93 26.25 15 26.25C17.07 26.25 18.75 27.93 18.75 30Z"
                  fill="white"
                />
              </svg>
              <h3>Subscribe to unlock post</h3>
              <a
                className="cs-lock_btn"
                onClick={() => {
                  setModal(true);
                  getTiers();
                }}
              >
                Subscribe now
              </a>
            </div>
            <thead>
              <tr>
                <th className="cs-stycky_table_head">Date</th>
                <th className="cs-stycky_table_head">Ticker</th>
                <th className="cs-stycky_table_head">Cost</th>
                <th className="cs-stycky_table_head">Stop Loss (Optional)</th>
                <th className="cs-stycky_table_head">Current Return / Loss</th>
                <th className="cs-stycky_table_head">Position Size %</th>
                <th className="cs-stycky_table_head">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>26 Jan 2023 </td>
                <td className="cs-primary_color cs-break-all">Test Ticker</td>
                <td className="cs-primary_color cs-medium">$ 45</td>
                <td className="cs-danger_text cs-medium">$ 100</td>
                <td style={{ color: "#858585" }}>3 %</td>
                <td className="cs-primary_color cs-medium">50% postioned</td>
                <td></td>
              </tr>
              <tr>
                <td>26 Jan 2023 </td>
                <td className="cs-primary_color cs-break-all">Test Ticker</td>
                <td className="cs-primary_color cs-medium">$ 45</td>
                <td className="cs-danger_text cs-medium">$ 100</td>
                <td style={{ color: "#858585" }}>3 %</td>
                <td className="cs-primary_color cs-medium">50% postioned</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      <Spacing lg="50" md="30" />
      <OpenPositionGraph openPosition={openPosition} />
      <Spacing lg="60" md="60" />
      {modal && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div className="cs-modal_box cs-style2">
                <div className="cs-modal_box_in">
                  <button
                    className="cs-close_btn"
                    onClick={() => setModal(false)}
                  >
                    <Icon icon="ri:close-circle-fill" />
                  </button>
                  <Card3 creator={creator} count={false} />
                  <Spacing lg="80" md="60" />
                  <MembershipTire
                    tiers={tiers}
                    loading={tierLoading}
                    title={t("select_membership_tier")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
