import {
  getUserUrl,
  logoutUrl,
  resentOtpUrl,
  switchRoleUrl,
  updateUserInfoUrl,
} from "../../../const";
import axios from "../../../lib/axios";

// get user info
export const getUserInfo = async () => {
  const res = await axios.get(getUserUrl);
  if (res.data.success === 0) {
    localStorage.removeItem("token");
  }
  return res;
};

// update user info
export const updateUserInfo = async (data) => {
  delete data.name_show;
  return await axios.put(updateUserInfoUrl, data);
};

// logout user
export const logoutUser = async (data) => {
  return await axios.post(logoutUrl, data);
};

// resent otp
export const resentOtp = async (email) => {
  return await axios.post(resentOtpUrl, { params: { email } });
};

// switch role
export const switchRole = async () => {
  return await axios.get(switchRoleUrl);
};
