import {
  cancelSubscribtionUrl,
  createCardUrl,
  getCardUrl,
  makeCardDefaultUrl,
  singleSubscribtionUrl,
  subscribedTierUrl,
} from "../../const";
import axios from "../../lib/axios";

// get all subscribed tier
export const subscribedTiersInfo = async () => {
  return await axios.get(subscribedTierUrl);
};
// get single subscribtion
export const getSingleSubscribtion = async (post_id) => {
  return await axios.get(singleSubscribtionUrl, { params: { post_id } });
};

// cancel subscribtion
export const handleCancelSubscribtion = async (post_id) => {
  return await axios.get(cancelSubscribtionUrl, { params: { post_id } });
};

// get card
export const getCards = async () => {
  return await axios.get(getCardUrl);
};

// create card
export const createCard = async (data) => {
  return await axios.post(createCardUrl, data);
};

// make card default
export const makeCardDefault = async (cardId) => {
  return await axios.get(makeCardDefaultUrl, {
    params: {
      card_id: cardId,
    },
  });
};
