import React, { useState } from 'react';
import { useEffect } from 'react';
import {
  Link,
  useOutletContext,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { creatorNewsletter } from '../../../api/services/creator/newsletter';
import LoadingSpinner from '../../../components/Common/LoadingSpinner';
import DotOption from '../../../components/DotOption';
import Spacing from '../../../components/Spacing';
import { tagStringToArray } from '../../../utils/tagStringToArray';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import PaginateButton from '../../../components/Common/PaginateButton';
import { defaultHasMoreCount } from '../../../config/const/hasMore';
import EmptyData from '../../../components/Common/EmptyData';
import { textLimit } from '../../../utils/TextLimit';
import { removeHtml } from '../../../utils/removeHtml';
import { useTranslation } from 'react-i18next';

export default function Newsletter() {
  const { t } = useTranslation();
  const params = useParams();
  let [searchParams] = useSearchParams();
  const [creator] = useOutletContext();
  const [newsletters, setNewsletters] = useState([]);
  const [newsletterCount, setNewsletterCount] = useState(0);
  const [newsletterLoading, setNewsletterLoading] = useState(false);
  const [newsletterHasMore, setNewsletterHasMore] = useState(true);
  const [newsletterPage, setNewsletterPage] = useState(2);
  const [newsletterPaginateLoading, setNewsletterPaginateLoading] =
    useState(false);

  const [pinnedNewsletters, setPinnedNewsletters] = useState([]);
  const [pinnedLoading, setPinnedLoading] = useState(false);
  const [pinnedHasMore, setPinnedHasMore] = useState(true);
  const [pinnedPage, setPinnedPage] = useState(2);
  const [pinnedPaginateLoading, setPinnedPaginateLoading] = useState(false);
  const [pinnedCount, setPinnedCount] = useState(0);

  const selectedTag = searchParams.get('tag') ?? '';
  const searchQuery = searchParams.get('search') ?? '';

  // get all creator newsletters
  const newsletter = async () => {
    setNewsletterLoading(true);
    const res = await creatorNewsletter(
      params.creator_id,
      1,
      5,
      searchQuery,
      selectedTag,
    );
    setNewsletterLoading(false);
    setNewsletterCount(res.data.count);
    setNewsletters(tagStringToArray(res.data.data));
  };

  // paginate newsletter
  const newsletterPaginate = async () => {
    setNewsletterPaginateLoading(true);
    const res = await creatorNewsletter(
      params.creator_id,
      newsletterPage,
      5,
      searchQuery,
      selectedTag,
    );
    setNewsletterPaginateLoading(false);
    setNewsletters([...newsletters, ...tagStringToArray(res.data.data)]);
    setNewsletterPage(newsletterPage + 1);
    if (!res.data.data.length) {
      setNewsletterHasMore(false);
    }
  };

  // get all pinned newsletter
  const pinnedNewslatter = async () => {
    setPinnedLoading(true);
    const res = await creatorNewsletter(
      params.creator_id,
      1,
      5,
      searchQuery,
      selectedTag,
      1,
    );
    setPinnedLoading(false);
    setPinnedNewsletters(tagStringToArray(res.data.data));
    setPinnedCount(res.data.count);
  };

  // pinned newsletter paginate
  const pinnedPaginate = async () => {
    setPinnedPaginateLoading(true);
    const res = await creatorNewsletter(
      params.creator_id,
      pinnedPage,
      5,
      searchQuery,
      selectedTag,
      1,
    );
    setPinnedPaginateLoading(false);
    setPinnedNewsletters([
      ...pinnedNewsletters,
      ...tagStringToArray(res.data.data),
    ]);
    setPinnedPage(pinnedPage + 1);
    if (!res.data.data.length) {
      setPinnedHasMore(false);
    }
  };

  useEffect(() => {
    newsletter();
    pinnedNewslatter();
  }, [searchParams]);
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <div className="cs-layout_1">
        <div className="cs-layout_left">
          {selectedTag == '' ? (
            <>
              <h2 style={{ fontSize: '24px', marginBottom: '8px' }}>
                {creator.name_show}
                {t('newsletter_title')}
              </h2>
              <p className="cs-m0 cs-light" style={{ color: '#8E8EA3' }}>
                {t('newsletter_subtitle')}
              </p>
            </>
          ) : (
            <>
              <h2 style={{ fontSize: '24px', marginBottom: '8px' }}>
                {t('hashtag_text')}
              </h2>
              <p className="cs-m0 cs-light" style={{ color: '#8E8EA3' }}>
                {t('searching_text')} “{selectedTag}”
              </p>
            </>
          )}
          <Spacing lg="30" md="30" />
          <div
            className={`cs-card_8_wrap ${
              newsletterLoading ? 'cs-loading_wrap' : ''
            }`}
          >
            {newsletterLoading && (
              <>
                <Spacing lg="50" md="50" />
                <LoadingSpinner />
              </>
            )}
            {newsletters.map(item => (
              <div className="cs-card cs-style4" key={uuidv4()}>
                <div className="cs-card_meta">
                  <img src={item.creator_image} alt="User" />
                  <div style={{ color: '#353535' }}>
                    <p
                      className="cs-m0"
                      style={{
                        textTransform: 'uppercase',
                        letterSpacing: '0.05em',
                      }}
                    >
                      {item.name_show}
                    </p>
                    <p className="cs-m0">
                      {moment(item.created_at).format('LT')} ;{' '}
                      {moment(item.created_at).format('DD-MMM-Y')}
                    </p>
                  </div>
                  <DotOption
                    url={`/dashboard/${item.userId}/newsletter/${item.id}`}
                  />
                </div>
                <div className="cs-card_info">
                  <div className="cs-card_thumb">
                    {item.image && <img src={item.image} alt="" />}
                  </div>
                  <h3>{item.title}</h3>
                  <p className="cs-break_all">
                    {textLimit(removeHtml(item.detail), 435)}
                  </p>
                  <Link
                    to={`/dashboard/${item.userId}/newsletter/view/${item.id}`}
                    className="cs-coninue_reading"
                  >
                    {t('continue_reading_text')}
                  </Link>
                  <div className="cs-tags">
                    {item.tag.map(tag => (
                      <Link
                        to={`/dashboard/${creator.id}/newsletter?tag=${tag}`}
                        className={`cs-tag ${
                          selectedTag === tag ? 'active' : ''
                        }`}
                        key={uuidv4()}
                      >
                        {tag}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            ))}
            {!newsletters.length && !newsletterLoading ? <EmptyData /> : null}
          </div>
          <Spacing lg="20" md="20" />
          {newsletterCount > defaultHasMoreCount && (
            <PaginateButton
              loading={newsletterPaginateLoading}
              hasMore={newsletterHasMore}
              onClick={newsletterPaginate}
            />
          )}
        </div>
        <div className="cs-layout_right">
          <div className="cs-section_heading_4">
            <h2 className="cs-section_title">{t('pinned_by_creator_text')}</h2>
            <div className="cs-section_heading_right">
              {/* <button className="cs-icon_btn">
                <svg
                  width={9}
                  height={13}
                  viewBox="0 0 9 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.121661 12.7023L4.50004 9.08404L8.87842 12.7023V1.33066C8.87842 0.759493 8.38859 0.296875 7.78382 0.296875H1.21626C0.611492 0.296875 0.121661 0.759493 0.121661 1.33066V12.7023Z"
                    fill="currentColor"
                  />
                </svg>
              </button> */}
            </div>
          </div>
          <Spacing lg="30" md="30" />
          <div
            className={`cs-card_8_wrap ${
              pinnedLoading ? 'cs-loading_wrap' : ''
            }`}
          >
            {pinnedLoading && (
              <>
                <Spacing lg="50" md="50" />
                <LoadingSpinner />
              </>
            )}
            {pinnedNewsletters.map(item => (
              <div className="cs-card cs-style4 cs-type1" key={uuidv4()}>
                <div className="cs-card_thumb">
                  {item.image && <img src={item.image} alt="" />}
                </div>
                <div className="cs-card_info">
                  <div className="cs-csra_info">
                    <div className="cs-csra_date">
                      <span>{moment(item.created_at).format('LT')}</span>
                      <span>{moment(item.created_at).format('DD-MMM-Y')}</span>
                    </div>
                    <DotOption
                      url={`/dashboard/${item.userId}/newsletter/${item.id}`}
                    />
                  </div>
                  <h3>{item.title}</h3>
                  <p className="cs-break_all">
                    {item.short_desc.substring(0, 100)}
                  </p>
                  <Link
                    to={`/dashboard/${item.userId}/newsletter/view/${item.id}`}
                    className="cs-coninue_reading"
                  >
                    {t('continue_reading_text')}
                  </Link>
                  <div className="cs-tags">
                    {item.tag.map(tag => (
                      <Link
                        to={`/dashboard/${creator.id}/newsletter?tag=${tag}`}
                        className={`cs-tag ${
                          selectedTag === tag ? 'active' : ''
                        }`}
                        key={uuidv4()}
                      >
                        {tag}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            ))}
            {!pinnedNewsletters.length && !pinnedLoading ? <EmptyData /> : null}
          </div>
          <Spacing lg="20" md="20" />
          {pinnedCount > defaultHasMoreCount && (
            <PaginateButton
              loading={pinnedPaginateLoading}
              hasMore={pinnedHasMore}
              onClick={pinnedPaginate}
            />
          )}
        </div>
      </div>
      <Spacing lg="50" md="50" />
    </div>
  );
}
