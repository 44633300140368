const baseURL = "https://api.gorudo.io/api/v1/";

// auth url
export const loginUrl = baseURL + "user/login";
export const logoutUrl = baseURL + "user/logout";
export const signUpUrl = baseURL + "user/registration";
export const resentOtpUrl = baseURL + "user/resend_otp";
export const checkUserNameUrl = baseURL + "user/check_username";
export const getUserUrl = baseURL + "user/get_user_info";
export const getCountryListUrl = baseURL + "user/get_country";
export const updateUserInfoUrl = baseURL + "user/update_user_info";
export const userVerifyUrl = baseURL + "user/user_varify";
export const forgotPassReqUrl = baseURL + "user/forget_password_send_email";
export const switchRoleUrl = baseURL + "user/role_switch";
export const resetPasswordUrl = baseURL + "user/forget_password_update";

// settings url
export const changePasswordUrl = baseURL + "user/update_user_pass";
export const emailNotificationSettingsUrl =
  baseURL + "member/settings/email_settings";
export const pushNotificationUrl = baseURL + "member/settings/push_settings";

// billing url
export const allTransactionUrl = baseURL + "member/settings/all_transaction";
export const upcomingTransactionUrl =
  baseURL + "member/settings/upcoming_transaction";
export const canceledTransactionUrl =
  baseURL + "member/settings/calceled_transaction";
export const subscriptionCheckUrl =
  baseURL + "member/settings/subscription_check";

export const checkoutCheckUrl = baseURL + "payment/checkout_check";

// subscribe tier url
export const subscribedTierUrl = baseURL + "member/settings/subscribed_tiers";
export const singleSubscribtionUrl =
  baseURL + "member/settings/subscribed_tiers_single";
export const cancelSubscribtionUrl =
  baseURL + "member/settings/cancel_subscription";
export const getCardUrl = baseURL + "member/settings/cards_pm";
export const createCardUrl = baseURL + "member/settings/cards_pm";
export const makeCardDefaultUrl = baseURL + "member/settings/cards_default_pm";

// feed url
export const feedBoardcastUrl = baseURL + "member/dashboard/latest_broadcast";
export const feedTradeDiaryUrl =
  baseURL + "member/dashboard/latest_trade_diary";

// creator url
export const crteatorPublicViewUrl =
  baseURL + "member/creator/creator_public_detail";
export const creatorPublicTierUrl =
  baseURL + "member/creator/tiers_public_detail";
export const paymentUrl = baseURL + "payment/checkout";
export const resubscribePaymentUrl = baseURL + "payment/resubscribe_checkout";
export const paymentSuccessVerifyUrl = baseURL + "payment/success_page";
export const paymentCancelVerifyUrl = baseURL + "payment/cancel_page";
export const creatorDetailsUrl = baseURL + "member/creator/creator_detail";
export const creatorBroadcastUrl = baseURL + "member/broadcast/broadcast";
export const creatorBroadcastTierUrl = baseURL + "settings/broadcast_tier";
export const creatorTradeDiaryUrl =
  baseURL + "member/trade_diary/search_trade_diary";
export const creatorTradeDiaryTierUrl = baseURL + "settings/tradedairy_tier";
export const creatorWatchlistUrl = baseURL + "member/watchlist/all_watchlist";
export const creatorNewslettersUrl =
  baseURL + "member/newsletter/search_newsletter";

export const creatorWebinarUrl = baseURL + "member/webinar/search_webinar";
export const creatorResourceUrl = baseURL + "member/resources/search_resources";

export const subscribedCreatorUrl =
  baseURL + "member/creator/my_recent_subscribe_search";
export const unsubscribedCreatorUrl =
  baseURL + "member/creator/my_recent_unsubscribe_search";
export const bookmarkCreatorUrl = baseURL + "member/creator/bookmark";
export const deleteBookmarkUrl = baseURL + "member/creator/bookmark";
export const searchCreatorUrl = baseURL + "member/creator/search_new_creator";
export const creatorOpenPositionUrl =
  baseURL + "member/openposition/open_postion";
export const multipleOpenPositionUrl =
  baseURL + "member/openposition/open_postion_multiple";

// public view api
export const publicviewCreatorNewsletterUrl =
  baseURL + "member/newsletter/newsletter_public";
export const newsletterTierUrl = baseURL + "settings/newsletter_tier";

// notification api
export const allNotificationUrl = baseURL + "notification/notification_list";
export const unreadNotificationUrl =
  baseURL + "notification/notification_list_unread";
export const notificationSeenUpdateUrl =
  baseURL + "notification/notification_update_seen";
export const notificationDeleteUrl =
  baseURL + "notification/notification_update_remove";
