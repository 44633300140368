import { useEffect } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getBookmarkCreator } from "../../../api/services/myCreator";
import Card from "../../../components/Card";
import EmptyData from "../../../components/Common/EmptyData";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import PaginateButton from "../../../components/Common/PaginateButton";
import Spacing from "../../../components/Spacing";
import { defaultHasMoreCount } from "../../../config/const/hasMore";

export default function Bookmark({ count }) {
  let [searchParams] = useSearchParams();
  const [creator, setCretor] = useState([]);
  const [creatorCount, setCreatorCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [paginateLoading, setPaginateLoading] = useState(false);
  const [page, setPage] = useState(2);

  const searchQuery = searchParams.get("search") ?? "";

  // Flag variable to track cancellation
  let isCancelled = false;

  // AbortController for cancelling fetch requests
  const abortController = new AbortController();
  const { signal } = abortController;

  // get creator
  const bookmarkCreator = async () => {
    setLoading(true);
    try {
      const res = await getBookmarkCreator(1, 5, searchQuery, { signal });
      if (!isCancelled) {
        setLoading(false);
        setCretor(res.data.data);
        setCreatorCount(res.data.count);
        count(res.data.count);
      }
    } catch (error) {
      if (!isCancelled) {
        // Handle the error
      }
    }
  };

  // paginate
  const paginate = async () => {
    setPaginateLoading(true);
    try {
      const res = await getBookmarkCreator(page, 5, searchQuery, { signal });
      if (!isCancelled) {
        setPaginateLoading(false);
        setCretor([...creator, ...res.data.data]);
        setPage(page + 1);
        if (!res.data.data.length) {
          setHasMore(false);
        }
      }
    } catch (error) {
      if (!isCancelled) {
        // Handle the error
      }
    }
  };

  useEffect(() => {
    bookmarkCreator();

    // Cleanup function to cancel ongoing processes
    return () => {
      isCancelled = true; // Set the flag to true
      abortController.abort(); // Cancel the fetch request
    };
  }, [searchParams]);
  return (
    <>
      <div className={`${loading ? "cs-loading_wrap" : ""}`}>
        {loading && (
          <>
            <Spacing lg="50" md="50" />
            <LoadingSpinner />
          </>
        )}
        {creator.map((item) => (
          <Card
            cardHref={`/creator-public-view/${item.id}`}
            src={item.image}
            title={item.name_show}
            subtitle={item.about_yourself}
            memberNumber={item.members}
            broadcastNumber={item.broadcasts}
            tradeDairyNumber={item.trade_dairy}
            deleteBtn={item.id}
            setLoading={setLoading}
            refresh={bookmarkCreator}
          />
        ))}
        {!creator.length && !loading ? <EmptyData /> : null}
      </div>
      {creatorCount > defaultHasMoreCount && (
        <>
          <Spacing lg="20" md="20" />
          <PaginateButton
            hasMore={hasMore}
            loading={paginateLoading}
            onClick={paginate}
          />
        </>
      )}
    </>
  );
}
