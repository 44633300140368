import moment from "moment";
import { useContext, useEffect, useState } from "react";
import {
  allNotification,
  notificationSeenUpdate,
} from "../../api/services/notification";
import NotificationContext from "../../context/notification/notification-context";
import { notificationDetailsStringToArray } from "../../utils/notificationDetailsStringToArray";
import LoadingSpinner from "../Common/LoadingSpinner";
import Spacing from "../Spacing";
import Notification from "./index";

export function AllNotification({ close }) {
  const [notification, setNotification] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getUnreadCount } = useContext(NotificationContext);

  const getAllNotification = async () => {
    setLoading(true);
    const res = await allNotification();
    setNotification(notificationDetailsStringToArray(res.data.data));
    setLoading(false);
  };
  // make notification seen
  const makeNotificationSeen = async (id) => {
    close();
    await notificationSeenUpdate(id);
    getUnreadCount();
  };

  // update seen
  const updateSeen = async (id) => {
    let notificationCopy = [...notification];
    let notificationIndex = notificationCopy.findIndex(
      (item) => item.id === id
    );
    notificationCopy[notificationIndex].seen = 1;
    setNotification(notificationCopy);
  };

  // delete notification
  const deleteNotification = async (id) => {
    let notificationCopy = [...notification];
    let notificationIndex = notificationCopy.findIndex(
      (item) => item.id === id
    );
    notificationCopy.splice(notificationIndex, 1);
    setNotification(notificationCopy);
  };

  useEffect(() => {
    getAllNotification();
  }, []);
  return (
    <ul
      className={`cs-notification_list cs-mp0 ${
        loading ? "cs-loading_wrap" : ""
      }`}
    >
      {loading && (
        <>
          <Spacing lg="50" md="50" />
          <LoadingSpinner />
        </>
      )}
      {notification.map((item) => (
        <li>
          <Notification
            makeNotificationSeen={makeNotificationSeen}
            thumb={item.userImage}
            title={item.title}
            id={item.id}
            note={item.note}
            seen={item.seen}
            postId={item.postId}
            type={item.type}
            userId={item.userId}
            detail={item.detail}
            date={`${moment(item.created_at).format("LT")} ; ${moment(
              item.created_at
            ).format("D MMM Y")}`}
            updateSeen={updateSeen}
            deleteNotification={deleteNotification}
          />
        </li>
      ))}
    </ul>
  );
}
