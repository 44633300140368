import { creatorWatchlistUrl } from "../../const";
import axios from "../../lib/axios";

// get creator watchlist
export const getCreatorWatchList = async (
  creator_id,
  page = 1,
  limit = 5,
  search = "",
  filter = "",
  from = "",
  to = "",
  post_id = ""
) => {
  return await axios.get(creatorWatchlistUrl, {
    params: {
      creator_id,
      page,
      limit,
      search,
      filter,
      from,
      to,
      post_id,
    },
  });
};
