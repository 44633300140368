import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  checkoutCheck,
  processToStripePayment,
} from "../../api/services/public/creator";
import Spacing from "../Spacing";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import LoadingSpinner from "../Common/LoadingSpinner";
import EmptyData from "../Common/EmptyData";
import formatPrice from "../../utils/formatPrice";
import { useTranslation } from "react-i18next";

export default function MembershipTire({
  title,
  tiers,
  loading,
  tierErrorMessage = "",
}) {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [loadingId, setLoadingId] = useState(false);
  const [invitationCode, setInvitationCode] = useState([]);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modal, setModal] = useState(false);
  const [subscribeTierId, setSubscribeTierId] = useState("");
  const [checkoutWarning, setCheckoutWarning] = useState("");
  // if featires
  const isFeatures = (array, item) => {
    const result = array?.find(({ featuresName }) => featuresName === item);
    return !result ? "cs-disabled" : "";
  };

  // checkout check
  const handleCheckoutCheck = async (id) => {
    setPaymentLoading(true);
    setSubscribeTierId(id);
    setErrorMessage("");
    setLoadingId(id);
    const res = await checkoutCheck({ membership_tire_id: id });
    setCheckoutWarning(res.data.message);
    if (res.data.data === 0) {
      await processToPayment(id);
    } else {
      setModal(true);
    }
  };

  // process to pay
  const processToPayment = async (id) => {
    const findIndex = invitationCode.findIndex((code) => code.tierId === id);
    const baseUrl = window.location.origin;
    const data = {
      membership_tire_id: id,
      invitation_code: invitationCode[findIndex].invitation_code,
      success_url: `${baseUrl}/payment/success?creator_id=${params.id}&`,
      cancel_url: `${baseUrl}/payment/cancel?creator_id=${params.id}&`,
    };

    const res = await processToStripePayment(data);
    if (res.data.success === 1) {
      if (res.data.data.url) {
        window.location.href = res.data.data.url;
      } else {
        navigate("/my-creators");
      }
    } else {
      setErrorMessage(res.data.message);
      setPaymentLoading(false);
    }
  };

  // genearte invitation code array
  useEffect(() => {
    setInvitationCode(
      tiers.map((item) => {
        return {
          tierId: item.id,
          invitation_code: "",
        };
      })
    );
  }, [tiers]);
  return (
    <>
      <h2 style={{ fontSize: "30px", textAlign: "center", marginBottom: "0" }}>
        {title}
      </h2>
      <Spacing lg="40" md="40" />
      <div className={`cs-tires ${loading ? "cs-loading_wrap" : ""}`}>
        {loading && (
          <>
            <Spacing lg="50" md="50" />
            <LoadingSpinner />
          </>
        )}
        {tiers.map((item, index) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleCheckoutCheck(item.id);
            }}
            key={index}
          >
            <div className={`cs-tire ${item.is_popular === "1" && "active"}`}>
              <h3 className="cs-tire_title">{item.title}</h3>
              <ul className="cs-tire_list">
                <li className={isFeatures(item.features, "Broadcast")}>
                  <Icon icon="charm:tick" />
                  {t("broadcast_text")}
                </li>
                <li className={isFeatures(item.features, "Trade Diary")}>
                  <Icon icon="charm:tick" />
                  {t("trade_dairy_text")}
                </li>
                <li className={isFeatures(item.features, "Open Position")}>
                  <Icon icon="charm:tick" />
                  {t("open_position_text")}
                </li>
                <li className={isFeatures(item.features, "Resources + FAQ")}>
                  <Icon icon="charm:tick" />
                  {t("resources_faq_text")}
                </li>
                <li className={isFeatures(item.features, "Newsletter")}>
                  <Icon icon="charm:tick" />
                  {t("newsletter_text")}
                </li>
                <li className={isFeatures(item.features, "Watchlist")}>
                  <Icon icon="charm:tick" />
                  {t("watchlist_text")}
                </li>
                <li className={isFeatures(item.features, "Webinar / Video")}>
                  <Icon icon="charm:tick" />
                  {t("webinar_video_text")}
                </li>
              </ul>
              {item.price !== "0" && (
                <>
                  <Spacing lg="15" md="15" />
                  <div className="cs-promo_code">
                    <div>{t("invitation_code")}</div>
                    <input
                      type="text"
                      placeholder={t("enter_code")}
                      onChange={(e) => {
                        const findIndex = invitationCode.findIndex(
                          (code) => code.tierId === item.id
                        );
                        let updatedCodes = [...invitationCode];
                        updatedCodes[findIndex].invitation_code =
                          e.target.value;
                        setInvitationCode(updatedCodes);
                      }}
                    />
                  </div>
                  {errorMessage && loadingId === item.id && (
                    <span className="cs-danger">{errorMessage}</span>
                  )}
                  <Spacing lg="20" md="20" />
                  <div className="cs-tire_info">
                    {item.member_qouta} {t("seat_left")}
                  </div>
                </>
              )}
              <Spacing lg="10" md="10" />
              <div className="cs-tire_price">
                <span style={{ fontWeight: "400", fontSize: "17px" }}>$</span>
                <span style={{ fontWeight: "700", fontSize: "25px" }}>
                  {item.price}
                </span>
                <span style={{ opacity: "0.68" }}>
                  /{t("my_subscription_text_5")}*
                </span>
              </div>
              <div>
                <span>
                  {formatPrice(
                    item.price_convert ?? "0",
                    item.price_convert_code ?? "0"
                  )}
                </span>
              </div>
              <Spacing lg="20" md="20" />
              <button
                className={`cs-tire_btn ${
                  paymentLoading && loadingId === item.id
                    ? "cs-loading_wrap"
                    : ""
                }`}
              >
                {paymentLoading && loadingId === item.id && (
                  <span className="cs-btn_loading">
                    <Icon icon="la:spinner" />
                    {t("loading_text")}
                  </span>
                )}
                {t("choose")}
              </button>
            </div>
          </form>
        ))}
      </div>
      {!tiers.length && !loading ? (
        <EmptyData message={tierErrorMessage} />
      ) : null}
      {modal && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div className="cs-modal_box cs-style1">
                <Spacing lg="45" md="45" />
                <div className="cs-modal_box_in">
                  <div className="cs-text_center">
                    <div style={{ fontSize: "14px", lineHeight: "1.6em" }}>
                      {checkoutWarning}
                    </div>
                  </div>
                  <Spacing lg="30" md="30" />
                  <div className="cs-modal_btns">
                    <button
                      className="cs-modal_btn"
                      onClick={() => {
                        setModal(false);
                        setPaymentLoading(false);
                      }}
                    >
                      {t("cancel_text")}
                    </button>
                    <button
                      className={`cs-modal_btn cs-color1 ${
                        checkoutWarning ===
                        "You have already subscribed to the same tier."
                          ? "disabled-button"
                          : ""
                      }`}
                      onClick={() => {
                        processToPayment(subscribeTierId);
                        setModal(false);
                      }}
                    >
                      {t("confirm_text")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
