import { creatorResourceUrl } from "../../const";
import axios from "../../lib/axios";

// creator resource
export const creatorResource = async (
  creator_id,
  page = 1,
  limit = 5,
  search = "",
  tag = "",
  pin = "",
  post_id = "",
  draft = ""
) => {
  return await axios.get(creatorResourceUrl, {
    params: { creator_id, page, limit, search, tag, pin, post_id, draft },
  });
};
