import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import Header from "../Header";
import DashboardSideNav from "../MainSideNav/DashboardSideNav";
import useAuth from "../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { getCreatorDetails } from "../../api/services/creator/details";
import LoadingSpinner from "../Common/LoadingSpinner";
import Spacing from "../Spacing";
import UserContext from "../../context/user/user-context";

export default function DashboardLayout({ showSearch }) {
  const params = useParams();
  const Auth = useAuth();
  const { user, isLoading } = useContext(UserContext);
  const [creator, setCreator] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const creator = async () => {
      setLoading(true);
      const res = await getCreatorDetails(params.creator_id);
      setLoading(false);
      setCreator(res.data.data);
    };
    creator();
  }, []);

  // deside what to render
  let content = "";
  if (isLoading)
    content = (
      <div
        className="cs-loading_wrap"
        style={{ width: "100%", height: "100vh" }}
      >
        <LoadingSpinner />
      </div>
    );
  if (!isLoading && user.varified === 0) {
    content = <Navigate to="/auth/email-verification?resentCode=true" />;
  }
  if (!isLoading && user.varified && user.dob === null) {
    content = <Navigate to="/auth/personal-info" />;
  }
  if (!isLoading && user.varified && user.dob) {
    content = (
      <>
        <Header showSearch={showSearch} />
        <div className={`${loading ? "cs-loading_wrap" : ""}`}>
          {loading && (
            <>
              <Spacing lg="50" md="50" />
              <LoadingSpinner />
            </>
          )}
          <DashboardSideNav creator={creator} />
          <div className="cs-with_sidebar_main">
            <Outlet context={[creator]} />
          </div>
        </div>
      </>
    );
  }
  return Auth ? <>{content}</> : <Navigate to="/auth/login" />;
}
