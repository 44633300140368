import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getCreatorWatchList } from '../../../api/services/creator/watchlist';
import LoadingSpinner from '../../../components/Common/LoadingSpinner';
import DotOption from '../../../components/DotOption';
import Spacing from '../../../components/Spacing';
import { v4 as uuidv4 } from 'uuid';
import MembershipTire from '../../../components/MembershipTire';
import {
  getPublicCreatorDetails,
  getPublicCreatorTier,
} from '../../../api/services/public/creator';
import { Icon } from '@iconify/react';
import Card3 from '../../../components/Card/Card3';
import { useTranslation } from 'react-i18next';

export default function WatchlistsDetails() {
  const { t } = useTranslation();
  const params = useParams();
  const [creator, setCreator] = useState({});
  const [searchParams] = useSearchParams();
  const [watchlists, setWatchlists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [watchlistCount, setWatchlistCount] = useState(0);
  const [tierLoading, setTierLoading] = useState(false);
  const [tiers, setTiers] = useState([]);
  const [modal, setModal] = useState(false);

  const searchQuery = searchParams.get('search') ?? '';
  const watchlist = async () => {
    setLoading(true);
    const res = await getCreatorWatchList(
      params.creator_id,
      1,
      5,
      searchQuery,
      '',
      '',
      '',
      params.id,
    );
    setWatchlists(res.data.data);
    setWatchlistCount(res.data.count);
    setLoading(false);
  };

  // get tiers
  const getTiers = async () => {
    setTierLoading(true);
    const res = await getPublicCreatorTier(params.creator_id);
    setTiers(res.data.data);
    setTierLoading(false);
  };

  // get creator
  const getCreator = async () => {
    const res = await getPublicCreatorDetails(params.creator_id);
    setCreator(res.data.data);
  };

  useEffect(() => {
    watchlist();
    getCreator();
  }, [params]);
  return (
    <div className="cs-container_md" style={{ minHeight: '80vh' }}>
      <Spacing lg="30" md="40" />
      <div className="cs-section_heading_2">
        <div className="cs-section_left">
          <h2 className="cs-section_title">{t('watchlist_text')}</h2>
        </div>
      </div>
      <Spacing lg="30" md="30" />
      <div className={`${loading ? 'cs-loading_wrap' : ''}`}>
        {loading && (
          <>
            <Spacing lg="50" md="50" />
            <LoadingSpinner />
          </>
        )}
        {watchlistCount > 0 ? (
          <>
            {watchlists.map(item => (
              <div key={uuidv4()}>
                <div className="cs-watchlist">
                  <div className="cs-watchlist_left">
                    <div className="cs-add_date_heading">
                      <div className="cs-add_date_in">
                        <span>{moment(item.note_date).format('dddd')}</span>
                        {/* <span>22 Jan</span> */}
                        <span>{moment(item.note_date).format('DD MMM')}</span>
                      </div>
                      <DotOption
                        url={`/dashboard/${item.userId}/watchlists/${item.id}`}
                      />
                    </div>
                    {/* <Spacing lg='5' md='5'/>
              <div className="cs-add_date_text">Some of these may need a pullback or some tighte-ning, but this is our focus coming into the week. Not the RED signal - also!</div> */}
                    <Spacing lg="25" md="25" />
                    {/* <button className="cs-add_date_btn">+ Add Date</button> */}
                  </div>
                  <div className="cs-watchlist_right">
                    <div className="cs-todo_input">
                      <label className="cs-todo_label">Creator’s NOTE</label>
                      <div
                        style={{ marginTop: '10px' }}
                        className="cs-break_all"
                      >
                        {item.note}
                      </div>
                      {/* <input type="text" className="cs-form_field" placeholder='Enter note' /> */}
                    </div>
                    <Spacing lg="25" md="25" />
                    <ul className="cs-todo_list cs-mp0">
                      {item.components.map(ticker => (
                        <li key={uuidv4()}>
                          <div className="cs-todo">
                            <div className="cs-todo_title">{ticker.title}</div>
                            <div className="cs-todo_subtitle">
                              {t('posted_in')}{' '}
                              {moment(ticker.created_at).format('LT')}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <Spacing lg="25" md="25" />
              </div>
            ))}
          </>
        ) : (
          <div className="cs-lock_wrap">
            <div className="cs-lock_overlay">
              <svg
                width={30}
                height={45}
                viewBox="0 0 30 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.375 18.3019V9.375C24.375 4.19813 20.1788 0 15 0C9.81938 0 5.625 4.19813 5.625 9.375V18.3019C2.19937 21.0506 0 25.2656 0 30C0 38.2838 6.71625 45 15 45C23.2838 45 30 38.2838 30 30C30 25.2656 27.8006 21.0506 24.375 18.3019ZM9.375 9.375C9.375 6.27375 11.8987 3.75 15 3.75C18.1013 3.75 20.625 6.27375 20.625 9.375V16.1006C18.8869 15.3956 16.9913 15 15 15C13.0087 15 11.1131 15.3956 9.375 16.1006V9.375ZM15 41.25C8.79562 41.25 3.75 36.2044 3.75 30C3.75 23.7956 8.79562 18.75 15 18.75C21.2044 18.75 26.25 23.7956 26.25 30C26.25 36.2044 21.2044 41.25 15 41.25ZM18.75 30C18.75 32.07 17.07 33.75 15 33.75C12.93 33.75 11.25 32.07 11.25 30C11.25 27.93 12.93 26.25 15 26.25C17.07 26.25 18.75 27.93 18.75 30Z"
                  fill="white"
                />
              </svg>
              <h3>Subscribe to unlock post</h3>
              <a
                className="cs-lock_btn"
                onClick={() => {
                  setModal(true);
                  getTiers();
                }}
              >
                Subscribe now
              </a>
            </div>
            <div className="cs-watchlist">
              <div className="cs-watchlist_left">
                <div className="cs-add_date_heading">
                  <div className="cs-add_date_in">
                    <span>Friday</span>
                    <span>20 Jan</span>
                  </div>
                </div>
                {/* <Spacing lg='5' md='5'/>
          <div className="cs-add_date_text">Some of these may need a pullback or some tighte-ning, but this is our focus coming into the week. Not the RED signal - also!</div> */}
                <Spacing lg="25" md="25" />
                {/* <button className="cs-add_date_btn">+ Add Date</button> */}
              </div>
              <div className="cs-watchlist_right">
                <div className="cs-todo_input">
                  <label className="cs-todo_label">Creator’s NOTE</label>
                  <div style={{ marginTop: '10px' }} className="cs-break_all">
                    Lorem Ipsum is simply dummy text of the printing
                  </div>
                  {/* <input type="text" className="cs-form_field" placeholder='Enter note' /> */}
                </div>
                <Spacing lg="25" md="25" />
                <ul className="cs-todo_list cs-mp0">
                  <li>
                    <div className="cs-todo">
                      <div className="cs-todo_title">Test Ticker 1</div>
                      <div className="cs-todo_subtitle">
                        Posted in 1 min ago
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="cs-todo">
                      <div className="cs-todo_title">Test Ticker 2</div>
                      <div className="cs-todo_subtitle">
                        Posted in 1 min ago
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="cs-todo">
                      <div className="cs-todo_title">Test Ticker 2</div>
                      <div className="cs-todo_subtitle">
                        Posted in 1 min ago
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <Spacing lg="25" md="25" />
          </div>
        )}
      </div>
      <Spacing lg="50" md="50" />
      {modal && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div className="cs-modal_box cs-style2">
                <div className="cs-modal_box_in">
                  <button
                    className="cs-close_btn"
                    onClick={() => setModal(false)}
                  >
                    <Icon icon="ri:close-circle-fill" />
                  </button>
                  <Card3 creator={creator} count={false} />
                  <Spacing lg="80" md="60" />
                  <MembershipTire
                    tiers={tiers}
                    loading={tierLoading}
                    title={t('select_membership_tier')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
