import moment from "moment/moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { creatorOpenPosition } from "../../../api/services/creator/openPosition";
import EmptyData from "../../../components/Common/EmptyData";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import DotOption from "../../../components/DotOption";
import Spacing from "../../../components/Spacing";
import OpenPositionGraph from "./components/OpenPositionGraph";
import { useTranslation } from "react-i18next";

export default function OpenPosition() {
  const { t } = useTranslation();
  const [creator] = useOutletContext();
  const [searchParams] = useSearchParams();
  const [openPosition, setOpenPosition] = useState([]);
  const [loading, setLoading] = useState(false);
  const { creator_id } = useParams();

  const searchQuery = searchParams.get("search") ?? "";

  // get open position
  const getAllOpenPostion = async () => {
    setLoading(true);
    const res = await creatorOpenPosition(creator_id, "", searchQuery);
    setOpenPosition(res.data.data);
    setLoading(false);
  };
  useEffect(() => {
    getAllOpenPostion();
  }, [searchParams]);
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <h2 style={{ fontSize: "24px", marginBottom: "8px" }}>
        {creator.name_show}
        {t("open_position_title")}
      </h2>
      <p className="cs-m0 cs-light" style={{ color: "#8E8EA3" }}>
        {t("open_position_subtitle")}
      </p>
      <Spacing lg="30" md="30" />
      <div
        className={`cs-table_responsive ${loading ? "cs-loading_wrap" : ""}`}
        style={{
          maxHeight: "295px",
          overflow: "auto",
          marginTop: "-1px",
        }}
      >
        {loading && (
          <>
            <Spacing lg="50" md="50" />
            <LoadingSpinner />
          </>
        )}
        <table className="cs-table cs-type1 cs-medium">
          <thead>
            <tr>
              <th className="cs-stycky_table_head">
                {t("open_position_table_heading_1")}
              </th>
              <th className="cs-stycky_table_head">
                {t("open_position_table_heading_2")}
              </th>
              <th className="cs-stycky_table_head">
                {t("open_position_table_heading_3")}
              </th>
              <th className="cs-stycky_table_head">
                {t("open_position_table_heading_4")}
              </th>
              <th className="cs-stycky_table_head">
                {t("open_position_table_heading_5")}
              </th>
              <th className="cs-stycky_table_head">
                {t("open_position_table_heading_6")}
              </th>
              <th className="cs-stycky_table_head">
                {t("open_position_table_heading_7")}
              </th>
            </tr>
          </thead>
          <tbody>
            {openPosition.map((item, index) => (
              <tr key={index}>
                <td>{moment(item.date).format("D MMM Y")}</td>
                <td className="cs-primary_color cs-break-all">{item.ticker}</td>
                <td className="cs-primary_color cs-medium">$ {item.cost}</td>
                <td className="cs-danger_text cs-medium">$ {item.stop_loss}</td>
                <td style={{ color: "#858585" }}>{item.current_return} %</td>
                <td className="cs-primary_color cs-medium">
                  {item.position_size}% positioned
                </td>
                <td>
                  <DotOption
                    url={`/dashboard/${item.userId}/open-position/${item.id}`}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {!openPosition.length && !loading ? <EmptyData /> : null}
      </div>
      <Spacing lg="50" md="30" />
      <OpenPositionGraph openPosition={openPosition} />
      <Spacing lg="60" md="60" />
    </div>
  );
}
