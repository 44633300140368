import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import Spacing from '../../components/Spacing';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { changePassword } from '../../api/services/settings/changePass';
import { checkpassword } from '../../utils/PasswordStrengthCheck';
import { useTranslation } from 'react-i18next';

export default function ChangePassword() {
  const { t } = useTranslation();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessgae] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');

  const formSchema = Yup.object().shape({
    password_old: Yup.string()
      .required('The old password field is required')
      .min(6, 'The old password min required 6 character'),
    password: Yup.string()
      .required('The password field is required')
      .min(6, 'The password min required 6 character'),
    password_c: Yup.string()
      .required('The confirm password field is required')
      .min(6, 'The confirm password min required 6 character')
      .oneOf([Yup.ref('password')], 'Password does not match'),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(formOptions);

  // handle password cgange
  const handleChange = async data => {
    setLoading(true);
    const res = await changePassword(data);
    setLoading(false);
    if (res.data.success === 0) {
      setErrorMessgae(res.data.message);
    } else if (res.data.success === 1) {
      reset();
      setPasswordStrength('');
      setErrorMessgae('');
      setSuccessMessage(res.data.message);
    }
  };
  return (
    <div className="cs-contaienr_sm">
      <Spacing lg="50" md="50" />
      <form
        onSubmit={handleSubmit(handleChange)}
        className="cs-reset_password_box"
      >
        <h2>{t('change_password_title')}</h2>
        <Spacing lg="30" md="30" />
        {errorMessage && <p className="cs-danger">{errorMessage}</p>}
        {successMessage && <p className="cs-success">{successMessage}</p>}
        <label>{t('change_password_input_label_1')}</label>
        <div className="cs-field_with_icon">
          <input
            type={showOldPassword ? 'text' : 'password'}
            placeholder={t('change_password_input_placeholder_1')}
            className="cs-form_field"
            {...register('password_old')}
          />
          <span
            className="cs-field_icon_btn"
            onClick={() => setShowOldPassword(!showOldPassword)}
          >
            {showOldPassword ? (
              <Icon icon="mdi:eye-off" />
            ) : (
              <Icon icon="ic:baseline-remove-red-eye" />
            )}
          </span>
        </div>
        <div className="invalid-feedback">{errors.password_old?.message}</div>
        <Spacing lg="25" md="25" />
        <label>{t('change_password_input_label_2')}</label>
        <div
          className="cs-field_with_icon"
          onChange={e => {
            setPasswordStrength(checkpassword(e.target.value));
          }}
        >
          <input
            type={showNewPassword ? 'text' : 'password'}
            placeholder={t('change_password_input_placeholder_2')}
            className="cs-form_field"
            {...register('password')}
          />
          <span
            className="cs-field_icon_btn"
            onClick={() => setShowNewPassword(!showNewPassword)}
          >
            {showNewPassword ? (
              <Icon icon="mdi:eye-off" />
            ) : (
              <Icon icon="ic:baseline-remove-red-eye" />
            )}
          </span>
        </div>
        {passwordStrength && <p>Password is {passwordStrength}</p>}
        <div className="invalid-feedback">{errors.password?.message}</div>
        <Spacing lg="25" md="25" />
        <label>{t('change_password_input_label_3')}</label>
        <div className="cs-field_with_icon">
          <input
            type={showConfirmPassword ? 'text' : 'password'}
            placeholder={t('change_password_input_placeholder_3')}
            className="cs-form_field"
            {...register('password_c')}
          />
          <span
            className="cs-field_icon_btn"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          ></span>
          <span
            className="cs-field_icon_btn"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          >
            {showConfirmPassword ? (
              <Icon icon="mdi:eye-off" />
            ) : (
              <Icon icon="ic:baseline-remove-red-eye" />
            )}
          </span>
        </div>
        <div className="invalid-feedback">{errors.password_c?.message}</div>
        <Spacing lg="25" md="25" />
        <button
          className={`cs-logout_btn cs-type1 ${
            loading ? 'cs-loading_wrap' : ''
          }`}
        >
          {loading && (
            <span className="cs-btn_loading">
              <Icon icon="la:spinner" />
              {t('loading_text')}
            </span>
          )}
          {t('change_password_submit')}
        </button>
      </form>
      <Spacing lg="60" md="60" />
    </div>
  );
}
