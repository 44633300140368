import { creatorWebinarUrl } from "../../const";
import axios from "../../lib/axios";
// get creator webinar
export const creatorWebinar = async (
  creator_id,
  page = 1,
  limit = 5,
  search = "",
  tag = "",
  pin = "",
  post_id = "",
  draft = ""
) => {
  return await axios.get(creatorWebinarUrl, {
    params: {
      creator_id,
      page,
      limit,
      search,
      tag,
      pin,
      post_id,
      draft,
    },
  });
};
