import axios from "../../lib/axios";
import { feedBoardcastUrl, feedTradeDiaryUrl } from "../../const";

// get latest boardCast
export const getLatestBoardcast = async (
  page = 1,
  limit = 3,
  search = "",
  tag = ""
) => {
  return await axios.get(feedBoardcastUrl, {
    params: {
      page,
      limit,
      search,
      tag,
    },
  });
};

// get latest trade diary
export const getTradeDiary = async (page = 1, limit = 3, search = "") => {
  return await axios.get(feedTradeDiaryUrl, {
    params: {
      page,
      limit,
      search,
    },
  });
};
