import { useState, useEffect } from "react";
import { getUserInfo } from "../../api/services/auth/user";
import UserContext from "./user-context";

const UserState = (props) => {
  const [isAuth, setIsAuth] = useState(false);
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getUserInformation = async () => {
    setIsLoading(true);
    const res = await getUserInfo();
    if (res.data.success === 1) {
      setUser(res.data.data);
      setIsAuth(true);
    } else {
      setIsAuth(false);
      setUser({});
      localStorage.removeItem("isAuth");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getUserInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        isAuth,
        setIsAuth,
        setUser,
        getUserInformation,
        isLoading,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
