import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Spacing from '../../components/Spacing';
import Bookmark from './components/Bookmark';
import Subscribed from './components/Subscribed';
import Unsubscribed from './components/Unsubscribed';
import { useTranslation } from 'react-i18next';

export default function MyCreator() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selected, setSelected] = useState(0);
  const [count, setCount] = useState(0);
  const handelTab = index => {
    if (selected === index) {
      // return setSelected(null)
    }
    setCount(0);
    setSelected(index);
  };
  const selectTypeName =
    selected === 0
      ? 'subscribed'
      : selected === 1
      ? 'unsubscribed'
      : 'bookmark';
  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab === 'unsubscribed') {
      setSelected(1);
    } else if (tab === 'bookmark') {
      setSelected(2);
    } else {
      setSelected(0);
    }
  }, [searchParams]);
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <h2 style={{ fontSize: '20px', marginBottom: '8px' }}>
        {t('my_creators_title')}
      </h2>
      <p className="cs-m0 cs-light" style={{ color: '#8E8EA3' }}>
        {t('my_creators_subtitle_text_1')} {selectTypeName} {count}{' '}
        {t('my_creators_subtitle_text_2')}
      </p>
      <Spacing lg="30" md="30" />
      <ul className="cs-secondary_nav">
        <li>
          <button
            className={selected === 0 ? 'active' : ''}
            onClick={() => navigate('/my-creators')}
          >
            {t('my_creators_tab_nav_1')}
          </button>
        </li>
        <li>
          <button
            className={selected === 1 ? 'active' : ''}
            onClick={() => navigate('/my-creators?tab=unsubscribed')}
          >
            {t('my_creators_tab_nav_2')}
          </button>
        </li>
        <li>
          <button
            className={selected === 2 ? 'active' : ''}
            onClick={() => navigate('/my-creators?tab=bookmark')}
          >
            {t('my_creators_tab_nav_3')}
          </button>
        </li>
      </ul>
      <Spacing lg="15" md="15" />
      {selected === 0 && <Subscribed count={setCount} />}
      {selected === 1 && <Unsubscribed count={setCount} />}
      {selected === 2 && <Bookmark count={setCount} />}
      <Spacing lg="60" md="60" />
    </div>
  );
}
