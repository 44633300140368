import { Icon } from "@iconify/react";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Link,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { getCreatorBroadcast } from "../../../api/services/creator/broadcast";
import EmptyData from "../../../components/Common/EmptyData";
import PaginateButton from "../../../components/Common/PaginateButton";
import DotOption from "../../../components/DotOption";
import Spacing from "../../../components/Spacing";
import { defaultHasMoreCount } from "../../../config/const/hasMore";
import { tagStringToArray } from "../../../utils/tagStringToArray";
import { useTranslation } from "react-i18next";

export default function Broadcasts() {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const [creator] = useOutletContext();
  const params = useParams();
  const [broadcasts, setBoardcasts] = useState([]);
  const [brodcastCount, setBroadcastCount] = useState(0);
  const [broadcastHasMore, setBroadcastHasMore] = useState(true);
  const [broadcastPaginateLoading, setBroadcastPaginateLoading] =
    useState(false);
  const [broadcastPage, setBroadcastPage] = useState(2);
  const [broadcastLoading, setBroadcastLoading] = useState(false);

  const [isDisclaimer, setIsDisclaimer] = useState(
    localStorage.getItem("closeBroadcastDisclaimer") ? false : true
  );

  const [pinnedBroadcasts, setPinnedBroadcasts] = useState([]);
  const [pinnedBroadcastCount, setPinnedBroadcastCount] = useState(0);
  const [pinnedBroadcastHasMore, setPinnedBroadcastHasMore] = useState(true);
  const [pinnedBroadcastPaginateLoading, setPinnedBroadcastPaginateLoading] =
    useState(false);
  const [pinnedBroadcastLoading, setPinnedBroadcastLoading] = useState(false);
  const [pinnedBroadcastPage, setpinnedBroadcastPage] = useState(2);

  const selectedTag = searchParams.get("tag") ?? "";
  const searchQuery = searchParams.get("search") ?? "";

  // get broadcast
  const broadcast = async () => {
    setBroadcastLoading(true);
    const res = await getCreatorBroadcast(
      params.creator_id,
      1,
      5,
      searchQuery,
      selectedTag
    );
    setBroadcastLoading(false);
    setBoardcasts(tagStringToArray(res.data.data));
    setBroadcastCount(res.data.count);
  };

  // get broadcast paginate
  const broadcastPaginate = async () => {
    setBroadcastPaginateLoading(true);
    const res = await getCreatorBroadcast(
      params.creator_id,
      broadcastPage,
      5,
      searchQuery,
      selectedTag
    );
    setBoardcasts([...broadcasts, ...tagStringToArray(res.data.data)]);
    setBroadcastPaginateLoading(false);
    setBroadcastPage(broadcastPage + 1);
    if (!res.data.data.length) {
      setBroadcastHasMore(false);
    }
  };
  // get pinned broadcast
  const pinnedBroadcast = async () => {
    setPinnedBroadcastLoading(true);
    const res = await getCreatorBroadcast(
      params.creator_id,
      1,
      5,
      searchQuery,
      selectedTag,
      1
    );
    setPinnedBroadcastLoading(false);
    setPinnedBroadcasts(tagStringToArray(res.data.data));
    setPinnedBroadcastCount(res.data.count);
  };

  // pinned broadcast paginate
  const pinnedBroadcastPaginate = async () => {
    setPinnedBroadcastPaginateLoading(true);
    const res = await getCreatorBroadcast(
      params.creator_id,
      pinnedBroadcastPage,
      5,
      searchQuery,
      selectedTag,
      1
    );
    setPinnedBroadcastPaginateLoading(false);
    setPinnedBroadcasts([
      ...pinnedBroadcasts,
      ...tagStringToArray(res.data.data),
    ]);
    setpinnedBroadcastPage(pinnedBroadcastPage + 1);
    if (!res.data.data.length) {
      setPinnedBroadcastHasMore(false);
    }
  };
  const handleBroadcastClose = () => {
    localStorage.setItem("closeBroadcastDisclaimer", true);
    setIsDisclaimer(false);
  };

  useEffect(() => {
    broadcast();
    pinnedBroadcast();
  }, [searchParams]);
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <div className="cs-layout_1">
        <div className="cs-layout_left">
          {selectedTag == "" ? (
            <>
              <h2 style={{ fontSize: "24px", marginBottom: "8px" }}>
                {creator.name_show}
                {t("broadcasts_title")}
              </h2>
              <p className="cs-m0 cs-light" style={{ color: "#8E8EA3" }}>
                {t("broadcasts_subtitle")}
              </p>
            </>
          ) : (
            <>
              <h2 style={{ fontSize: "24px", marginBottom: "8px" }}>
                {t("hashtag_text")}
              </h2>
              <p className="cs-m0 cs-light" style={{ color: "#8E8EA3" }}>
                {t("searching_text")} “{selectedTag}”
              </p>
            </>
          )}
          <Spacing lg="30" md="30" />
          <div className="cs-card_8_wrap">
            {broadcasts.map((item) => (
              <div className="cs-card cs-style8">
                <ul className="cs-card_meta cs-mp0">
                  <li>
                    <Icon icon="fa6-solid:flag" />
                    <span>
                      {t("due_to_text")}{" "}
                      {moment(item.created_at).format("DD-MMM-Y")}
                    </span>
                  </li>
                  <li>
                    <Icon icon="fa6-solid:clock" />
                    <span>{moment(item.created_at).format("LT")}</span>
                  </li>
                </ul>
                <h2 className="cs-card_title">{item.title}</h2>
                {item.image && <img src={item.image} alt="Thumb" />}
                <div className="cs-card_desc">{item.description}</div>
                <Spacing lg="15" md="15" />
                <div className="cs-tags">
                  {item.tag.map((tag) => (
                    <Link
                      to={`/dashboard/${creator.id}/broadcasts?tag=${tag}`}
                      className={`cs-tag ${
                        selectedTag === tag ? "active" : ""
                      }`}
                    >
                      {tag}
                    </Link>
                  ))}
                </div>
                <DotOption
                  url={`/dashboard/${item.userId}/broadcasts/${item.id}`}
                />
              </div>
            ))}
            {!broadcasts.length && !broadcastLoading ? <EmptyData /> : null}
          </div>
          <Spacing lg="20" md="20" />
          {brodcastCount > defaultHasMoreCount && (
            <PaginateButton
              loading={broadcastPaginateLoading}
              hasMore={broadcastHasMore}
              onClick={broadcastPaginate}
            />
          )}
        </div>
        <div className="cs-layout_right">
          <div className="cs-section_heading_4">
            <h2 className="cs-section_title">{t("pinned_by_creator_text")}</h2>
            <div className="cs-section_heading_right">
              {/* <button className="cs-icon_btn">
                <svg
                  width={9}
                  height={13}
                  viewBox="0 0 9 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.121661 12.7023L4.50004 9.08404L8.87842 12.7023V1.33066C8.87842 0.759493 8.38859 0.296875 7.78382 0.296875H1.21626C0.611492 0.296875 0.121661 0.759493 0.121661 1.33066V12.7023Z"
                    fill="currentColor"
                  />
                </svg>
              </button> */}
            </div>
          </div>
          <Spacing lg="30" md="30" />
          <div className="cs-card_8_wrap">
            {pinnedBroadcasts.map((item) => (
              <div className="cs-card cs-style8 cs-type1">
                <ul className="cs-card_meta cs-mp0">
                  <li>
                    <span>{moment(item.created_at).format("DD-MMM-Y")}</span>
                  </li>
                  <li>
                    <span>{moment(item.created_at).format("LT")}</span>
                  </li>
                </ul>
                <h2 className="cs-card_title">{item.title}</h2>
                <div className="cs-card_desc">{item.description}</div>
                {item.image && <img src={item.image} alt="Thumb" />}
                <div className="cs-tags">
                  {item.tag.map((tag) => (
                    <Link
                      to={`/dashboard/${creator.id}/broadcasts?tag=${tag}`}
                      className={`cs-tag ${
                        selectedTag === tag ? "active" : ""
                      }`}
                    >
                      {tag}
                    </Link>
                  ))}
                </div>
                <DotOption
                  url={`/dashboard/${item.userId}/broadcasts/${item.id}`}
                />
              </div>
            ))}
            {!pinnedBroadcasts.length && !pinnedBroadcastLoading ? (
              <EmptyData />
            ) : null}
          </div>
          <Spacing lg="20" md="20" />
          {pinnedBroadcastCount > defaultHasMoreCount && (
            <PaginateButton
              loading={pinnedBroadcastPaginateLoading}
              hasMore={pinnedBroadcastHasMore}
              onClick={pinnedBroadcastPaginate}
            />
          )}
          <Spacing lg="20" md="20" />
          {isDisclaimer && (
            <div className="cs-disclaimer_card">
              <span
                className="cs-disclaimer_close"
                onClick={handleBroadcastClose}
              >
                <svg
                  width={21}
                  height={22}
                  viewBox="0 0 21 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    y="0.457031"
                    width={21}
                    height="21.5309"
                    rx="10.5"
                    fill="#E5E5E5"
                  />
                  <path
                    d="M10.5 10.1117L14.3891 6.22266L15.5 7.33359L11.6109 11.2227L15.5 15.1117L14.3891 16.2227L10.5 12.3336L6.61094 16.2227L5.5 15.1117L9.38906 11.2227L5.5 7.33359L6.61094 6.22266L10.5 10.1117Z"
                    fill="#4F4F4F"
                  />
                </svg>
              </span>
              <h2>
                <svg
                  width={12}
                  height={15}
                  viewBox="0 0 12 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.1786 11.9431C9.90336 11.2973 9.85486 8.52519 9.85391 8.45525V6.59553C9.85391 4.80564 8.87858 3.26653 7.4926 2.61119C7.48962 1.69096 6.82126 0.943359 6.00004 0.943359C5.17881 0.943359 4.51047 1.691 4.50747 2.61115C3.12155 3.26648 2.14619 4.8056 2.14619 6.59548V8.45521C2.14529 8.52515 2.09674 11.2972 0.821467 11.943C0.676192 12.0166 0.596349 12.194 0.629937 12.3687C0.663487 12.5435 0.80131 12.6683 0.960742 12.6683H4.19369C4.25996 13.0772 4.43236 13.4575 4.69602 13.7656C5.04622 14.1748 5.50934 14.4001 5.99999 14.4001C6.49067 14.4001 6.95379 14.1748 7.30395 13.7656C7.56766 13.4575 7.74005 13.0771 7.80628 12.6683H11.0392C11.1987 12.6683 11.3365 12.5435 11.3701 12.3687C11.4037 12.194 11.3239 12.0166 11.1786 11.9431ZM9.49874 10.6151C9.6328 11.0709 9.82262 11.5286 10.0918 11.9095H1.90825C2.17739 11.5286 2.36721 11.0709 2.50127 10.6151H9.49874ZM6.00004 1.70228C6.37152 1.70228 6.6856 1.98219 6.78387 2.36373C6.53069 2.30485 6.26854 2.2738 6.00004 2.2738C5.73155 2.2738 5.4694 2.30483 5.21622 2.36373C5.31451 1.98222 5.62859 1.70228 6.00004 1.70228ZM2.82296 8.45754V6.59546C2.82296 4.63097 4.24818 3.03273 6.00004 3.03273C7.75191 3.03273 9.17713 4.63097 9.17713 6.59546V8.46064C9.17758 8.51772 9.1855 9.11763 9.32032 9.85612H2.67969C2.81459 9.11692 2.82255 8.51596 2.82296 8.45754ZM6.00004 13.6412C5.48355 13.6412 5.03279 13.2236 4.8881 12.6684H7.11195C6.96726 13.2236 6.51658 13.6412 6.00004 13.6412Z"
                    fill="#54595E"
                  />
                  <path
                    d="M5.99996 3.56445C4.54407 3.56445 3.35962 4.89265 3.35962 6.52526C3.35962 6.73483 3.51113 6.90473 3.69801 6.90473C3.88489 6.90473 4.0364 6.73483 4.0364 6.52526C4.0364 5.31113 4.91725 4.32338 5.99998 4.32338C6.18686 4.32338 6.33837 4.15347 6.33837 3.9439C6.33835 3.73431 6.18684 3.56445 5.99996 3.56445Z"
                    fill="#54595E"
                  />
                </svg>
                <span>{t("disclaimer")}:</span>
              </h2>
              <p>
                {t("disclaimer_text_1")} <br /> <br />
                {t("disclaimer_text_2")}
              </p>
            </div>
          )}
        </div>
      </div>
      <Spacing lg="50" md="50" />
    </div>
  );
}
