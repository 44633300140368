import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import { getCreatorWatchList } from "../../../api/services/creator/watchlist";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import DateRangeComp from "../../../components/DateRangeComp";
import DotOption from "../../../components/DotOption";
import Spacing from "../../../components/Spacing";
import { v4 as uuidv4 } from "uuid";
import { dateConvart } from "../../../utils/dateConvart";
import PaginateButton from "../../../components/Common/PaginateButton";
import { defaultHasMoreCount } from "../../../config/const/hasMore";
import { subDays, addDays } from "date-fns/esm";
import EmptyData from "../../../components/Common/EmptyData";
import { useTranslation } from "react-i18next";

export default function Watchlists() {
  const { t } = useTranslation();
  // date state
  const [range, setRange] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: addDays(new Date(), 365),
      key: "selection",
    },
  ]);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [creator] = useOutletContext();
  const [watchlists, setWatchlists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [watchlistPage, setWatchlistPage] = useState(2);
  const [watchlistHasMore, setWatchlistHasMore] = useState(true);
  const [watchlistCount, setWatchlistCount] = useState(0);
  const [watchlistPaginateLoading, setWatchlistPaginateLoading] =
    useState(false);

  const searchQuery = searchParams.get("search") ?? "";
  const watchlist = async () => {
    setLoading(true);
    const res = await getCreatorWatchList(
      params.creator_id,
      1,
      5,
      searchQuery,
      "",
      dateConvart(range[0].startDate),
      dateConvart(range[0].endDate)
    );
    setWatchlists(res.data.data);
    setWatchlistCount(res.data.count);
    setLoading(false);
  };

  // watch list paginate
  const paginate = async () => {
    setWatchlistPaginateLoading(true);
    const res = await getCreatorWatchList(
      params.creator_id,
      watchlistPage,
      5,
      searchQuery,
      "",
      dateConvart(range[0].startDate),
      dateConvart(range[0].endDate)
    );
    setWatchlists([...watchlists, ...res.data.data]);
    setWatchlistPage(watchlistPage + 1);
    setWatchlistPaginateLoading(false);
    if (!res.data.data.length) {
      setWatchlistHasMore(false);
    }
  };
  useEffect(() => {
    watchlist();
  }, [range, searchParams]);
  return (
    <div className="cs-container_md" style={{ minHeight: "80vh" }}>
      <Spacing lg="30" md="40" />
      <div className="cs-section_heading_2">
        <div className="cs-section_left">
          <h2 className="cs-section_title">
            {creator.name_show}
            {t("watchlists_title")}
          </h2>
        </div>
        <div className="cs-section_right">
          <DateRangeComp range={range} setRange={setRange} />
        </div>
      </div>
      <Spacing lg="30" md="30" />
      <div className={`${loading ? "cs-loading_wrap" : ""}`}>
        {loading && (
          <>
            <Spacing lg="50" md="50" />
            <LoadingSpinner />
          </>
        )}
        {watchlists.map((item) => (
          <div key={uuidv4()}>
            <div className="cs-watchlist">
              <div className="cs-watchlist_left">
                <div className="cs-add_date_heading">
                  <div className="cs-add_date_in">
                    <span>{moment(item.note_date).format("dddd")}</span>
                    {/* <span>22 Jan</span> */}
                    <span>{moment(item.note_date).format("DD MMM")}</span>
                  </div>
                  <DotOption
                    url={`/dashboard/${item.userId}/watchlists/${item.id}`}
                  />
                </div>
                {/* <Spacing lg='5' md='5'/>
          <div className="cs-add_date_text">Some of these may need a pullback or some tighte-ning, but this is our focus coming into the week. Not the RED signal - also!</div> */}
                <Spacing lg="25" md="25" />
                {/* <button className="cs-add_date_btn">+ Add Date</button> */}
              </div>
              <div className="cs-watchlist_right">
                <div className="cs-todo_input">
                  <label className="cs-todo_label">
                    {t("watchlists_creators_note")}
                  </label>
                  <div style={{ marginTop: "10px" }} className="cs-break_all">
                    {item.note}
                  </div>
                  {/* <input type="text" className="cs-form_field" placeholder='Enter note' /> */}
                </div>
                <Spacing lg="25" md="25" />
                <ul className="cs-todo_list cs-mp0">
                  {item.components.map((ticker) => (
                    <li key={uuidv4()}>
                      <div className="cs-todo">
                        <div className="cs-todo_title">{ticker.title}</div>
                        {/* <div className="cs-todo_subtitle">
                          Posted in {moment(ticker.created_at).format("LT")}
                        </div> */}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <Spacing lg="25" md="25" />
          </div>
        ))}
        {!watchlists.length && !loading ? <EmptyData /> : null}
      </div>
      {watchlistCount > defaultHasMoreCount && (
        <PaginateButton
          onClick={paginate}
          loading={watchlistPaginateLoading}
          hasMore={watchlistHasMore}
        />
      )}
      <Spacing lg="50" md="50" />
    </div>
  );
}
