import { Route, Routes, useLocation } from "react-router-dom";
import CreatorPublicView from "./components/CreatorPublicView";
import DashboardLayout from "./components/Layout/DashboardLayout";
import MainLayout from "./components/Layout/MainLayout";
import SettingsLayout from "./components/Layout/SettingsLayout";
import NewsletterDetailsView from "./components/NewsletterDetailsView";
import ResourcesFaqView from "./components/ResourcesFaqView";
import WebinarVideoView from "./components/WebinarVideoView";
import ErrorPage from "./components/ErrorPage";
import BillingInformation from "./pages/BillingInformation";
import Broadcasts from "./pages/dashboard/Broadcasts";
import Newsletter from "./pages/dashboard/Newsletter";
import OpenPosition from "./pages/dashboard/OpenPosition";
import ResourcesFaq from "./pages/dashboard/ResourcesFaq";
import TradeDiary from "./pages/dashboard/TradeDiary";
import Watchlists from "./pages/dashboard/Watchlists";
import WebinarVideo from "./pages/dashboard/WebinarVideo";
import Feed from "./pages/Feed";
import EmailVerification from "./pages/auth/EmailVerification";
import EmailConfirmation from "./pages/auth/EmailConfirmation";
import FogotPassword from "./pages/auth/FogotPassword";
import Login from "./pages/auth/Login";
import PersonalInfo from "./pages/auth/PersonalInfo";
import Register from "./pages/auth/Register";
import MyCreator from "./pages/MyCreator";
import SearchCreators from "./pages/SearchCreators";
import BasicInfo from "./pages/settings/BasicInfo";
import ChangePassword from "./pages/settings/ChangePassword";
import EditSubscription from "./pages/settings/EditSubscription";
import MySubscription from "./pages/settings/MySubscription";
import UpdatePassword from "./pages/auth/UpdatePassword";
import RelatedPages from "./components/RelatedPages";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentCancel from "./pages/PaymentCancel";
import BoradcastDetails from "./pages/dashboard/Broadcasts/BoradcastDetails";
import TradeDiaryDetails from "./pages/dashboard/TradeDiary/TradeDiaryDetails";
import WatchlistsDetails from "./pages/dashboard/Watchlists/WatchlistsDetails";
import OpenPositionDetails from "./pages/dashboard/OpenPosition/OpenPositionDetails";
import NewsletterDetails from "./pages/dashboard/Newsletter/NewsletterDetails";
import WebinarVideoDetails from "./pages/dashboard/WebinarVideo/WebinarVideoDetails";
import ResourcesFaqDetails from "./pages/dashboard/ResourcesFaq/ResourcesFaqDetails";
import { useEffect } from "react";
import NotificationSettings from "./pages/settings/NotificationSettings";
import AllNotificationPage from "./pages/settings/AllNotification";
import MultipleOpenPositionDetails from "./pages/dashboard/OpenPosition/MultipleOpenPositionDetails";
import SubscribeCreator from "./pages/SubscribeCreator";
import RoleSwitch from "./pages/RoleSwitch";

function App() {
  const location = useLocation();
  useEffect(() => {
    const currentPath = sessionStorage.getItem("currentPath") ?? "/";
    sessionStorage.setItem("currentPath", location.pathname);
    sessionStorage.setItem("prevPath", currentPath);
  }, [location.pathname]);
  return (
    <>
      <Routes>
        <Route path="/pages" element={<RelatedPages />} />
        <Route path="/auth">
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="email-verification" element={<EmailVerification />} />
          <Route path="fogot-password" element={<FogotPassword />} />
          <Route path="email-confirmation" element={<EmailConfirmation />} />
          <Route path="update-password" element={<UpdatePassword />} />
          <Route path="personal-info" element={<PersonalInfo />} />
        </Route>
        <Route path="/" element={<MainLayout showSearch />}>
          <Route index element={<Feed />} />
          <Route path="my-creators" element={<MyCreator />} />

          <Route path="billing-information" element={<BillingInformation />} />
          <Route
            path="creator-public-view/:id"
            element={<CreatorPublicView />}
          />
          <Route path="all-notification" element={<AllNotificationPage />} />
        </Route>
        <Route path="/" element={<MainLayout />}>
          <Route path="search-creator" element={<SearchCreators />} />
        </Route>
        <Route path="/settings" element={<SettingsLayout />}>
          <Route path="basic-info" element={<BasicInfo />} />
          <Route
            path="notification-settings"
            element={<NotificationSettings />}
          />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="my-subscription" element={<MySubscription />} />
          <Route
            path="my-subscription/:id/edit-subscription"
            element={<EditSubscription />}
          />
        </Route>
        <Route
          path="/dashboard/:creator_id"
          element={<DashboardLayout showSearch />}
        >
          <Route path="broadcasts" element={<Broadcasts />} />
          <Route path="broadcasts/:id" element={<BoradcastDetails />} />
          <Route path="trade-diary" element={<TradeDiary />} />
          <Route path="trade-diary/:id" element={<TradeDiaryDetails />} />
          <Route path="watchlists" element={<Watchlists />} />
          <Route path="watchlists/:id" element={<WatchlistsDetails />} />
          <Route path="open-position" element={<OpenPosition />} />
          <Route path="open-position/:id" element={<OpenPositionDetails />} />
          <Route
            path="open-positions"
            element={<MultipleOpenPositionDetails />}
          />
          <Route path="newsletter" element={<Newsletter />} />
          <Route path="newsletter/:id" element={<NewsletterDetails />} />
          <Route
            path="newsletter/view/:id"
            element={<NewsletterDetailsView />}
          />
          <Route path="webinar-video" element={<WebinarVideo />} />
          <Route path="webinar-video/:id" element={<WebinarVideoDetails />} />
          <Route path="webinar-video/view/:id" element={<WebinarVideoView />} />
          <Route path="resources-faq" element={<ResourcesFaq />} />
          <Route path="resources-faq/:id" element={<ResourcesFaqDetails />} />
          <Route path="resources-faq/view/:id" element={<ResourcesFaqView />} />
        </Route>
        <Route path="payment/success" element={<PaymentSuccess />} />
        <Route path="payment/cancel" element={<PaymentCancel />} />
        <Route path="subscribe-creator" element={<SubscribeCreator />} />
        <Route path="role-switch" element={<RoleSwitch />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
