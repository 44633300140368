import { creatorOpenPositionUrl, multipleOpenPositionUrl } from "../../const";
import axios from "../../lib/axios";
// get creator open position
export const creatorOpenPosition = async (
  creator_id,
  post_id = "",
  search = ""
) => {
  return await axios.get(creatorOpenPositionUrl, {
    params: { creator_id, post_id, search },
  });
};

// get multiple openposition
export const getMultipleOpenPossition = async (creator_id, ids) => {
  return await axios.get(multipleOpenPositionUrl, {
    params: { creator_id, ids },
  });
};
