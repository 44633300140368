import { useEffect, useState } from 'react';
import {
  canceledBillingInfo,
  checkSubscribtion,
} from '../../../api/services/billingInfo';
import LoadingSpinner from '../../../components/Common/LoadingSpinner';
import Spacing from '../../../components/Spacing';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { Icon } from '@iconify/react';
import {
  getPublicCreatorTier,
  processResubscribePayment,
  processToStripePayment,
} from '../../../api/services/public/creator';
import MembershipTire from '../../../components/MembershipTire';
import { useTranslation } from 'react-i18next';

export default function Canceled() {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [loading, setLoading] = useState(false);
  const [billingInfo, setBillingInfo] = useState([]);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [creatorId, setCreatorId] = useState(false);
  const [renewTierId, setRenewTierId] = useState('');
  const [tierCheckLoading, setTierCheckLoading] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState('');
  const [tiers, setTiers] = useState([]);
  const [tierLoading, setTierLoading] = useState(false);
  const [creatorName, setCreatorName] = useState('');
  const [tierName, setTierName] = useState('');
  const [renewMessage, setRenewMessage] = useState('');
  const [tierErrorMessage, setTierErrorMesage] = useState('');
  // get all billing info
  const allBilling = async () => {
    setLoading(true);
    const res = await canceledBillingInfo();
    setBillingInfo(res.data.data.data);
    setLoading(false);
  };

  // renew now
  const renewNow = async (index, tierId, subId) => {
    setTierCheckLoading(true);
    setLoadingIndex(index);
    setCreatorId(subId);
    const res = await checkSubscribtion(tierId);
    if (res.data.data === 1) {
      setRenewTierId(tierId);
      setRenewMessage(res.data.message);
      setModal(true);
    } else {
      setModal3(true);
    }
    setTierCheckLoading(false);
    setLoadingIndex('');
  };

  // process to pay
  const processToPayment = async id => {
    setPaymentLoading(true);
    const baseUrl = window.location.origin;
    const data = {
      membership_tire_id: id,
      success_url: `${baseUrl}/payment/success?creator_id=${creatorId}&`,
      cancel_url: `${baseUrl}/payment/cancel?creator_id=${creatorId}&`,
    };
    const res = await processResubscribePayment(data);
    if (res.data.success === 1) {
      window.location.href = res.data.data.url;
    }
  };

  // get tier
  const getTier = async () => {
    setTiers([]);
    setTierLoading(true);
    const res = await getPublicCreatorTier(creatorId);
    if (res.data.success === 1) {
      setTiers(res.data.data);
    } else {
      setTierErrorMesage(res.data.message);
    }
    setTiers(res.data.data);
    setTierLoading(false);
  };

  useEffect(() => {
    allBilling();
  }, []);
  return (
    <>
      <div className="cs-table_responsive">
        <table
          className={`cs-table cs-medium ${loading ? 'cs-loading_wrap' : ''}`}
          style={{ minWidth: '700px' }}
        >
          {loading && (
            <>
              <Spacing lg="50" md="50" />
              <LoadingSpinner />
            </>
          )}
          <thead>
            <tr>
              <th style={{ width: '25%' }}>
                {t('billing_information_canceled_table_heading_1')}
              </th>
              <th>{t('billing_information_canceled_table_heading_2')}</th>
              <th>{t('billing_information_canceled_table_heading_3')}</th>
              <th>{t('billing_information_canceled_table_heading_4')}</th>
              <th>{t('billing_information_canceled_table_heading_5')}</th>
              <th>{t('billing_information_canceled_table_heading_6')}</th>
            </tr>
          </thead>
          <tbody>
            {billingInfo.map((item, index) => (
              <tr key={uuidv4()}>
                <td>{item.items.data[0].plan.metadata?.title}</td>
                <td className="cs-accent_color">
                  {item.items.data[0].plan.metadata?.fullname}
                </td>
                <td>
                  {item.items.data[0].plan.metadata?.price}{' '}
                  {item.items.data[0].plan.metadata?.currency}
                </td>
                <td>{moment(item.canceled_at * 1000).format('MMM D, Y')}</td>
                <td>
                  <span className="cs-table_btn">{t('inactive_text')}</span>
                </td>
                <td>
                  <button
                    onClick={() => {
                      renewNow(
                        index,
                        item.items.data[0].plan.metadata?.tierId,
                        item.items.data[0].plan.metadata?.id,
                      );
                      setCreatorName(
                        item.items.data[0].plan.metadata?.fullname,
                      );
                      setTierName(item.items.data[0].plan.metadata?.title);
                    }}
                    className={`cs-table_btn cs-color1 ${
                      tierCheckLoading && loadingIndex === index
                        ? 'cs-loading_wrap'
                        : ''
                    }`}
                    disabled={tierCheckLoading}
                  >
                    {tierCheckLoading && loadingIndex === index && (
                      <span className="cs-btn_loading">
                        <Icon icon="la:spinner" />
                        {t('loading_text')}
                      </span>
                    )}
                    {t('renew_text')}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {modal && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div className="cs-modal_box cs-style1">
                <Spacing lg="45" md="45" />
                <div className="cs-modal_box_in">
                  <div className="cs-text_center">
                    <h2
                      className="cs-semibold cs-m0"
                      style={{ fontSize: '20px' }}
                    >
                      {t('billing_information_canceled_modal_text_1_1')}{' '}
                      {tierName}{' '}
                      {t('billing_information_canceled_modal_text_1_2')}
                    </h2>
                    <Spacing lg="5" md="5" />
                    <div style={{ fontSize: '14px', lineHeight: '1.6em' }}>
                      {renewMessage}
                    </div>
                  </div>
                  <Spacing lg="30" md="30" />
                  <div className="cs-modal_btns">
                    <button
                      className="cs-modal_btn"
                      onClick={() => setModal(false)}
                    >
                      {t('cancel_text')}
                    </button>
                    <button
                      className={`cs-modal_btn cs-color1 ${
                        paymentLoading ? 'cs-loading_wrap' : ''
                      }`}
                      disabled={paymentLoading}
                      onClick={() => processToPayment(renewTierId)}
                    >
                      {paymentLoading && (
                        <span className="cs-btn_loading">
                          <Icon icon="la:spinner" />
                          {t('loading_text')}
                        </span>
                      )}
                      {t('confirm_text')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {modal3 && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal3(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div className="cs-modal_box cs-style1">
                <Spacing lg="45" md="45" />
                <div className="cs-modal_box_in">
                  <div className="cs-text_center">
                    <h2
                      className="cs-semibold cs-m0 cs-danger"
                      style={{ fontSize: '20px' }}
                    >
                      {t('billing_information_canceled_modal_text_2_1')}{' '}
                      {tierName}{' '}
                      {t('billing_information_canceled_modal_text_2_2')}
                    </h2>
                    <Spacing lg="5" md="5" />
                    <div style={{ fontSize: '14px', lineHeight: '1.6em' }}>
                      {t('billing_information_canceled_modal_text_3')}{' '}
                      {creatorName}
                    </div>
                  </div>
                  <Spacing lg="30" md="30" />
                  <div className="cs-modal_btns">
                    <button
                      className="cs-modal_btn"
                      onClick={() => setModal3(false)}
                    >
                      {t('cancel_text')}
                    </button>
                    <button
                      className="cs-modal_btn cs-color1"
                      onClick={() => {
                        getTier();
                        setModal2(true);
                        setModal3(false);
                      }}
                    >
                      {t('view_membership_tiers_text')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {modal2 && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal2(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div className="cs-modal_box cs-style2">
                <div className="cs-modal_box_in">
                  <button
                    className="cs-close_btn"
                    onClick={() => setModal2(false)}
                  >
                    <Icon icon="ri:close-circle-fill" />
                  </button>
                  <MembershipTire
                    tiers={tiers}
                    loading={tierLoading}
                    tierErrorMessage={tierErrorMessage}
                    title={t('select_membership_tier')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
