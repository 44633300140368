import { searchCreatorUrl } from "../const";
import axios from "../lib/axios";

// search creator
export const searchCreator = async (page = 1, limit = 5, search = "") => {
  return axios.get(searchCreatorUrl, {
    params: {
      page,
      limit,
      search,
    },
  });
};
