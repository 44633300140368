import { Icon } from '@iconify/react';
import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import {
  getBroadcastTiers,
  getCreatorBroadcast,
} from '../../../api/services/creator/broadcast';
import DotOption from '../../../components/DotOption';
import Spacing from '../../../components/Spacing';
import { tagStringToArray } from '../../../utils/tagStringToArray';
import MembershipTire from '../../../components/MembershipTire';
import Card3 from '../../../components/Card/Card3';
import { getPublicCreatorDetails } from '../../../api/services/public/creator';
import { useTranslation } from 'react-i18next';

export default function BoradcastDetails() {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const [creator, setCreator] = useState({});
  const params = useParams();
  const [broadcasts, setBoardcasts] = useState([]);
  const [brodcastCount, setBroadcastCount] = useState(0);
  const [broadcastLoading, setBroadcastLoading] = useState(false);
  const [tierLoading, setTierLoading] = useState(false);
  const [tiers, setTiers] = useState([]);
  const [modal, setModal] = useState(false);

  // get broadcast
  const broadcast = async () => {
    setBroadcastLoading(true);
    const res = await getCreatorBroadcast(
      params.creator_id,
      1,
      5,
      '',
      '',
      '',
      params.id,
    );
    setBroadcastLoading(false);
    setBoardcasts(tagStringToArray(res.data.data));
    setBroadcastCount(res.data.count);
  };

  // get tiers
  const getTiers = async () => {
    setTierLoading(true);
    const res = await getBroadcastTiers(params.id);
    setTiers(res.data.data);
    setTierLoading(false);
  };

  // get creator
  const getCreator = async () => {
    const res = await getPublicCreatorDetails(params.creator_id);
    setCreator(res.data.data);
  };

  useEffect(() => {
    broadcast();
    getCreator();
  }, [searchParams, params]);
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <div className="cs-layout_1">
        <div className="cs-layout_left">
          <h2 style={{ fontSize: '24px', marginBottom: '8px' }}>Broadcast</h2>
          <Spacing lg="30" md="30" />
          <div className="cs-card_8_wrap">
            {brodcastCount > 0 ? (
              <>
                {broadcasts.map(item => (
                  <div key={uuidv4()} className="cs-card cs-style8">
                    <ul className="cs-card_meta cs-mp0">
                      <li>
                        <Icon icon="fa6-solid:flag" />
                        <span>
                          {t('due_to_text')}{' '}
                          {moment(item.created_at).format('DD-MMM-Y')}
                        </span>
                      </li>
                      <li>
                        <Icon icon="fa6-solid:clock" />
                        <span>{moment(item.created_at).format('LT')}</span>
                      </li>
                    </ul>
                    <h2 className="cs-card_title">{item.title}</h2>
                    {item.image && <img src={item.image} alt="Thumb" />}
                    <div className="cs-card_desc">{item.description}</div>
                    <Spacing lg="15" md="15" />
                    <div className="cs-tags">
                      {item.tag.map(tag => (
                        <a key={uuidv4()} className={`cs-tag`}>
                          {tag}
                        </a>
                      ))}
                    </div>
                    <DotOption
                      url={`/dashboard/${item.userId}/broadcasts/${item.id}`}
                    />
                  </div>
                ))}
              </>
            ) : (
              <div className="cs-card cs-style8 cs-lock_wrap">
                <div className="cs-lock_overlay">
                  <svg
                    width={30}
                    height={45}
                    viewBox="0 0 30 45"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.375 18.3019V9.375C24.375 4.19813 20.1788 0 15 0C9.81938 0 5.625 4.19813 5.625 9.375V18.3019C2.19937 21.0506 0 25.2656 0 30C0 38.2838 6.71625 45 15 45C23.2838 45 30 38.2838 30 30C30 25.2656 27.8006 21.0506 24.375 18.3019ZM9.375 9.375C9.375 6.27375 11.8987 3.75 15 3.75C18.1013 3.75 20.625 6.27375 20.625 9.375V16.1006C18.8869 15.3956 16.9913 15 15 15C13.0087 15 11.1131 15.3956 9.375 16.1006V9.375ZM15 41.25C8.79562 41.25 3.75 36.2044 3.75 30C3.75 23.7956 8.79562 18.75 15 18.75C21.2044 18.75 26.25 23.7956 26.25 30C26.25 36.2044 21.2044 41.25 15 41.25ZM18.75 30C18.75 32.07 17.07 33.75 15 33.75C12.93 33.75 11.25 32.07 11.25 30C11.25 27.93 12.93 26.25 15 26.25C17.07 26.25 18.75 27.93 18.75 30Z"
                      fill="white"
                    />
                  </svg>
                  <h3>Subscribe to unlock post</h3>
                  <a
                    className="cs-lock_btn"
                    onClick={() => {
                      setModal(true);
                      getTiers();
                    }}
                  >
                    Subscribe now
                  </a>
                </div>
                <ul className="cs-card_meta cs-mp0">
                  <li>
                    <Icon icon="fa6-solid:flag" />
                    <span>{t('due_to_text')} 17-Jan-2023</span>
                  </li>
                  <li>
                    <Icon icon="fa6-solid:clock" />
                    <span>1:18 PM</span>
                  </li>
                </ul>
                <h2 className="cs-card_title">Broadcast title</h2>
                <img src="/images/thumb_3.jpeg" alt="Thumb" />
                <div className="cs-card_desc">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Consectetur minima ipsam veniam nesciunt est itaque a odit.
                  Voluptas dolore dignissimos distinctio ea, numquam autem
                  reiciendis consequuntur nihil enim, possimus eos.
                </div>
                <Spacing lg="15" md="15" />
                <div className="cs-tags">
                  <a className={`cs-tag`}>tag1</a>
                  <a className={`cs-tag`}>tag2</a>
                </div>
              </div>
            )}
          </div>
          <Spacing lg="20" md="20" />
        </div>
      </div>
      <Spacing lg="50" md="50" />{' '}
      {modal && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div className="cs-modal_box cs-style2">
                <div className="cs-modal_box_in">
                  <button
                    className="cs-close_btn"
                    onClick={() => setModal(false)}
                  >
                    <Icon icon="ri:close-circle-fill" />
                  </button>
                  <Card3 creator={creator} count={false} />
                  <Spacing lg="80" md="60" />
                  <MembershipTire
                    tiers={tiers}
                    loading={tierLoading}
                    title={t('select_membership_tier')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
