import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./sass/index.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserState from "./context/user/UserState";
import NotificationState from "./context/notification/NotificationState";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <UserState>
      <NotificationState>
        <ToastContainer position="top-center" />
        <App />
      </NotificationState>
    </UserState>
  </BrowserRouter>
  // </React.StrictMode>
);
