import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
export default function PaginateButton({ loading, hasMore, onClick }) {
  const { t } = useTranslation();
  return (
    <>
      {hasMore ? (
        <button
          className={`cs-load_more_btn ${loading ? 'cs-loading_wrap' : ''}`}
          onClick={onClick}
          disabled={loading}
        >
          {loading && (
            <span className="cs-btn_loading">
              <Icon icon="la:spinner" />
              {t('loading_text')}
            </span>
          )}
          {t('show_more_text')}
        </button>
      ) : (
        <div style={{ textAlign: 'center' }}>{t('seen_all_message')}</div>
      )}
    </>
  );
}
