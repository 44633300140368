import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { upcommingBillingInfo } from "../../../api/services/billingInfo";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import Spacing from "../../../components/Spacing";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useTranslation } from "react-i18next";

export default function UpcomingBilling() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [billingInfo, setBillingInfo] = useState([]);
  // get all billing info
  const allBilling = async () => {
    setLoading(true);
    const res = await upcommingBillingInfo();
    setBillingInfo(res.data.data.data);
    setLoading(false);
  };

  const set30Days = (timestamp) => {
    const date = new Date(timestamp);
    // Add 30 days to the date
    date.setDate(date.getDate() + 30);
    // Get the timestamp of the new date
    return date;
  };

  useEffect(() => {
    allBilling();
  }, []);
  return (
    <>
      <div className="cs-table_responsive">
        <table
          className={`cs-table cs-medium ${loading ? "cs-loading_wrap" : ""}`}
          style={{ minWidth: "700px" }}
        >
          {loading && (
            <>
              <Spacing lg="50" md="50" />
              <LoadingSpinner />
            </>
          )}
          <thead>
            <tr>
              <th>
                {t("billing_information_upcoming_billing_table_heading_1")}
              </th>
              <th>
                {t("billing_information_upcoming_billing_table_heading_2")}
              </th>
              <th>
                {t("billing_information_upcoming_billing_table_heading_3")}
              </th>
              <th>
                {t("billing_information_upcoming_billing_table_heading_4")}
              </th>
              <th>
                {t("billing_information_upcoming_billing_table_heading_5")}
              </th>
              <th>
                {t("billing_information_upcoming_billing_table_heading_6")}
              </th>
            </tr>
          </thead>
          <tbody>
            {billingInfo.map((item) => (
              <tr key={uuidv4()}>
                <td>
                  {moment(item.current_period_end * 1000).format("MMM D, Y")}
                </td>
                <td>{item.items.data[0].plan.metadata?.title}</td>
                <td className="cs-accent_color">
                  {item.items.data[0].plan.metadata?.fullname}
                </td>
                <td style={{ textTransform: "uppercase" }}>
                  {item.items.data[0].plan.metadata?.price}{" "}
                  {item.items.data[0].plan.metadata?.currency}
                </td>
                <td>
                  {moment(set30Days(item.current_period_end * 1000)).format(
                    "MMM D, Y"
                  )}
                </td>
                <td>{item.id}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
