import axios from 'axios';
import { baseURL } from '../const';

const instance = axios.create();

const token = localStorage.getItem('token')

instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

instance.defaults.headers.common['Accept'] = 'application/json';
instance.defaults.headers.common['Content-Type'] = 'application/json';


export default instance;