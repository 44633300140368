import { useEffect, useRef, useState } from 'react';
import { DateRange } from 'react-date-range';
import format from 'date-fns/format';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './date_range_comp.scss';
import { useTranslation } from 'react-i18next';

const DateRangeComp = ({ range, setRange }) => {
  const { t } = useTranslation();
  // open close
  const [open, setOpen] = useState(false);

  // get the target element to toggle
  const refOne = useRef(null);

  useEffect(() => {
    // event listeners
    document.addEventListener('keydown', hideOnEscape, true);
    document.addEventListener('click', hideOnClickOutside, true);
  }, []);

  // hide dropdown on ESC press
  const hideOnEscape = e => {
    if (e.key === 'Escape') {
      setOpen(false);
    }
  };

  // Hide on outside click
  const hideOnClickOutside = e => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  return (
    <div className="cs-calendar_wrap">
      <div className="cs-calender">
        {t('date_range')}
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.0039 2H15.0039V1C15.0039 0.734784 14.8986 0.48043 14.711 0.292893C14.5235 0.105357 14.2691 0 14.0039 0C13.7387 0 13.4843 0.105357 13.2968 0.292893C13.1093 0.48043 13.0039 0.734784 13.0039 1V2H7.00391V1C7.00391 0.734784 6.89855 0.48043 6.71101 0.292893C6.52348 0.105357 6.26912 0 6.00391 0C5.73869 0 5.48434 0.105357 5.2968 0.292893C5.10926 0.48043 5.00391 0.734784 5.00391 1V2H3.00391C2.20826 2 1.4452 2.31607 0.882586 2.87868C0.319977 3.44129 0.00390625 4.20435 0.00390625 5V17C0.00390625 17.7956 0.319977 18.5587 0.882586 19.1213C1.4452 19.6839 2.20826 20 3.00391 20H17.0039C17.7996 20 18.5626 19.6839 19.1252 19.1213C19.6878 18.5587 20.0039 17.7956 20.0039 17V5C20.0039 4.20435 19.6878 3.44129 19.1252 2.87868C18.5626 2.31607 17.7996 2 17.0039 2ZM18.0039 17C18.0039 17.2652 17.8986 17.5196 17.711 17.7071C17.5235 17.8946 17.2691 18 17.0039 18H3.00391C2.73869 18 2.48434 17.8946 2.2968 17.7071C2.10926 17.5196 2.00391 17.2652 2.00391 17V10H18.0039V17ZM18.0039 8H2.00391V5C2.00391 4.73478 2.10926 4.48043 2.2968 4.29289C2.48434 4.10536 2.73869 4 3.00391 4H5.00391V5C5.00391 5.26522 5.10926 5.51957 5.2968 5.70711C5.48434 5.89464 5.73869 6 6.00391 6C6.26912 6 6.52348 5.89464 6.71101 5.70711C6.89855 5.51957 7.00391 5.26522 7.00391 5V4H13.0039V5C13.0039 5.26522 13.1093 5.51957 13.2968 5.70711C13.4843 5.89464 13.7387 6 14.0039 6C14.2691 6 14.5235 5.89464 14.711 5.70711C14.8986 5.51957 15.0039 5.26522 15.0039 5V4H17.0039C17.2691 4 17.5235 4.10536 17.711 4.29289C17.8986 4.48043 18.0039 4.73478 18.0039 5V8Z"
            fill="#4680C2"
          />
        </svg>
      </div>
      <input
        value={`${format(range[0].startDate, 'MM/dd/yyyy')} to ${format(
          range[0].endDate,
          'MM/dd/yyyy',
        )}`}
        readOnly
        className="inputBox"
        onClick={() => setOpen(open => !open)}
      />
      <div ref={refOne}>
        {open && (
          <DateRange
            onChange={item => setRange([item.selection])}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            ranges={range}
            months={1}
            direction="horizontal"
            className="calendarElement"
          />
        )}
      </div>
    </div>
  );
};

export default DateRangeComp;
