import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
import "./footer.scss";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="cs-footer">
      <div className="cs-container">
        <div className="cs-footer_in">
          <div className="cs-footer_left">
            <div className="cs-footer_logo">
              <img src="/images/logo_white.svg" alt="Logo" />
            </div>
          </div>
          <div className="cs-footer_right">
            <div className="cs-footer_col"></div>
            <div className="cs-footer_col">
              <ul className="cs-footer_menu">
                <li>
                  <a href="https://gorudo.io/terms-of-use">
                    {t("terms_of_use")}
                  </a>
                </li>
                <li>
                  <a href="https://gorudo.io/privacy">{t("privacy_policy")}</a>
                </li>
                <li>
                  <a href="https://gorudo.io/disclaimer">{t("disclaimer")}</a>
                </li>
                <li>
                  <a href="https://gorudo.io/faqs">Faqs</a>
                </li>
              </ul>
            </div>
            <div className="cs-footer_col">
              <div className="cs-footer_title">{t("follow_us")}</div>
              <Link to="/" className="cs-footer_btn_1">
                <Icon icon="fa6-brands:square-facebook" />
              </Link>
              <br />
              <Link to="/" className="cs-footer_btn_2">
                <>
                  <Icon icon="ph:envelope-simple" />
                  admin@gorudo.io
                </>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
