import { Icon } from '@iconify/react';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { searchCreator } from '../api/services/searchcreator';
import Card from '../components/Card';
import EmptyData from '../components/Common/EmptyData';
import LoadingSpinner from '../components/Common/LoadingSpinner';
import PaginateButton from '../components/Common/PaginateButton';
import Spacing from '../components/Spacing';
import { defaultHasMoreCount } from '../config/const/hasMore';
import { useTranslation } from 'react-i18next';

export default function SearchCreators() {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [creators, setCreators] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [paginateLoading, setPaginateLoading] = useState(false);
  const [searchString, setSearchString] = useState();
  const searchQuery = searchParams.get('search') ?? '';

  // handle search
  const handleSearch = async () => {
    setLoading(true);
    const res = await searchCreator(1, 5, searchQuery);
    setLoading(false);
    setCount(res.data.count);
    setCreators(res.data.data);
  };

  // handle paginate
  const paginate = async () => {
    setPaginateLoading(true);
    const res = await searchCreator(page, 5, searchQuery);
    setPaginateLoading(false);
    setPage(page + 1);
    setCreators([...creators, ...res.data.data]);
    if (!res.data.data.length) {
      setHasMore(false);
    }
  };

  // handle search query
  const handleSearchQuery = () => {
    const path = location.pathname;
    navigate(`${path}?search=${searchString}`);
  };
  useEffect(() => {
    handleSearch();
  }, [searchParams]);

  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <div className="cs-search_box">
        <h2>{t('search_creators_title')}</h2>
        <form
          className="cs-search_box_in"
          onSubmit={e => {
            e.preventDefault();
            handleSearchQuery();
          }}
        >
          <input
            type="text"
            placeholder={t('search_creators_placeholder')}
            onChange={e => {
              setSearchString(e.target.value);
            }}
          />
          <button>
            <Icon icon="material-symbols:search" />
          </button>
        </form>
        <p className="cs-m0 cs-accent_color">
          {count} {t('search_creators_result_text')}
        </p>
      </div>
      <Spacing lg="30" md="30" />
      <div className={`cs-search_result ${loading ? 'cs-loading_wrap' : ''}`}>
        {loading && (
          <>
            <Spacing lg="50" md="50" />
            <LoadingSpinner />
          </>
        )}
        {creators.map(item => (
          <Card
            key={item.id}
            cardHref={`/creator-public-view/${item.id}`}
            src={item.image}
            title={item.name_show}
            subtitle={item.about_yourself}
            memberNumber={item.members}
            broadcastNumber={item.broadcasts}
            tradeDairyNumber={item.trade_dairy}
            broadcastRecentNumber={false}
            tradeRecentDairyNumber={false}
          />
        ))}
        {!creators.length && !loading ? <EmptyData /> : null}
      </div>
      <Spacing lg="20" md="20" />
      {count > defaultHasMoreCount && (
        <PaginateButton
          loading={paginateLoading}
          hasMore={hasMore}
          onClick={paginate}
        />
      )}
      <Spacing lg="60" md="60" />
    </div>
  );
}
