import { emailNotificationSettingsUrl, pushNotificationUrl } from "../../const";
import axios from "../../lib/axios";

// update email settings
export const updateEmailNotificationSettings = async (data) => {
  data.email = data.email ? 1 : 0;
  data.broadcast = data.broadcast ? 1 : 0;
  data.trade_diary = data.trade_diary ? 1 : 0;
  data.watchlist = data.watchlist ? 1 : 0;
  data.open_position = data.open_position ? 1 : 0;
  data.resources = data.resources ? 1 : 0;
  data.newsletter = data.newsletter ? 1 : 0;
  data.webinar = data.webinar ? 1 : 0;
  return await axios.post(emailNotificationSettingsUrl, data);
};

// update push settings
export const updatePushNotificationSettings = async (data) => {
  data.push = data.push ? 1 : 0;
  data.broadcast = data.broadcast ? 1 : 0;
  data.trade_diary = data.trade_diary ? 1 : 0;
  data.watchlist = data.watchlist ? 1 : 0;
  data.open_position = data.open_position ? 1 : 0;
  data.resources = data.resources ? 1 : 0;
  data.newsletter = data.newsletter ? 1 : 0;
  data.webinar = data.webinar ? 1 : 0;
  return await axios.post(pushNotificationUrl, data);
};

// get email settings
export const getEmailSettings = async () => {
  return await axios.get(emailNotificationSettingsUrl);
};

// get push settings
export const getPushSettings = async () => {
  return await axios.get(pushNotificationUrl);
};
