import { Icon } from '@iconify/react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { checkSubscribtion } from '../../../api/services/billingInfo';
import { getUnsubscribedCreator } from '../../../api/services/myCreator';
import {
  getPublicCreatorTier,
  processResubscribePayment,
  processToStripePayment,
} from '../../../api/services/public/creator';
import Card from '../../../components/Card';
import EmptyData from '../../../components/Common/EmptyData';
import LoadingSpinner from '../../../components/Common/LoadingSpinner';
import PaginateButton from '../../../components/Common/PaginateButton';
import MembershipTire from '../../../components/MembershipTire';
import Spacing from '../../../components/Spacing';
import { defaultHasMoreCount } from '../../../config/const/hasMore';
import { useTranslation } from 'react-i18next';

export default function Unsubscribed({ count }) {
  // Flag variable to track cancellation
  let isCancelled = false;

  // AbortController for cancelling fetch requests
  const abortController = new AbortController();
  const { signal } = abortController;

  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [creator, setCretor] = useState([]);
  const [creatorCount, setCreatorCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [paginateLoading, setPaginateLoading] = useState(false);
  const [page, setPage] = useState(2);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [renewTierId, setRenewTierId] = useState('');
  const [tierCheckLoading, setTierCheckLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState('');
  const [creatorName, setCreatorName] = useState('');
  const [tiers, setTiers] = useState([]);
  const [tierLoading, setTierLoading] = useState(false);
  const [renewMessage, setRenewMessage] = useState('');
  const [creatorId, setCreatorId] = useState('');
  const [tierName, setTierName] = useState('');

  const searchQuery = searchParams.get('search') ?? '';

  // get creator
  const unsubscribedCreator = async () => {
    setLoading(true);
    try {
      const res = await getUnsubscribedCreator(1, 5, searchQuery, { signal });
      if (!isCancelled) {
        setLoading(false);
        setCretor(res.data.data);
        setCreatorCount(res.data.count);
        count(res.data.count);
      }
    } catch (error) {
      if (!isCancelled) {
        // Handle the error
      }
    }
  };

  // paginate
  const paginate = async () => {
    setPaginateLoading(true);
    const res = await getUnsubscribedCreator(page, 5, searchQuery);
    setPaginateLoading(false);
    setCretor([...creator, ...res.data.data]);
    setPage(page + 1);
    if (!res.data.data.length) {
      setHasMore(false);
    }
  };

  // get tier
  const getTier = async () => {
    setTiers([]);
    setTierLoading(true);
    const res = await getPublicCreatorTier(creatorId);
    setTiers(res.data.data);
    setTierLoading(false);
  };

  // renew now
  const renewNow = async (index, tierId, creatorId, creatorname, tierName) => {
    setTierCheckLoading(true);
    setLoadingIndex(index);
    setCreatorName(creatorname);
    setCreatorId(creatorId);
    setTierName(tierName);
    const res = await checkSubscribtion(tierId);
    if (res.data.data === 1) {
      setRenewTierId(tierId);
      setRenewMessage(res.data.message);
      setModal(true);
    } else {
      setModal3(true);
    }
    setTierCheckLoading(false);
    setLoadingIndex('');
  };

  // process to pay
  const processToPayment = async id => {
    setPaymentLoading(true);
    const baseUrl = window.location.origin;
    const data = {
      membership_tire_id: id,
      success_url: `${baseUrl}/payment/success?creator_id=${creatorId}&`,
      cancel_url: `${baseUrl}/payment/cancel?creator_id=${creatorId}&`,
    };
    const res = await processResubscribePayment(data);
    if (res.data.success === 1) {
      if (res.data.data.url) {
        window.location.href = res.data.data.url;
      } else {
        navigate('/my-creators');
      }
    }
  };
  useEffect(() => {
    unsubscribedCreator();

    return () => {
      isCancelled = true; // Set the flag to true
      abortController.abort(); // Cancel the fetch request
    };
  }, [searchParams]);
  return (
    <>
      <div className={`${loading ? 'cs-loading_wrap' : ''}`}>
        {loading && (
          <>
            <Spacing lg="50" md="50" />
            <LoadingSpinner />
          </>
        )}
        {creator.map((item, index) => (
          <Card
            // cardHref={`/dashboard/${item.id}/broadcasts`}
            cardHref={
              new Date(item.expire_at) < new Date()
                ? `/creator-public-view/${item.id}`
                : `/dashboard/${item.id}/broadcasts`
            }
            src={item.image}
            title={item.name_show}
            subtitle={item.about_yourself}
            memberNumber={item.members}
            broadcastNumber={item.broadcasts}
            tradeDairyNumber={item.trade_dairy}
            index={index}
            tierId={item.membershipTireId}
            subscribeBtn
            creatorId={item.id}
            renewNow={renewNow}
            membership_tiers_title={item.membership_tiers_title}
            tierCheckLoading={tierCheckLoading}
            loadingIndex={loadingIndex}
          />
        ))}
        {!creator.length && !loading ? <EmptyData /> : null}
      </div>
      {creatorCount > defaultHasMoreCount && (
        <>
          <Spacing lg="20" md="20" />
          <PaginateButton
            hasMore={hasMore}
            loading={paginateLoading}
            onClick={paginate}
          />
        </>
      )}

      {modal && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div className="cs-modal_box cs-style1">
                <Spacing lg="45" md="45" />
                <div className="cs-modal_box_in">
                  <div className="cs-text_center">
                    <h2
                      className="cs-semibold cs-m0"
                      style={{ fontSize: '20px' }}
                    >
                      {t('billing_information_canceled_modal_text_1_1')}{' '}
                      {tierName}{' '}
                      {t('billing_information_canceled_modal_text_1_2')}
                    </h2>
                    <Spacing lg="5" md="5" />
                    <div style={{ fontSize: '14px', lineHeight: '1.6em' }}>
                      {renewMessage}
                    </div>
                  </div>
                  <Spacing lg="30" md="30" />
                  <div className="cs-modal_btns">
                    <button
                      className="cs-modal_btn"
                      onClick={() => setModal(false)}
                    >
                      {t('cancel_text')}
                    </button>
                    <button
                      className={`cs-modal_btn cs-color1 ${
                        paymentLoading ? 'cs-loading_wrap' : ''
                      }`}
                      disabled={paymentLoading}
                      onClick={() => processToPayment(renewTierId)}
                    >
                      {paymentLoading && (
                        <span className="cs-btn_loading">
                          <Icon icon="la:spinner" />
                          {t('loading_text')}
                        </span>
                      )}
                      {t('confirm_text')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {modal3 && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal3(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div className="cs-modal_box cs-style1">
                <Spacing lg="45" md="45" />
                <div className="cs-modal_box_in">
                  <div className="cs-text_center">
                    <h2
                      className="cs-semibold cs-m0 cs-danger"
                      style={{ fontSize: '20px' }}
                    >
                      {t('billing_information_canceled_modal_text_2_1')}{' '}
                      {tierName}{' '}
                      {t('billing_information_canceled_modal_text_2_2')}
                    </h2>
                    <Spacing lg="5" md="5" />
                    <div style={{ fontSize: '14px', lineHeight: '1.6em' }}>
                      {t('billing_information_canceled_modal_text_3')}{' '}
                      <span style={{ textTransform: 'capitalize' }}>
                        {creatorName}
                      </span>
                    </div>
                  </div>
                  <Spacing lg="30" md="30" />
                  <div className="cs-modal_btns">
                    <button
                      className="cs-modal_btn"
                      onClick={() => setModal3(false)}
                    >
                      {t('cancel_text')}
                    </button>
                    <button
                      className="cs-modal_btn cs-color1"
                      onClick={() => {
                        getTier();
                        setModal2(true);
                        setModal3(false);
                      }}
                    >
                      {t('view_membership_tiers_text')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {modal2 && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal2(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div className="cs-modal_box cs-style2">
                <div className="cs-modal_box_in">
                  <button
                    className="cs-close_btn"
                    onClick={() => setModal2(false)}
                  >
                    <Icon icon="ri:close-circle-fill" />
                  </button>
                  <MembershipTire
                    tiers={tiers}
                    loading={tierLoading}
                    title={t('select_membership_tier')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
