import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { subscribedTiersInfo } from '../../api/services/mySubscribtion';
import Spacing from '../../components/Spacing';
import { v4 as uuidv4 } from 'uuid';
import LoadingSpinner from '../../components/Common/LoadingSpinner';
import moment from 'moment';
import EmptyData from '../../components/Common/EmptyData';
import { useTranslation } from 'react-i18next';

export default function MySubscription() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [subscribtions, setSubscribtions] = useState([]);
  // get all subscribed tier
  const subscribedTier = async () => {
    setLoading(true);
    const res = await subscribedTiersInfo();
    setSubscribtions(res.data.data);
    setLoading(false);
  };

  useEffect(() => {
    subscribedTier();
  }, []);
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <h2 style={{ fontSize: '20px', marginBottom: '8px' }}>
        {t('my_subscription_title')}
      </h2>
      <p className="cs-m0 cs-light" style={{ color: '#8E8EA3' }}>
        {t('my_subscription_subtitle')}
      </p>
      <Spacing lg="30" md="30" />
      <div className="cs-card_5_wrap">
        <div className="cs-card_5_head cs-row">
          <div className="cs-col_6">{t('my_subscription_text_1')}</div>
          <div className="cs-col_6">{t('my_subscription_text_2')}</div>
        </div>
        <div className={`${loading ? 'cs-loading_wrap' : ''}`}>
          {loading && (
            <>
              <Spacing lg="50" md="50" />
              <LoadingSpinner />
            </>
          )}
          {subscribtions.map(item => (
            <div className="cs-card cs-style5" key={uuidv4()}>
              <div className="cs-row">
                <div className="cs-col_6">
                  <div className="cs-card_media">
                    <img src={item.creator_image} alt="User" />
                    <div className="cs-card_media_right">
                      <h3>{item.name_show}</h3>
                      <p>{t('my_subscription_text_3')}</p>
                    </div>
                  </div>
                  <Spacing lg="0" md="15" />
                </div>
                <div className="cs-col_6">
                  <div className="cs-card_in">
                    <Link
                      to={`/settings/my-subscription/${item.id}/edit-subscription`}
                      className="cs-card_btn"
                    >
                      {t('my_subscription_text_4')}
                    </Link>
                    <div className="cs-card_price">
                      <span style={{ fontSize: '17px', fontWeight: '400' }}>
                        $
                      </span>
                      <span>{item.amount}</span>
                      <span
                        style={{
                          color: '#64C680',
                          fontSize: '16px',
                          fontWeight: '400',
                        }}
                      >
                        /{t('my_subscription_text_5')}
                      </span>
                    </div>
                    {item.amount !== '0' && (
                      <p
                        style={{
                          letterSpacing: '0.03px',
                          color: '#353535',
                          lineHeight: '1.6em',
                        }}
                      >
                        {t('my_subscription_text_6')}{' '}
                        {moment(item.expire_at).format('D-MMM-Y')}
                      </p>
                    )}
                    <h2>
                      {item.tier_name}{' '}
                      <span className="cs-card_label">
                        {t('my_subscription_text_7')}
                      </span>
                    </h2>
                    <p
                      className="cs-m0 cs-break_all"
                      style={{
                        letterSpacing: '0.03px',
                        color: '#353535',
                        lineHeight: '1.6em',
                      }}
                    >
                      {item.tier_detail}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {!subscribtions.length && !loading ? <EmptyData /> : null}
        </div>
      </div>
      <Spacing lg="60" md="60" />
    </div>
  );
}
