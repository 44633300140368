import {
  bookmarkCreatorUrl,
  deleteBookmarkUrl,
  subscribedCreatorUrl,
  unsubscribedCreatorUrl,
} from "../const";
import axios from "../lib/axios";

// get subscribed creator
export const getSubscribedCreator = async (
  page = 1,
  limit = 5,
  search = ""
) => {
  return await axios.get(subscribedCreatorUrl, {
    params: {
      page,
      limit,
      search,
    },
  });
};

// get unsubscribed creator
export const getUnsubscribedCreator = async (
  page = 1,
  limit = 5,
  search = ""
) => {
  return await axios.get(unsubscribedCreatorUrl, {
    params: {
      page,
      limit,
      search,
    },
  });
};

// get bookmark creator
export const getBookmarkCreator = async (page = 1, limit = 5, search = "") => {
  return await axios.get(bookmarkCreatorUrl, {
    params: {
      page,
      limit,
      search,
    },
  });
};

// delete bookmark
export const deleteBookmark = async (creator_id) => {
  return await axios.delete(deleteBookmarkUrl, { params: { creator_id } });
};
