import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logoutUser, switchRole } from "../../api/services/auth/user";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../Common/LoadingSpinner";

export default function UserDropdown({
  user,
  handelProfileToggle,
  profileToggle,
  setProfileToggle,
}) {
  const { t } = useTranslation();
  const refOne = useRef(null);
  const navigate = useNavigate();
  const [roleSwitchLoading, setRoleSwitchLoading] = useState(false);
  const [logoutLoading, setLogoutLoading] = useState(false);
  // handle logout
  const handleLogout = async () => {
    setLogoutLoading(true);
    await logoutUser({ type: "2", device: 3 });
    setLogoutLoading(false);
    localStorage.removeItem("token");
    localStorage.removeItem("isAuth");
    localStorage.removeItem("closeBroadcastDisclaimer");
    localStorage.removeItem("closeTradeDiaryDisclaimer");
    sessionStorage.setItem("currentPath", "/");
    navigate("/auth/login");
  };
  // Hide on outside click
  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setProfileToggle(false);
    }
  };
  // handle switch role
  const handleSwitchRole = async () => {
    setRoleSwitchLoading(true);
    await switchRole();
    // setRoleSwitchLoading(false);
    const token = localStorage.getItem("token");
    const redirectUrl = `https://creator.gorudo.io/role-switch?token=${token}`;
    window.location.href = redirectUrl;
  };
  useEffect(() => {
    // event listeners
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);
  return (
    <div className="cs-dropdown_wrap cs-user" ref={refOne}>
      <button className="cs-user_btn" onClick={handelProfileToggle}>
        {/* <img src="/images/user1.png" alt="User" /> */}
        <div className="cs-avater">
          <h3>{user.name_show?.charAt(0)}</h3>
        </div>
        <svg
          style={{
            transform: `${profileToggle ? "rotate(180deg)" : "rotate(0deg)"}`,
          }}
          width="13"
          height="7"
          viewBox="0 0 13 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.0752 1L6.40853 6.33333L11.7419 1"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {profileToggle && (
        <div className="cs-dropdown cs-user_dropdown">
          <div className="cs-dropdown_info">
            <h3>{user.name_show}</h3>
            <span class="cs-header_label">{t("member_text")}</span>
          </div>
          <ul className="cs-mp0 cs-medium">
            <li>
              <Link to="/settings/basic-info">
                {t("header_dropdown_item_1")}
              </Link>
            </li>
            <li
              onClick={handleSwitchRole}
              style={{ cursor: "pointer" }}
              className={roleSwitchLoading ? "cs-loading_wrap" : ""}
            >
              {roleSwitchLoading && (
                <>
                  <LoadingSpinner />
                </>
              )}
              <a>{t("switch_to_member")}</a>
              <label className="cs-switch">
                <input type="checkbox" />
                <span></span>
              </label>
            </li>
            <li>
              <Link to="/settings/my-subscription">
                {t("header_dropdown_item_3")}
              </Link>
            </li>
          </ul>
          <div className="cs-dropdown_footer">
            <button className="cs-dropdown_footer_btn" onClick={handleLogout}>
              {logoutLoading ? `${t("logging_out")}` : `${t("logout")}`}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
