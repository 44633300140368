import React, { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import Header from "../Header";
import MainSideNav from "../MainSideNav";
import useAuth from "../../hooks/useAuth";
import UserContext from "../../context/user/user-context";
import LoadingSpinner from "../Common/LoadingSpinner";

export default function MainLayout({ showSearch }) {
  const Auth = useAuth();
  const { user, isLoading } = useContext(UserContext);

  // deside what to render
  let content = "";
  if (isLoading)
    content = (
      <div
        className="cs-loading_wrap"
        style={{ width: "100%", height: "100vh" }}
      >
        <LoadingSpinner />
      </div>
    );
  if (!isLoading && user.varified === 0) {
    content = <Navigate to="/auth/email-verification?resentCode=true" />;
  }
  if (!isLoading && user.varified && user.dob === null) {
    content = <Navigate to="/auth/personal-info" />;
  }
  if (!isLoading && user.varified && user.dob) {
    content = (
      <>
        <Header showSearch={showSearch} />
        <MainSideNav />
        <div className="cs-with_sidebar_main">
          <Outlet />
        </div>
      </>
    );
  }
  return Auth ? <>{content}</> : <Navigate to="/auth/login" />;
}
