import { Icon } from '@iconify/react';
import moment from 'moment/moment';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Link,
  useOutletContext,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { creatorWebinar } from '../../../api/services/creator/webinar';
import LoadingSpinner from '../../../components/Common/LoadingSpinner';
import DotOption from '../../../components/DotOption';
import Spacing from '../../../components/Spacing';
import VideoModal from '../../../components/VideoModal';
import { tagStringToArray } from '../../../utils/tagStringToArray';
import MembershipTire from '../../../components/MembershipTire';
import {
  getPublicCreatorDetails,
  getPublicCreatorTier,
} from '../../../api/services/public/creator';
import Card3 from '../../../components/Card/Card3';
import { textLimit } from '../../../utils/TextLimit';
import { removeHtml } from '../../../utils/removeHtml';
import { useTranslation } from 'react-i18next';

export default function WebinarVideoDetails() {
  const { t } = useTranslation();
  const params = useParams();
  let [searchParams] = useSearchParams();
  const [creator, setCreator] = useState({});
  const [webinar, setWebinar] = useState([]);
  const [webinarLoading, setWebinarLoading] = useState(false);
  const [webinarCount, setWebinarCount] = useState(0);
  const [tierLoading, setTierLoading] = useState(false);
  const [tiers, setTiers] = useState([]);
  const [modal, setModal] = useState(false);

  // get creator webinar
  const getWebinar = async () => {
    setWebinarLoading(true);
    const res = await creatorWebinar(
      params.creator_id,
      1,
      5,
      '',
      '',
      '',
      params.id,
    );
    setWebinarLoading(false);
    setWebinarCount(res.data.count);
    setWebinar(tagStringToArray(res.data.data));
  };

  // get tiers
  const getTiers = async () => {
    setTierLoading(true);
    const res = await getPublicCreatorTier(params.creator_id);
    setTiers(res.data.data);
    setTierLoading(false);
  };

  // get creator
  const getCreator = async () => {
    const res = await getPublicCreatorDetails(params.creator_id);
    setCreator(res.data.data);
  };

  useEffect(() => {
    getWebinar();
    getCreator();
  }, [params]);
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <div className="cs-layout_1">
        <div className="cs-layout_left">
          <h2 style={{ fontSize: '24px', marginBottom: '0px' }}>
            Webinar / Video Link
          </h2>
          <Spacing lg="30" md="30" />
          <div
            className={`cs-card_8_wrap ${
              webinarLoading ? 'cs-loading_wrap' : ''
            }`}
          >
            {webinarLoading && (
              <>
                <Spacing lg="50" md="50" />
                <LoadingSpinner />
              </>
            )}
            {webinarCount > 0 ? (
              <>
                {' '}
                {webinar.map(item => (
                  <div className="cs-card cs-style8" key={uuidv4()}>
                    <ul className="cs-card_meta cs-mp0">
                      <li>
                        <Icon icon="fa6-solid:flag" />
                        <span>
                          {t('due_to_text')}{' '}
                          {moment(item.created_at).format('DD-MMM-Y')}
                        </span>
                      </li>
                      <li>
                        <Icon icon="fa6-solid:clock" />
                        <span>{moment(item.created_at).format('LT')}</span>
                      </li>
                    </ul>
                    <div className="cs-video_thumb">
                      <img
                        style={{ width: '100%' }}
                        src={item.image ? item.image : '/images/thumb_3.jpeg'}
                        alt="Thumb"
                      />
                      <VideoModal videoSrc={item.video} />
                    </div>
                    <h2 className="cs-card_title cs-break_all">{item.title}</h2>
                    <div className="cs-card_desc cs-break_all">
                      {textLimit(removeHtml(item.detail), 435)}
                    </div>
                    <Link
                      to={`/dashboard/${creator.id}/webinar-video/view/${item.id}`}
                      className="cs-card_btn"
                    >
                      {t('continue_reading_text')}
                    </Link>
                    <Spacing lg="15" md="15" />
                    <div className="cs-tags">
                      {item.tag.map(tag => (
                        <a className={`cs-tag`} key={uuidv4()}>
                          {tag}
                        </a>
                      ))}
                    </div>
                    <DotOption
                      url={`/dashboard/${item.userId}/webinar-video/${item.id}`}
                    />
                  </div>
                ))}
              </>
            ) : (
              <div className="cs-card cs-style8 cs-lock_wrap">
                <div className="cs-lock_overlay">
                  <svg
                    width={30}
                    height={45}
                    viewBox="0 0 30 45"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.375 18.3019V9.375C24.375 4.19813 20.1788 0 15 0C9.81938 0 5.625 4.19813 5.625 9.375V18.3019C2.19937 21.0506 0 25.2656 0 30C0 38.2838 6.71625 45 15 45C23.2838 45 30 38.2838 30 30C30 25.2656 27.8006 21.0506 24.375 18.3019ZM9.375 9.375C9.375 6.27375 11.8987 3.75 15 3.75C18.1013 3.75 20.625 6.27375 20.625 9.375V16.1006C18.8869 15.3956 16.9913 15 15 15C13.0087 15 11.1131 15.3956 9.375 16.1006V9.375ZM15 41.25C8.79562 41.25 3.75 36.2044 3.75 30C3.75 23.7956 8.79562 18.75 15 18.75C21.2044 18.75 26.25 23.7956 26.25 30C26.25 36.2044 21.2044 41.25 15 41.25ZM18.75 30C18.75 32.07 17.07 33.75 15 33.75C12.93 33.75 11.25 32.07 11.25 30C11.25 27.93 12.93 26.25 15 26.25C17.07 26.25 18.75 27.93 18.75 30Z"
                      fill="white"
                    />
                  </svg>
                  <h3>Subscribe to unlock post</h3>
                  <a
                    className="cs-lock_btn"
                    onClick={() => {
                      setModal(true);
                      getTiers();
                    }}
                  >
                    Subscribe now
                  </a>
                </div>
                <ul className="cs-card_meta cs-mp0">
                  <li>
                    <Icon icon="fa6-solid:flag" />
                    <span>{t('due_to_text')} 26-Jan-2023</span>
                  </li>
                  <li>
                    <Icon icon="fa6-solid:clock" />
                    <span>1:03 PM</span>
                  </li>
                </ul>
                <div className="cs-video_thumb">
                  <img
                    style={{ width: '100%' }}
                    src="/images/thumb_3.jpeg"
                    alt="Thumb"
                  />
                  <VideoModal videoSrc="" />
                </div>
                <h2 className="cs-card_title cs-break_all">Webinar title</h2>
                <div className="cs-card_desc cs-break_all">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta
                  id magni sapiente qui dolor laudantium vitae neque fuga quo,
                  quaerat eius! Excepturi qui nesciunt et a nisi voluptatum
                  ratione rem!
                </div>
                <a className="cs-card_btn">{t('continue_reading_text')}</a>
                <Spacing lg="15" md="15" />
                <div className="cs-tags">
                  <a className={`cs-tag`}>tag 1</a>
                </div>
                <DotOption />
              </div>
            )}
          </div>
          <Spacing lg="20" md="20" />
        </div>
      </div>
      <Spacing lg="50" md="50" /> <Spacing lg="50" md="50" />
      {modal && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div className="cs-modal_box cs-style2">
                <div className="cs-modal_box_in">
                  <button
                    className="cs-close_btn"
                    onClick={() => setModal(false)}
                  >
                    <Icon icon="ri:close-circle-fill" />
                  </button>
                  <Card3 creator={creator} count={false} />
                  <Spacing lg="80" md="60" />
                  <MembershipTire
                    tiers={tiers}
                    loading={tierLoading}
                    title={t('select_membership_tier')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
