import {
  creatorBroadcastTierUrl,
  creatorBroadcastUrl,
  creatorDetailsUrl,
} from "../../const";
import axios from "../../lib/axios";

// get creator broadcast
export const getCreatorBroadcast = async (
  creator_id,
  page = 1,
  limit = 5,
  search = "",
  tag = "",
  pin = "",
  post_id = ""
) => {
  return await axios.get(creatorBroadcastUrl, {
    params: {
      creator_id,
      page,
      limit,
      search,
      tag,
      pin,
      filter: "",
      post_id,
    },
  });
};

// get broadcast tier
export const getBroadcastTiers = async (post_id) => {
  return await axios.get(creatorBroadcastTierUrl, { params: { post_id } });
};
