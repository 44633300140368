import React from "react";
import Header from "../../components/Header";
import Spacing from "../../components/Spacing";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useState } from "react";
import { forgotPassReq } from "../../api/services/auth/forgotPassword";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import { Navigate } from "react-router-dom";
export default function FogotPassword() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const auth = useAuth();
  const formSchema = Yup.object().shape({
    email: Yup.string().required("The email field is required"),
    type: Yup.string(),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm(formOptions);

  // handle forgot
  const handleForgot = async (data) => {
    setIsLoading(true);
    const res = await forgotPassReq(data);
    if (res.data.success === 1) {
      setMessage(res.data.message);
    } else {
      setError("email", {
        type: "custom",
        message: res.data.message,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setValue("type", 2);
  }, []);

  return !auth ? (
    <>
      <Header starter />
      <div className="cs-contaienr_sm">
        <Spacing lg="90" md="90" />
        <Spacing lg="60" md="60" />
        <form
          onSubmit={handleSubmit(handleForgot)}
          className="cs-reset_password_box"
        >
          <h2>{t("forgot_password_title")}</h2>
          <Spacing lg="10" md="10" />
          <p className="cs-m0 cs-text_center">
            {t("forgot_password_subtitle")}
          </p>
          <p className="cs-success">{message}</p>
          <Spacing lg="30" md="30" />
          <label>{t("forgot_password_input_email")}</label>
          <input
            type="email"
            className="cs-form_field"
            {...register("email")}
            placeholder={t("forgot_password_input_email_placeholder")}
          />
          <p className="invalid-feedback">{errors.email?.message}</p>
          <Spacing lg="25" md="25" />
          <button
            className={`cs-logout_btn  cs-type1 ${
              isLoading ? "cs-loading_wrap" : ""
            }`}
            disabled={isLoading}
          >
            {isLoading && (
              <span className="cs-btn_loading">
                <Icon icon="la:spinner" />
                {t("loading_text")}
              </span>
            )}
            {t("forgot_password_form_btn_text")}
          </button>
        </form>
        <Spacing lg="60" md="60" />
      </div>
    </>
  ) : (
    <Navigate to="/" />
  );
}
