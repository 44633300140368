import { Icon } from '@iconify/react';
import moment from 'moment/moment';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Link,
  useOutletContext,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { creatorResource } from '../../../api/services/creator/resource';
import LoadingSpinner from '../../../components/Common/LoadingSpinner';
import DotOption from '../../../components/DotOption';
import Spacing from '../../../components/Spacing';
import { tagStringToArray } from '../../../utils/tagStringToArray';
import { defaultHasMoreCount } from '../../../config/const/hasMore';
import PaginateButton from '../../../components/Common/PaginateButton';
import EmptyData from '../../../components/Common/EmptyData';
import { textLimit } from '../../../utils/TextLimit';
import { removeHtml } from '../../../utils/removeHtml';
import { useTranslation } from 'react-i18next';

export default function ResourcesFaq() {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const [creator] = useOutletContext();
  const params = useParams();
  const [resource, setResource] = useState([]);
  const [resourceLoading, setResourceLoading] = useState(false);
  const [resourceHasMore, setResourceHasMore] = useState(true);
  const [resourcePage, setResourcePage] = useState(2);
  const [resourceCount, setResourceCount] = useState(0);
  const [resourcePaginateLoading, setResourcePaginateLoading] = useState(false);

  const [pinnedResource, setPinnedResource] = useState([]);
  const [pinnedLoading, setPinnedLoading] = useState(false);
  const [pinnedHasMore, setPinnedHasMore] = useState(true);
  const [pinnedPage, setPinnedPage] = useState(2);
  const [pinnedPaginateLoading, setPinnedPaginateLoading] = useState(false);
  const [pinnedCount, setPinnedCount] = useState(0);
  const selectedTag = searchParams.get('tag') ?? '';
  const searchQuery = searchParams.get('search') ?? '';
  // get creator resource
  const getResource = async () => {
    setResourceLoading(true);
    const res = await creatorResource(
      params.creator_id,
      1,
      5,
      searchQuery,
      selectedTag,
    );
    setResourceLoading(false);
    setResource(tagStringToArray(res.data.data));
    setResourceCount(res.data.count);
  };

  // resorce paginate
  const resourcePaginate = async () => {
    setResourcePaginateLoading(true);
    const res = await creatorResource(
      params.creator_id,
      resourcePage,
      5,
      searchQuery,
      selectedTag,
    );
    setResourcePaginateLoading(false);
    setResource([...resource, ...tagStringToArray(res.data.data)]);
    setResourcePage(resourcePage + 1);
    if (!res.data.data.length) {
      setResourceHasMore(false);
    }
  };

  // get all pinned resource
  const pinnedResources = async () => {
    setPinnedLoading(true);
    const res = await creatorResource(
      params.creator_id,
      1,
      5,
      searchQuery,
      selectedTag,
      1,
    );
    setPinnedLoading(false);
    setPinnedResource(tagStringToArray(res.data.data));
    setPinnedCount(res.data.count);
  };

  // pinned resource paginate
  const pinnedPaginate = async () => {
    setPinnedPaginateLoading(true);
    const res = await creatorResource(
      params.creator_id,
      pinnedPage,
      5,
      searchQuery,
      selectedTag,
      1,
    );
    setPinnedPaginateLoading(false);
    setPinnedResource([...pinnedResource, ...tagStringToArray(res.data.data)]);
    setPinnedPage(pinnedPage + 1);
    if (!res.data.data.length) {
      setPinnedHasMore(false);
    }
  };

  useEffect(() => {
    getResource();
    pinnedResources();
  }, [searchParams]);
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <div className="cs-layout_1">
        <div className="cs-layout_left">
          {selectedTag == '' ? (
            <>
              <h2 style={{ fontSize: '24px', marginBottom: '0px' }}>
                {creator.name_show}
                {t('resources_faq_title')}
              </h2>
            </>
          ) : (
            <>
              <h2 style={{ fontSize: '24px', marginBottom: '8px' }}>
                {t('hashtag_text')}
              </h2>
              <p className="cs-m0 cs-light" style={{ color: '#8E8EA3' }}>
                {t('searching_text')} “{selectedTag}”
              </p>
            </>
          )}
          <Spacing lg="30" md="30" />
          <div
            className={`cs-card_8_wrap ${
              resourceLoading ? 'cs-loading_wrap' : ''
            }`}
          >
            {resourceLoading && (
              <>
                <Spacing lg="50" md="50" />
                <LoadingSpinner />
              </>
            )}
            {resource.map(item => (
              <div className="cs-card cs-style8" key={uuidv4()}>
                <ul className="cs-card_meta cs-mp0">
                  <li>
                    <Icon icon="fa6-solid:flag" />
                    <span>
                      {t('due_to_text')}{' '}
                      {moment(item.created_at).format('DD-MMM-Y')}
                    </span>
                  </li>
                  <li>
                    <Icon icon="fa6-solid:clock" />
                    <span>{moment(item.created_at).format('LT')}</span>
                  </li>
                </ul>
                {item.image && (
                  <img style={{ width: '100%' }} src={item.image} alt="Thumb" />
                )}
                <h2 className="cs-card_title cs-break_all">{item.title}</h2>
                <div className="cs-card_desc cs-break_all">
                  {textLimit(removeHtml(item.detail), 435)}
                </div>
                <Link
                  to={`/dashboard/${creator.id}/resources-faq/view/${item.id}`}
                  className="cs-card_btn"
                >
                  {t('continue_reading_text')}
                </Link>
                <Spacing lg="15" md="15" />
                <div className="cs-tags">
                  {item.tag.map(tag => (
                    <Link
                      to={`/dashboard/${creator.id}/resources-faq?tag=${tag}`}
                      className={`cs-tag ${
                        selectedTag === tag ? 'active' : ''
                      }`}
                      key={uuidv4()}
                    >
                      {tag}
                    </Link>
                  ))}
                </div>
                <DotOption
                  url={`/dashboard/${item.userId}/resources-faq/${item.id}`}
                />
              </div>
            ))}
            {!resource.length && !resourceLoading ? <EmptyData /> : null}
          </div>
          <Spacing lg="20" md="20" />
          {resourceCount > defaultHasMoreCount && (
            <PaginateButton
              loading={resourcePaginateLoading}
              hasMore={resourceHasMore}
              onClick={resourcePaginate}
            />
          )}
        </div>
        <div className="cs-layout_right">
          <div className="cs-section_heading_4">
            <h2 className="cs-section_title">{t('pinned_by_creator_text')}</h2>
            <div className="cs-section_heading_right">
              {/* <button className="cs-icon_btn">
                <svg
                  width={9}
                  height={13}
                  viewBox="0 0 9 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.121661 12.7023L4.50004 9.08404L8.87842 12.7023V1.33066C8.87842 0.759493 8.38859 0.296875 7.78382 0.296875H1.21626C0.611492 0.296875 0.121661 0.759493 0.121661 1.33066V12.7023Z"
                    fill="currentColor"
                  />
                </svg>
              </button> */}
            </div>
          </div>
          <Spacing lg="30" md="30" />
          <div
            className={`cs-card_8_wrap ${
              pinnedLoading ? 'cs-loading_wrap' : ''
            }`}
          >
            {pinnedLoading && (
              <>
                <Spacing lg="50" md="50" />
                <LoadingSpinner />
              </>
            )}
            {pinnedResource.map(item => (
              <div className="cs-card cs-style4 cs-type1" key={uuidv4()}>
                <div className="cs-card_thumb">
                  {item.image && (
                    <img style={{ width: '100%' }} src={item.image} alt="" />
                  )}
                </div>
                <div className="cs-card_info">
                  <div className="cs-csra_info">
                    <div className="cs-csra_date">
                      <span>{moment(item.created_at).format('LT')}</span>
                      <span>{moment(item.created_at).format('DD-MMM-Y')}</span>
                    </div>
                    <DotOption
                      url={`/dashboard/${item.userId}/resources-faq/${item.id}`}
                    />
                  </div>
                  <h3 className="cs-break_all">{item.title}</h3>
                  <p className="cs-break_all">
                    {textLimit(removeHtml(item.detail), 100)}
                  </p>
                  <Link
                    to={`/dashboard/${creator.id}/resources-faq/view/${item.id}`}
                    className="cs-coninue_reading"
                  >
                    {t('continue_reading_text')}
                  </Link>
                  <div className="cs-tags">
                    {item.tag.map(tag => (
                      <Link
                        to={`/dashboard/${creator.id}/resources-faq?tag=${tag}`}
                        className={`cs-tag ${
                          selectedTag === tag ? 'active' : ''
                        }`}
                        key={uuidv4()}
                      >
                        {tag}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            ))}
            {!pinnedResource.length && !pinnedLoading ? <EmptyData /> : null}
          </div>
          <Spacing lg="20" md="20" />
          {pinnedCount > defaultHasMoreCount && (
            <PaginateButton
              loading={pinnedPaginateLoading}
              hasMore={pinnedHasMore}
              onClick={pinnedPaginate}
            />
          )}
        </div>
      </div>
      <Spacing lg="50" md="50" />
    </div>
  );
}
