import {
  checkoutCheckUrl,
  creatorPublicTierUrl,
  crteatorPublicViewUrl,
  newsletterTierUrl,
  paymentCancelVerifyUrl,
  paymentCheckUrl,
  paymentMethodAddUrl,
  paymentSuccessVerifyUrl,
  paymentUrl,
  publicviewCreatorNewsletterUrl,
  resubscribePaymentUrl,
} from "../../const";
import axios from "../../lib/axios";
// get public creator details
export const getPublicCreatorDetails = async (creator_id) => {
  return axios.get(crteatorPublicViewUrl, { params: { creator_id } });
};

// checkout check
export const checkoutCheck = async (data) => {
  return axios.post(checkoutCheckUrl, data);
};

// get public creator tier
export const getPublicCreatorTier = async (creator_id) => {
  return axios.get(creatorPublicTierUrl, { params: { creator_id } });
};

// process to stripe payment
export const processToStripePayment = async (data) => {
  return await axios.post(paymentUrl, data);
};

// process resubscribe payment
export const processResubscribePayment = async (data) => {
  return await axios.post(resubscribePaymentUrl, data);
};
// payment success verify
export const paymentSuccessVerify = async (session_id) => {
  return await axios.get(paymentSuccessVerifyUrl, { params: { session_id } });
};

// payment cancel verify
export const paymentCancelVerify = async (session_id) => {
  return await axios.get(paymentCancelVerifyUrl, { params: { session_id } });
};

// public view newsletter
export const creatorPublicViewNewsletter = async (
  creator_id,
  page = 1,
  limit = 10,
  search = "",
  tag = "",
  pin = "",
  is_schedule = "",
  post_id = ""
) => {
  return await axios.get(publicviewCreatorNewsletterUrl, {
    params: { creator_id, page, limit, search, tag, pin, is_schedule, post_id },
  });
};

// newsletter tier
export const newsletterTier = async (post_id) => {
  return await axios.get(newsletterTierUrl, { params: { post_id } });
};
