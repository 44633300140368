import { Icon } from '@iconify/react';
import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getCreatorTradeDiary,
  getTradeDiaryTiers,
} from '../../../api/services/creator/tradediary';
import { getPublicCreatorDetails } from '../../../api/services/public/creator';
import Card3 from '../../../components/Card/Card3';
import LoadingSpinner from '../../../components/Common/LoadingSpinner';
import DotOption from '../../../components/DotOption';
import MembershipTire from '../../../components/MembershipTire';
import Spacing from '../../../components/Spacing';
import { useTranslation } from 'react-i18next';

export default function TradeDiaryDetails() {
  const { t } = useTranslation();
  const params = useParams();
  const [creator, setCreator] = useState({});
  const [tradeDiary, setTradeDiary] = useState([]);
  const [tradeDiaryLoading, setTradeDiaryLoading] = useState(false);
  const [tradeDiaryCount, setTradeDiaryCount] = useState(0);
  const [tierLoading, setTierLoading] = useState(false);
  const [tiers, setTiers] = useState([]);
  const [modal, setModal] = useState(false);

  // get all trade diary
  const getTradeDiary = async () => {
    setTradeDiaryLoading(true);
    const res = await getCreatorTradeDiary(
      params.creator_id,
      1,
      5,
      '',
      '',
      params.id,
    );
    setTradeDiary(res.data.data);
    setTradeDiaryCount(res.data.count);
    setTradeDiaryLoading(false);
  };
  // get tiers
  const getTiers = async () => {
    setTierLoading(true);
    const res = await getTradeDiaryTiers(params.id);
    setTiers(res.data.data);
    setTierLoading(false);
  };
  // get creator
  const getCreator = async () => {
    const res = await getPublicCreatorDetails(params.creator_id);
    setCreator(res.data.data);
  };

  useEffect(() => {
    getTradeDiary();
    getCreator();
  }, [params]);
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <div className="cs-layout_1">
        <div className="cs-layout_left">
          <h2 style={{ fontSize: '24px', marginBottom: '8px' }}>
            {t('trade_diary')}
          </h2>
          <Spacing lg="30" md="30" />
          <div
            className={`cs-card_9_wrap ${
              tradeDiaryLoading ? 'cs-loading_wrap' : ''
            }`}
          >
            {tradeDiaryLoading && (
              <>
                <Spacing lg="50" md="50" />
                <LoadingSpinner />
              </>
            )}
            {tradeDiaryCount > 0 ? (
              <>
                {tradeDiary.map(item => (
                  <div className="cs-card cs-style9">
                    <div
                      className="cs-card_bar"
                      style={{
                        backgroundColor: item.tradeDairyColorBackground,
                      }}
                    ></div>
                    <div className="cs-card_in">
                      <div className="cs-left">
                        <h2 className="ca-card_title">{item.stock_name}</h2>
                        <div className="cs-card_meta">
                          <div>
                            <div className="cs-card_meta_title">
                              {t('price_text')}
                            </div>
                            <div className="cs-card_meta_price">
                              {item.stock_price !== 'Now' && '$'}
                              {item.stock_price}
                            </div>
                          </div>
                          <div>
                            <div className="cs-card_meta_title">
                              {t('stop_loss_text')}
                            </div>
                            <div className="cs-card_meta_price">
                              ${item.stop_loss}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cs-right">
                        <div className="cs-card_label">
                          <span
                            style={{
                              background: item.tradeDairyColorBackground,
                            }}
                          ></span>
                          {item.tradeDairyType}
                        </div>
                        <div className="cs-card_date">
                          {moment(item.created_at).format('DD-MMM-Y')}{' '}
                          {moment(item.created_at).format('LT')}
                        </div>
                      </div>
                    </div>
                    {item.note && (
                      <>
                        <div
                          className="cs-card_note_title"
                          style={{ textTransform: 'uppercase' }}
                        >
                          <Icon icon="mdi:tick-circle-outline" />
                          {t('notes_text')}:
                        </div>
                        <div className="cs-card_text cs-break_all">
                          {item.note}
                        </div>
                      </>
                    )}
                    <DotOption
                      url={`/dashboard/${item.userId}/trade-diary/${item.id}`}
                    />
                  </div>
                ))}
              </>
            ) : (
              <div className="cs-card cs-style9 cs-lock_wrap">
                <div className="cs-lock_overlay">
                  <svg
                    width={30}
                    height={45}
                    viewBox="0 0 30 45"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.375 18.3019V9.375C24.375 4.19813 20.1788 0 15 0C9.81938 0 5.625 4.19813 5.625 9.375V18.3019C2.19937 21.0506 0 25.2656 0 30C0 38.2838 6.71625 45 15 45C23.2838 45 30 38.2838 30 30C30 25.2656 27.8006 21.0506 24.375 18.3019ZM9.375 9.375C9.375 6.27375 11.8987 3.75 15 3.75C18.1013 3.75 20.625 6.27375 20.625 9.375V16.1006C18.8869 15.3956 16.9913 15 15 15C13.0087 15 11.1131 15.3956 9.375 16.1006V9.375ZM15 41.25C8.79562 41.25 3.75 36.2044 3.75 30C3.75 23.7956 8.79562 18.75 15 18.75C21.2044 18.75 26.25 23.7956 26.25 30C26.25 36.2044 21.2044 41.25 15 41.25ZM18.75 30C18.75 32.07 17.07 33.75 15 33.75C12.93 33.75 11.25 32.07 11.25 30C11.25 27.93 12.93 26.25 15 26.25C17.07 26.25 18.75 27.93 18.75 30Z"
                      fill="white"
                    />
                  </svg>
                  <h3>Subscribe to unlock post</h3>
                  <a
                    onClick={() => {
                      setModal(true);
                      getTiers();
                    }}
                    className="cs-lock_btn"
                  >
                    Subscribe now
                  </a>
                </div>
                <div className="cs-card_bar"></div>
                <div className="cs-card_in">
                  <div className="cs-left">
                    <h2 className="ca-card_title">Stock Name</h2>
                    <div className="cs-card_meta">
                      <div>
                        <div className="cs-card_meta_title">
                          {t('price_text')}
                        </div>
                        <div className="cs-card_meta_price">$00</div>
                      </div>
                      <div>
                        <div className="cs-card_meta_title">
                          {t('stop_loss_text')}
                        </div>
                        <div className="cs-card_meta_price">$00</div>
                      </div>
                    </div>
                  </div>
                  <div className="cs-right">
                    <div className="cs-card_label">BUY</div>
                    <div className="cs-card_date">17-Jan-2023 1:13 PM</div>
                  </div>
                </div>
                <div className="cs-card_note_title">
                  <Icon icon="mdi:tick-circle-outline" />
                  {t('notes_text')}::
                </div>
                <div className="cs-card_text cs-break_all">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Spacing lg="50" md="50" />
      {modal && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div className="cs-modal_box cs-style2">
                <div className="cs-modal_box_in">
                  <button
                    className="cs-close_btn"
                    onClick={() => setModal(false)}
                  >
                    <Icon icon="ri:close-circle-fill" />
                  </button>
                  <Card3 creator={creator} count={false} />
                  <Spacing lg="80" md="60" />
                  <MembershipTire
                    tiers={tiers}
                    loading={tierLoading}
                    title={t('select_membership_tier')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
